import React from "react";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import DocManagement from "../pages/DocManagement/DocManagement";
import UserManagement from "../pages/UserManagement/UserManagement";
import ManualReview from "../pages/ManualReview/ManualReview";
import AuditLog from "../pages/AuditLog/AuditLog";
import AdminConfig from '../pages/AdminConfig/AdminConfig';
import SuperAdmin from "../pages/SuperAdmin/SuperAdmin";
import UserProfile from "../pages/UserProfile/UserProfile";
import SSORedirect from "../pages/SSORedirect/SSORedirect";
import SSOLanding from "../pages/SSOLanding/SSOLanding";
import CheckIdleTime from "../container/CheckIdleTime";
import InfoScreen from "../pages/InfoScreen/InfoScreen";
import Reports from "../pages/NewReports/Reports";
import DocInfo from "../pages/SuperAdmin/DocInfo/DocInfo";
import DocStuck from "../pages/SuperAdmin/DocStuck/DocStuck";
import AccuracyTracker from "../pages/SuperAdmin/AccuracyTracker/Accuracy";
import SubCategory from "../pages/SuperAdmin/SubCategoryTable/SubCategoryTable";

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import * as c from "../utils/constants/constants";

class Entry extends React.Component {
  state = {
    currentPage: c.PAGE_LOGIN,
    doc_title: "",
    doc_id: "",

  };
  handlePageChange = (pageName) => {
    this.setState({
      currentPage: pageName,


    });
  };

  render() {
    return (
      <BrowserRouter>
        <div >
          <Switch>
            <Route
              exact
              path={c.PAGE_URLS[c.PAGE_LOGIN]}
              render={(props) => (
                c.SSO === 'true' ?
                  <SSOLanding {...props} handlePageChange={this.handlePageChange} />
                  :
                  <Login
                    {...props} handlePageChange={this.handlePageChange}
                  />
              )}
            />

            <Route
              exact
              path={c.PAGE_URLS[c.PAGE_INFO]}
              render={(props) => (
                <InfoScreen {...props} handlePageChange={this.handlePageChange} />
              )}
            />
            {/* <Route
              exact
              path={c.PAGE_URLS[c.PAGE_LOGIN]}
              render={(props) => (
                <SSOLanding {...props}  handlePageChange={this.handlePageChange} />
              )}
              />*/}
            <Route
              exact
              path={c.PAGE_URLS[c.SSO_REDIRECT]}
              render={(props) => (
                <SSORedirect{...props} handlePageChange={this.handlePageChange} />
              )}
            />


            {localStorage.getItem(c.ACCESS_TOKEN) ?
              <React.Fragment>
                <Route
                  exact
                  path={c.PAGE_URLS[c.PAGE_DASHBOARD]}
                  render={(props) => {
                    return (
                      <Dashboard
                        {...props}
                        handlePageChange={this.handlePageChange}
                      />);
                  }}
                />

                <Route
                  exact
                  path={c.PAGE_URLS[c.PAGE_DOC_MGMT]}
                  render={(props) => (
                    <DocManagement
                      {...props}
                      handlePageChange={this.handlePageChange}
                    />
                  )}
                />

                <Route
                  exact
                  path={c.PAGE_URLS[c.PAGE_USER_PROFILE]}
                  render={(props) => (
                    <UserProfile
                      {...props}
                      handlePageChange={this.handlePageChange}
                    />
                  )}
                />
                <Route
                  exact
                  path={c.PAGE_URLS[c.PAGE_MANUAL_REVIEW]}
                  render={(props) => (
                    <ManualReview
                      {...props}
                      handlePageChange={this.handlePageChange}
                    />
                  )}
                />
                <Route
                  exact
                  path={c.PAGE_URLS[c.ADMIN_CONFIG]}
                  render={(props) => (
                    <AdminConfig {...props} handlePageChange={this.handlePageChange} />
                  )}
                />
                {localStorage.getItem(c.USER_ROLE) && localStorage.getItem(c.USER_ROLE) === 'Admin' &&

                  <>
                    <Route
                      exact
                      path={c.PAGE_URLS[c.PAGE_USER_MGMT]}
                      render={(props) => (
                        <UserManagement
                          {...props}
                          handlePageChange={this.handlePageChange}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={c.PAGE_URLS[c.PAGE_AUDIT_LOG]}
                      render={(props) => (
                        <AuditLog
                          {...props}
                          handlePageChange={this.handlePageChange}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={c.PAGE_URLS[c.PAGE_REPORTS]}
                      render={(props) => (
                        <Reports
                          {...props}
                          handlePageChange={this.handlePageChange}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={c.PAGE_URLS[c.SUPER_ADMIN]}
                      render={(props) => (
                        <SuperAdmin {...props} handlePageChange={this.handlePageChange} />
                      )}
                    />
                    <Route
                      exact
                      path={c.PAGE_URLS[c.PAGE_DOC_INFO]}
                      render={(props) => (
                        <DocInfo {...props} handlePageChange={this.handlePageChange} />
                      )}
                    />
                    <Route
                      exact
                      path={c.PAGE_URLS[c.PAGE_DOC_STUCK]}
                      render={(props) => (
                        <DocStuck {...props} handlePageChange={this.handlePageChange} />
                      )}
                    />
                    <Route
                      exact
                      path={c.PAGE_URLS[c.PAGE_ACCURACY_TRACKER]}
                      render={(props) => (
                        <AccuracyTracker {...props} handlePageChange={this.handlePageChange} />
                      )}
                    />
                    <Route
                      exact
                      path={c.PAGE_URLS[c.PAGE_SUBCATEGORY]}
                      render={(props) => (
                        <SubCategory {...props} handlePageChange={this.handlePageChange} />
                      )}
                    />
                  </>
                }
                {localStorage.getItem(c.SETTINGS) && <CheckIdleTime />}
              </React.Fragment>

              :
              (c.SSO === 'true' ?
                <Redirect to={c.PAGE_URLS[c.SSO_REDIRECT]}
                  render={(props) => (
                    <SSORedirect {...props} handlePageChange={this.handlePageChange} />
                  )}
                />
                :
                <Redirect to={c.PAGE_URLS[c.PAGE_LOGIN]}
                  render={(props) => (
                    <Login {...props} handlePageChange={this.handlePageChange} />
                  )}
                />
              )

            }

          </Switch>

        </div>
      </BrowserRouter>
    );
  }
}

export default Entry;
