import React from "react";
import "./Dashboard.css";
import { connect } from "react-redux";
import Rightview from "./Rightview/Rightview";
import DocsStatusView from "./DocsStatus/DocsStatusView";
import Navbar from "../../components/Navbar/Navbar";
import RecentDocs from "./RecentDocs/RecentDocs";
import MetricsChart from "./MetricsChart/MetricsChart";
import { passDocId } from "../../redux/generalSettings/actions/actions";
import * as c from "../../utils/constants/constants";
import * as p from "../../utils/parameter/parameter";
import * as msg from "../../utils/constants/messages";
import {forceLogout} from "../../commonFunctions/forceLogout";
import { CircularProgress, Grid } from "@mui/material";
import {
  getLogs,
  getDocsStatus,
  getImetrics,
  getRecentDocs,
  getActionItems,
} from "../../redux/getdocsstatus/actions/actions";

class Dashboard extends React.Component {
  state = {
    isLoading: true,
    log_data: "",
    logs_page_num: 0,
    logs_data_length: "",
    int_metrics: "",
    doc_status: "",
    recent_docs: "",
    action_item: "",
    recent_page_num: 0,
    action_page_num: 0,
    action_item_length: "",
    refresh_logs : false,
    refresh_action_items: false
  };
 /* funciton to redirect to doc details screen or doc mgmt screen*/
  goToIntervention = (modal, param) => {
    p.isFilter.isFilter = false;
    localStorage.setItem('is_filter',JSON.stringify(p.isFilter));
    var _props = this.props;
    if (modal === "review") {
      localStorage.setItem(c.DOC_ID, param);
      this.props.passDocId(param);
      setTimeout(function () {
        _props.handlePageChange(c.PAGE_MANUAL_REVIEW);
        _props.history.push(`${c.PAGE_URLS[c.PAGE_MANUAL_REVIEW]}?doc_id=${localStorage.getItem(c.DOC_ID)}`);
      }, 700);
    } else {
      if (param) localStorage.setItem(c.DOC_STATUS, param);
      setTimeout(function () {
        _props.handlePageChange(c.PAGE_DOC_MGMT);
        _props.history.push(c.PAGE_URLS[c.PAGE_DOC_MGMT]);
      }, 700);
    }
    /* by removing this constant from local storage, we say that, user has not come to doc details from doc mgmt screen*/
    localStorage.removeItem(c.PREV_ROUTE);
  };
  /* Making API calls for doc status, logs and action items assigned for every 10 seconds with the help of setInterval for live update*/
  componentDidMount() {
    document.title = c.PRODUCT_NAME + " - Overview";
    localStorage.removeItem(c.DOC_STATUS);
    this.props.getLogs(
      c.ROOT_URL +
        "/getNotifications?page_limit=15&page_number=" +
        this.state.logs_page_num
    );
    this.props.getDocsStatus(c.ROOT_URL + "/getDocsStatusCount");
    this.props.getRecentDocs(c.ROOT_URL + "/getRecentDocs?page_limit=8");
    this.props.getActionItems(
      c.ROOT_URL +
        "/getActionItemsAssigned?page_limit=15&page_number=" +
        this.state.action_page_num
    );
    if (localStorage.getItem(c.SETTINGS)) {
      this.timer = setInterval(
        () =>
          this.props.getLogs(
            c.ROOT_URL +
              "/getNotifications?page_limit=15&page_number=" +
              this.state.logs_page_num
          ),
          parseInt(JSON.parse(localStorage.getItem(c.SETTINGS)).Page_Refresh_In_Sec)*1000
      );
      this.timer1 = setInterval(
        () => this.props.getDocsStatus(c.ROOT_URL + "/getDocsStatusCount"),
        parseInt(JSON.parse(localStorage.getItem(c.SETTINGS)).Page_Refresh_In_Sec)*1000
      );
      this.timer3 = setInterval(
        () =>
          this.props.getActionItems(
            c.ROOT_URL +
              "/getActionItemsAssigned?page_limit=15&page_number=" +
              this.state.action_page_num
          ),
          parseInt(JSON.parse(localStorage.getItem(c.SETTINGS)).Page_Refresh_In_Sec)*1000
      );
    } else {
      this.props.getDocsStatus(c.ROOT_URL + "/getDocsStatusCount");
      this.props.getLogs(
        c.ROOT_URL +
          "/getNotifications?page_limit=15&page_number=" +
          this.state.logs_page_num
      );
      this.props.getActionItems(
        c.ROOT_URL +
          "/getActionItemsAssigned?page_limit=15&page_number=" +
          this.state.action_page_num
      );
    }

    if (this.props.logs.logs)
      this.setState({
        log_data: this.props.logs.logs.data,
        logs_data_length: this.props.logs.logs.total_records,
      });
    if (this.props.int_metrics.int_metrics)
      this.setState({ int_metrics: this.props.int_metrics.int_metrics });
    if (this.props.items.items)
      this.setState({ doc_status: this.props.items.items });
    if (this.props.actionitems.actionitems)
      this.setState({
        action_item: this.props.actionitems.actionitems.data,
        action_item_length: this.props.actionitems.actionitems.total_records,
      });
    if (this.props.recentdocs.recentdocs)
      this.setState({ recent_docs: this.props.recentdocs.recentdocs });
  }
  componentDidUpdate = (prevProps) => {
    if (
      this.props.logs.hasError === 401 ||
      this.props.int_metrics.hasError === 401 ||
      this.props.items.hasError === 401 ||
      this.props.actionitems.hasError === 401 ||
      this.props.recentdocs.hasError === 401
    ) {
      forceLogout();
    } else if (
      this.props.logs.hasError ||
      this.props.int_metrics.hasError  ||
      this.props.items.hasError  ||
      this.props.actionitems.hasError  ||
      this.props.recentdocs.hasError 
    ){
      this.setState({isLoading : false});
      window.alert(msg.api_error);
    }
    if (this.props.logs.logs && this.props.logs.logs !== prevProps.logs.logs)
      this.setState({
        log_data: this.props.logs.logs.data,
        logs_data_length: this.props.logs.logs.total_records,
        isLoading: false,
        refresh_logs: false
      });
    if (
      this.props.int_metrics.int_metrics &&
      this.props.int_metrics.int_metrics !== prevProps.int_metrics.int_metrics
    )
      this.setState({ int_metrics: this.props.int_metrics.int_metrics });
    if (
      this.props.items.items &&
      this.props.items.items !== prevProps.items.items
    )
      this.setState({ doc_status: this.props.items.items });
    if (
      this.props.actionitems.actionitems &&
      this.props.actionitems.actionitems !== prevProps.actionitems.actionitems
    )
      this.setState({
        action_item: this.props.actionitems.actionitems.data,
        action_item_length: this.props.actionitems.actionitems.total_records,
        refresh_action_items: false
      });
    if (
      this.props.recentdocs.recentdocs &&
      this.props.recentdocs.recentdocs !== prevProps.recentdocs.recentdocs
    )
      this.setState({ recent_docs: this.props.recentdocs.recentdocs });
  };

  /* Clearing all the timers when screen unmounted so that APIs are not called for every 10 seconds*/
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.timer1);
    this.timer1 = null;
    clearInterval(this.timer3);
    this.timer3 = null;
  }
  /* funciton to handle pagination for supported components in overview screen */
  changePage = (action, item) => {
    if (action === "next") {
      if (item === "recent") {
        this.setState(
          (prevState) => {
            return {
              recent_page_num: prevState.recent_page_num + 1,
            };
          },
          () => {
            this.props.getRecentDocs(
              c.ROOT_URL +
                "/getRecentDocs?page_limit=15&page_number=" +
                this.state.recent_page_num
            );
          }
        );
      } else if (item === "action") {
        this.setState(
          (prevState) => {
            return {
              refresh_action_items : true,
              action_page_num: prevState.action_page_num + 1,
            };
          },
          () => {
            this.props.getActionItems(
              c.ROOT_URL +
                "/getActionItemsAssigned?page_limit=15&page_number=" +
                this.state.action_page_num
            );
          }
        );
      } else {
        this.setState(
          (prevState) => {
            return {
              refresh_logs: true,
              logs_page_num: prevState.logs_page_num + 1,
            };
          },
          () => {
            this.props.getLogs(
              c.ROOT_URL +
                "/getNotifications?page_limit=15&page_number=" +
                this.state.logs_page_num
            );
          }
        );
      }
    } else {
      if (item === "recent") {
        this.setState(
          (prevState) => {
            return {
              recent_page_num: prevState.recent_page_num - 1,
            };
          },
          () => {
            this.props.getRecentDocs(
              c.ROOT_URL +
                "/getRecentDocs?page_limit=15&page_number=" +
                this.state.recent_page_num
            );
          }
        );
      } else if (item === "action") {
        this.setState(
          (prevState) => {
            return {
              refresh_action_items: true,
              action_page_num: prevState.action_page_num - 1,
            };
          },
          () => {
            this.props.getActionItems(
              c.ROOT_URL +
                "/getActionItemsAssigned?page_limit=15&page_number=" +
                this.state.action_page_num
            );
          }
        );
      } else {
        this.setState(
          (prevState) => {
            return {
              refresh_logs : true,
              logs_page_num: prevState.logs_page_num - 1,
            };
          },
          () => {
            this.props.getLogs(
              c.ROOT_URL +
                "/getNotifications?page_limit=15&page_number=" +
                this.state.logs_page_num
            );
          }
        );
      }
    }
  };
  render() {
    return (
      <div className="flex-style-overview">
        <Navbar
          handlePageChange={this.handlePageChange}
          changeCurrentPage={(page) => this.setState({ currentPage: page })}
        />
        {this.state.isLoading ? (
          <CircularProgress style={{ color: "grey", margin: "40vh auto" }} />
        ) : (
          <>
            <Grid className="left-overview">
              <DocsStatusView
                goToIntervention={this.goToIntervention}
                int_metrics={this.state.int_metrics}
                doc_status={this.state.doc_status}
              />
            </Grid>
            <div className = 'logs-in-tablet'>
              <Rightview
                goToIntervention={this.goToIntervention}
                log_data={this.state.log_data}
                logs_data_length={this.state.logs_data_length}
                logs_page_num={this.state.logs_page_num}
                changePage={this.changePage}
                refresh_logs={this.state.refresh_logs}
              />
              </div>
            <Grid className="perform-metrics-grid">
              <MetricsChart goToIntervention={this.goToIntervention} />
              <RecentDocs
                goToIntervention={this.goToIntervention}
                recent_docs={this.state.recent_docs}
                action_item={this.state.action_item}
                recent_page_num={this.state.recent_page_num}
                action_page_num={this.state.action_page_num}
                action_item_length={this.state.action_item_length}
                changePage={this.changePage}
                refresh_action_items={this.state.refresh_action_items}
              />
            </Grid>
            <Grid className="right-view-grid">
              <Rightview
                goToIntervention={this.goToIntervention}
                log_data={this.state.log_data}
                logs_data_length={this.state.logs_data_length}
                logs_page_num={this.state.logs_page_num}
                changePage={this.changePage}
                refresh_logs={this.state.refresh_logs}
              />
            </Grid>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logs: state.logs,
    items: state.items,
    int_metrics: state.int_metrics,
    recentdocs: state.recentdocs,
    actionitems: state.actionitems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    passDocId: (doc_id) => dispatch(passDocId(doc_id)),
    getLogs: (url) => dispatch(getLogs(url)),
    getDocsStatus: (url) => dispatch(getDocsStatus(url)),
    getImetrics: (url) => dispatch(getImetrics(url)),
    getRecentDocs: (url) => dispatch(getRecentDocs(url)),
    getActionItems: (url) => dispatch(getActionItems(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
