import axios from "axios";
import * as c from '../constants/constants';
import { getToken } from "../../commonFunctions/getToken";

//const root_url = "https://docuroute-backend-yedpqwswya-uc.a.run.app/api/v1";
let auth_token = '';
auth_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";

export default {
  login: function (username, pwd, ip) {

    return axios({
      method: "post",
      url: c.ROOT_URL + "/login",
      auth: {
        username: username,
        password: pwd,
      },
      data: ip
    })
  },
  refresh: function () {
    let refresh_token = localStorage.getItem(c.REFRESH_TOKEN);
    let url = c.SSO && localStorage.getItem(c.SSO_ID) ?
      c.ROOT_URL + "/getTokenRefreshed?t_id=" + localStorage.getItem(c.SSO_ID)
      :
      c.ROOT_URL + "/getTokenRefreshed?t_id=goaBYslwRfubJKDwPISj";
    return axios({
      method: "get",
      url: url,
      headers: { Authorization: "Bearer " + refresh_token },

    })
  },
  general_settings: async function () {
    // auth_token = sessionStorage.getItem('access');
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocuSettings",
      headers: { Authorization: "Bearer " + auth_token },

    })
  },
  recent_documents: function (page_limit, page_num) {
    auth_token = localStorage.getItem('access');
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getRecentDocs",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  action_items: function (page_limit, page_num) {
    auth_token = localStorage.getItem('access');
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getActionItemsAssigned",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  notifications: function (page_limit, page_num) {
    auth_token = localStorage.getItem('access');
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getNotifications",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  document_status_count: function () {
    auth_token = localStorage.getItem('access');
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocsStatusCount",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  document_categories: function () {
    auth_token = localStorage.getItem('access');

    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocsCategoryCount",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  document_locations: function () {
    auth_token = localStorage.getItem('access');
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getLocationsCount",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },

  document_list: function (page_number, modal) {
    auth_token = localStorage.getItem('access');
    var url = "";
    if (modal === 'by_page' && page_number && page_number !== "") {
      url = c.ROOT_URL + "/getDocsList?page_number=" + page_number;
    }
    else if (modal === 'all_records' && page_number && page_number !== "") {
      url = c.ROOT_URL + "/getDocsList?page_limit=" + page_number;
    }
    else url = c.ROOT_URL + "/getDocsList";
    return axios({
      method: "get",
      url: url,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  document_list_all: function (page_limit) {
    auth_token = localStorage.getItem('access');
    var url = "";
    url = c.ROOT_URL + "/getDocsList?page_limit=" + page_limit;
    return axios({
      method: "get",
      url: url,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  fetch_results: async function (query, page_number, page_limit) {
    let auth_token = await getToken();
    // auth_token = localStorage.getItem('access');
    var url = "";
    if (page_number)
      url = c.ROOT_URL + "/getDocsListByFilter?page_number=" + page_number;
    else if (page_limit)
      url = c.ROOT_URL + "/getDocsListByFilter?page_limit=" + page_limit;
    else url = c.ROOT_URL + "/getDocsListByFilter";
    return axios({
      method: "post",
      url: url,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  review_doc: function (doc_id) {
    auth_token = localStorage.getItem('access');
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDoc/" + doc_id,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  update_after_review: async function (query, docId, revId, comp, action, str) {
    // auth_token = localStorage.getItem('access');
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/createDocumentRevision/" + docId + "/" + revId + '?comp=' + comp + '&action=' + action + (str === "pm" ? "&force_patient_match=yes" : ''),
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  skip_upload: async function (query, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/skipUpload?comp=" + comp + '&action=' + action,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  upload_file: async function (file_array, comp, action) {
    // auth_token = localStorage.getItem('access');
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/uploadDocument?comp=" + comp + '&action=' + action,
      data: file_array,
      headers: {
        "Content-Type": 'multipart/form-data',
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  fetch_results_audit: async function (query, page_number, page_limit) {
    //auth_token = localStorage.getItem('access');
    let auth_token = await getToken();
    var url = "";
    url = c.ROOT_URL + "/getAuditLogsByFilter?page_number=" + page_number + "&page_limit=" + page_limit;

    return axios({
      method: "post",
      url: url,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  create_user: async function (userdata, comp, action) {
    // auth_token = localStorage.getItem('access');
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/createUser?comp=" + comp + '&action=' + action,
      data: userdata,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  update_user: async function (userdata, user_id, comp, action) {
    // auth_token = localStorage.getItem('access');
    let auth_token = await getToken();
    return axios({
      method: "put",
      url: `${c.ROOT_URL}/updateUser/${user_id}?comp=${comp}&action=${action}`,
      data: userdata,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  update_tags: async function (doc_id, data, rev_id, comp, action) {
    // auth_token = localStorage.getItem('access');
    let auth_token = await getToken();
    return axios({
      method: "put",
      url: `${c.ROOT_URL}/updateTags/${doc_id}/${rev_id}?comp=${comp}&action=${action}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  exit_doc_details: async function (doc_id, data) {
    // auth_token = localStorage.getItem('access');
    let auth_token = await getToken();
    return axios({
      method: "put",
      url: `${c.ROOT_URL}/updateUserActiveStatus/${doc_id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  search_suggest_results: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: `${c.ROOT_URL}/getDocsListSuggestions`,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  search_suggest_results_audit: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: `${c.ROOT_URL}/getAuditLogsSuggestions`,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  search_suggest_results_user: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: `${c.ROOT_URL}/getUsersListSuggestions`,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  /*===========ip address public api=============*/
  get_ip: function (doc_id) {
    return axios({
      method: "get",
      url: 'https://api.ipify.org/?format=json'

    });
  },
  all_doc_details: async function (doc_id) {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getDocDetail/" + doc_id,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  get_doc_id: async function (query, doc_id, step) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getAdjacentDocument/" + doc_id + "/" + step,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  doc_stuck: async function (query, page_limit) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getDocListInProgress?page_limit=" + page_limit,
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  change_doc_status: async function (doc_id, query) {
    let auth_token = await getToken();
    return axios({
      method: "put",
      url: c.ROOT_URL + "/updateDocumentStatus/" + doc_id,
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    });
  },
  get_all_flags: async function () {
    let auth_token = await getToken();
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getTagsList",
      headers: { Authorization: "Bearer " + auth_token },
    });
  },

  upload_to_onbase: async function (query, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/uploadDocToMediTech?comp=" + comp + '&action=' + action,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  complete_document: async function (query, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/completeDocument?comp=" + comp + '&action=' + action,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  skip_upload: async function (query, comp, action) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/skipUpload?comp=" + comp + '&action=' + action,
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      },
    });
  },
  patient_encounters: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientEncounterDetails",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  patient_orders: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/patientOrderDetails",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  order_category: function () {
    auth_token = localStorage.getItem('access');
    return axios({
      method: "get",
      url: c.ROOT_URL + "/getEpicOrderCategory",
      headers: { Authorization: "Bearer " + auth_token },
    })
  },
  getUserManagementExport: async function (query, search_string, page_limit) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getUsersList?page_number=0" +
        "&page_limit=" + page_limit +
        "&sort_column=Last Name" +
        "&sort_order=asc" +
        "&search_string=" + search_string,
      data: query,
      headers: { Authorization: "Bearer " + auth_token },
    })
  },
  getRpMetrics: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayDocumentMetrics",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  avgPageByCategory: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayAveragePagesByCategory?page_limit=0",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  avgPageByLocation: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayAveragePagesByLocation?page_limit=0",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  rpGraphData: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayMonthlyStatistics",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  dailyStatics: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayDailyStatistics",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  hourlyLocationStatics: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayHourlyStatistics",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  locationStaticsByDate: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayDailyStatisticsByLocation",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },
  hourlyStatisticsByDate: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/displayHourlyStatisticsByDate",
      headers: { Authorization: "Bearer " + auth_token },
      data: query,
    });
  },

  getSubCategory: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getSubCategory",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },

  getAccuracyTracker: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getAccuracyTracker",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
  getDocStuck: async function (query) {
    let auth_token = await getToken();
    return axios({
      method: "post",
      url: c.ROOT_URL + "/getDocListInProgress?page_limit=0",
      data: query,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth_token,
      }
    });
  },
};


