import { CSVLink } from "react-csv";
import * as c from "../../../utils/constants/constants";
import React, { useEffect, useState } from "react";
import { GetCurrentDateAndTime } from "../../../commonFunctions/commonFunctions";


const getFilterDate = (props) => {
    let finalDate = ` for between ${props.fromDate} and ${props.toDate}`;
    return "Document: " + c.PRODUCT_NAME + " Accuracy report " + finalDate + "."
}
const ExportAccuracy = (props) => {
    const [dummy_headers, updateDummyHeaders] = useState([]);
    const [combined_json, updateCombinedJson] = useState([]);
    var name_formatted = ` ${props.fromDate} and ${props.toDate}`;
    useEffect(() => {
        let d_headers = [
            { label: getFilterDate(props), key: "category" },
            { label: '', key: "column_0" },           
        ]

        let length = Object.keys(props.headers || {}).length;

        for (let i = 0; i < length; i++) {
            d_headers.push({
                label: "",
                key: "column_" + (i + 1)
            })
        }
        updateDummyHeaders(d_headers);
        const export_info = [
            { category: ""},
            { category: "Export By:", column_0: localStorage.getItem(c.USER_NAME) },
            { category: "Export Date/Time:", column_0: GetCurrentDateAndTime("MM/DD/YYYY, hh:mm A") },
        ]
        const acc_headers = (rawData) => {
            let headers = { category: "Category" };
            let acc_header = props.headers;
            acc_header.forEach((m, i) => {
                headers["column_" + (i)] = m;
            });
            
            return {
                headers: [(headers || {})],
            }
        }

        const empty_row =[
            {category:""}
        ]

        const report_end = [
            { category: "" },
            { category: "<End of Report>" }
        ]

        let final_Data =[];
        let header_len = Object.keys(props.headers).length

        props.tableData.forEach(item => {
            let temp_obj={category:""}
           temp_obj.category=props.accuracyObjKeys[item.category]
           
            final_Data.push(temp_obj)
            if(item){
            item.children.forEach(child=>{
                let temp_child ={category:""}
                temp_child.category=props.accuracyObjKeys[child.category]
                for(let i=0;i<header_len;i++){
                    let col_nam='column_'+(i);
                   temp_child[col_nam]=child[col_nam]
                }
                    final_Data.push(temp_child)
            })}
        })
        let acc_header = acc_headers(props)
        let combinedjson = [];
        combinedjson = export_info.concat(empty_row).concat(acc_header.headers).concat(final_Data).concat(report_end);
        updateCombinedJson(combinedjson)
    }, [props.tableData, props.headers]);


    return (
        <div className="export-report-link">
            <CSVLink
                className="export-report-link"
                data={combined_json}
                headers={dummy_headers}
                filename={"readabl_accuracy_tracker_report_" + name_formatted + ".csv"}>
                Export
            </CSVLink>
        </div>
    )
}
export default ExportAccuracy;