import React from "react";
import { Dialog, DialogTitle} from "@mui/material";
import {DoneOutline} from '@mui/icons-material';


class AlertBox extends React.Component {
  state = {
    open: true,
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount = ()=> {
    this.setState({open : true});
  }
  render() {
    return (
      <>
      <Dialog  open={this.state.open}  onClose={this.handleClose}>
         <DialogTitle>
           <div className = 'icon-text'>
              <DoneOutline />
            <p style = {{marginLeft : '10px'}}>Data saved successfully</p>
            </div>
         </DialogTitle>
    </Dialog>
       
      </>
    );
  }
}

export default AlertBox;
