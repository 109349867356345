import jwt_decode from "jwt-decode";
import * as dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
dayjs.extend(utc);

export const ConvertUtcToLocal = (date, format = "MM/DD/YYYY, hh:mm:ss A") => {
  if(dayjs(date).isValid())
    return dayjs.utc(date).local().format(format);
  return ""
}
export const ConvertLocalToUtc = ({date, format = "MM/DD/YYYY, hh:mm:ss A", startOf, endOf}) => {
  if(date)
    if(startOf) return dayjs(date).startOf(startOf || "day").utc().format(format);
    if(endOf) return dayjs(date).endOf(endOf || "day").utc().format(format);
  return ""
}

export const FormatDate = ({date, format = "MM/DD/YYYY, hh:mm:ss A"}) => {
  if(date)
    return dayjs(date).format(format);
  return ""
}

/*================provides current date in mm/dd/yyyy ===========*/
export const GetCurrentDateAndTime = (format = "MM/DD/YYYY hh:mm:ss A") => {
  return dayjs().format(format);
}

/*========converts the given string to ConvertToTitleCase=====*/
export const ConvertToTitleCase = (str,ch) => {
  var splitStr =
    str.indexOf(ch) === -1
      ? str.toLowerCase().split(" ")
      : str.toLowerCase().split(ch);
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

/*==============converts seconds to Minutes and seconds===========*/
export const SecondsToMinutes = (sec) => {
  sec = Math.round(sec);
  var minutes =  sec / 60;
  var seconds =  sec % 60;
  return {
    minutes: minutes.toString().split(".")[0],
    seconds
  }
}

/*======check expiry of access/refresh token========================*/
//let key = "-----BEGIN CERTIFICATE-----MIIC/DCCAeSgAwIBAgIIP04+LrctbcwwDQYJKoZIhvcNAQEFBQAwIDEeMBwGA1UEAxMVMTA2MjU0MzgxOTEyMjkwNDc2NTMzMCAXDTIwMTIxODExMDQzN1oYDzk5OTkxMjMxMjM1OTU5WjAgMR4wHAYDVQQDExUxMDYyNTQzODE5MTIyOTA0NzY1MzMwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDAFJ8CYGjpLD8hC0Sy1kcnfUx5B4hu8rpWLCxs6XY9sCLL8IVIt3bxj2Iu/hteWGBRjuoDpSgCOtk+REaC9fcUsFbmdwEz6fIFw5ig542yhlDsenb6x9Mpe049EfV7TrgxFAPXbOsdQdLOX5tHTFLrhpVkgOZbrW99gs9+P6bllpUM9yVtnkcHWqnkfyRvwIJRBIRYhW1xchwHivVRtRdIAEAWLU0hjJQqkx0RDJWxB+qMoeVJZXIWHE9v8uAyfTrmEaSIdUR4PBcXSRmdmGdWHUX8uyOupzmIVbP9Pnyccgl/nSANDJJaV/URPyiGOuwdhR2wdRQ9Wd8MVs1lkwAnAgMBAAGjODA2MAwGA1UdEwEB/wQCMAAwDgYDVR0PAQH/BAQDAgeAMBYGA1UdJQEB/wQMMAoGCCsGAQUFBwMCMA0GCSqGSIb3DQEBBQUAA4IBAQA/gVqMZ4V+U/QwzYV9rp84gRSqOQOwsdwadAwtYWIQhbTZ87k4PS8dNRz0GClpG/rxb7HZYKRP6WC/3gpNcFqeowyQzndkHqiW2WZQn7V8j3LFCp8L0XahnRnUI2WP5x4C0Wt7wsFNoxoASHb/LTBuv/Y3K8bnK7B8XIgEVLpFKrBx55ICPt5vp/YqW9FZkaY7+HFMNvQQ8e8Fuu4w/5WExu1G36zz70J8EBqZfrgHHn+KuIm9IC7e2gLbi6uFJTeknzBqF1Tv2TDf6wbWOUy9OOvoFQHScztawaQz+YVnjwC6RVpTGIVu3t8tgzvPXpmDZhD6B9YRT/F9q4Ez7mqe-----END CERTIFICATE-----";
export const TokenExpiry = (token) => {
  var isValid = "";
  if (token) {
    const { exp } = jwt_decode(token);
    const expirationTime = exp * 1000 - 300000;
    if (Date.now() >= expirationTime) isValid = false;
    else isValid = true;
  }
  return isValid;
};
/*=================check user role by decoding access token============*/
export const GetUserInfo = (token) => {
  if (token) {
    const { user } = jwt_decode(token);
    return user;
  }
};

export const GetTimeZone = () => {
  let offset = new Date().getTimezoneOffset();
  let o = Math.abs(offset);
  return "-07:00";
}

export const SortData = (data, key, order) => {
    let tempData = (order == "des") ? data.sort((a, b) => (b[key] < a[key]) ? 1 : -1) : data.sort((a, b) => (a[key] < b[key]) ? -1 : 1)
    return tempData

}
export const GetDefaultDate = ({duration, type, format = "YYYY-MM-DD"}) => {
  let currentDate = dayjs().format(format);
  let fromDate = dayjs().subtract(duration, type || "day").format(format);
  return {
    fromDate: fromDate,
    toDate: currentDate
  }
}

export const GetDateRange = ({type = "day", format = "MM-DD-YYYY hh:mm:ss"}) => {
    return {
      fromDate: dayjs().startOf(type).format(format),
      toDate: dayjs().endOf(type).format(format),
    }
}


