import React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import * as c from "../../../../utils/constants/constants";
import { ConvertUtcToLocal } from "../../../../commonFunctions/commonFunctions";
import {CustomTooltip} from "../../../../components/CustomTooltip/CustomTooltip";

class TableData extends React.Component {
  render() {
    return (
      <>
        <TableBody>
          {this.props.audit_data &&
            this.props.audit_data.map((data, i) => (
              <TableRow key = {i}>
                <TableCell className="tableCellStyle" align="left">
                  <div>
                    <p style={{ margin: "0px" }}>{data.name}</p>
                  </div>
                </TableCell>
                <TableCell className="tableCellStyle" align="left">
                  <div>
                    <p style={{ margin: "0px", textTransform: "capitalize" }}>
                      {data.component}
                    </p>
                  </div>
                </TableCell>
                <TableCell  className="tableCellStyle" align="left">
                  {data.document_name.length > 40 ? (
                    <CustomTooltip title={data.document_name} placement="top-start">
                      <div style = {{cursor : 'pointer'}}>
                        <p className="doc-name-focus" onClick={() => this.props.goToIntervention(data.doc_id)}>
                          {data.document_name.length > 40
                            ? data.document_name.substr(0, 25) +
                              "..." +
                              data.document_name.substr(
                                data.document_name.length - 10,
                                data.document_name.length
                              )
                            : data.document_name}
                        </p>
                        <p style = {{margin : '0px',fontSize : '0.75em',color : '#a2a0a0'}}>{data.doc_id}</p>
                      </div>
                    </CustomTooltip>
                  ) : (
                    <div style = {{cursor : 'pointer'}}>
                      <p onClick={() => this.props.goToIntervention(data.doc_id)} className="doc-name-focus">{data.document_name}</p>
                      <p style = {{margin : '0px',fontSize : '0.75em',color : '#a2a0a0'}}>{data.doc_id}</p>
                    </div>
                  )}
                </TableCell>
                <TableCell  className="tableCellStyle" align="left">
                  {data.original_file_name.length > 40 ? (
                    <CustomTooltip title={data.original_file_name} placement="top-start">
                      <div>
                        <p className="doc-name-blur" >
                          {data.original_file_name.length > 40
                            ? data.original_file_name.substr(0, 25) +
                              "..." +
                              data.original_file_name.substr(
                                data.original_file_name.length - 10,
                                data.original_file_name.length
                              )
                            : data.original_file_name}
                        </p>
                      </div>
                    </CustomTooltip>
                  ) : (
                    <div >
                      <p className="doc-name-blur">{data.original_file_name}</p>
                    </div>
                  )}
                </TableCell>
                <TableCell className="tableCellStyle" align="left">
                  <div>
                    <p style={{ margin: "0px" }}>
                      {data.action}
                    </p>
                  </div>
                </TableCell>
                <TableCell className="tableCellStyle" align="left">
                  <div>
                    <p style={{ margin: "0px" }}>
                      {ConvertUtcToLocal(data.last_updated_date + " " + data.last_updated_time + " UTC", "MM/DD/YYYY, hh:mm A")}
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </>
    );
  }
}

export default TableData;
