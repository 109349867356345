import React from "react";
import "./Metrics.css";
import { SecondsToMinutes } from "../../../../commonFunctions/commonFunctions";

const Metrics = (props) => {
  return (
    <>
      <p className="title" style={{ textAlign: "left" }}>
        Document Metrics
      </p>
      {props.rp_metrics[0] && (
        <div className="rp-metrics-section">
         
          <div className="rp-each-metric">
            <div>
              <div className="icon-text">
                <div className="rp-flex-baseline">
                  <div className="rp-metrics-numbers">
                    {props.rp_metrics[0].Documents_Received ?
                      props.rp_metrics[0].Documents_Received.toLocaleString() : "0"}
                  </div>
                  <div className="rp-mini-text">docs</div>
                </div>
              </div>
            </div>
            <div className="rp-text-metrics">Documents Received</div>
          </div>
          <div className="rp-each-metric">
            <div>
              <div className="icon-text">
                <div className="rp-flex-baseline">
                  <div className="rp-metrics-numbers">
                    {props.rp_metrics[0].Total_Pages_Processed ?
                      props.rp_metrics[0].Total_Pages_Processed.toLocaleString() : "0"}
                  </div>
                  <div className="rp-mini-text">pages</div>
                </div>
              </div>
            </div>
            <div className="rp-text-metrics">Total Pages Processed</div>
          </div>
          <div className="rp-each-metric">
            <div>
              <div className="icon-text">
                <div className="rp-flex-baseline">
                  <div className="rp-metrics-numbers">
                    {props.rp_metrics[0].Average_Pages_Processed ?
                      props.rp_metrics[0].Average_Pages_Processed.toLocaleString() : "0"}
                  </div>
                  <div className="rp-mini-text">pages per doc</div>
                </div>
              </div>
            </div>
            <div className="rp-text-metrics">Average Pages Processed</div>
          </div>
          <div className="rp-each-metric">
            <div>
              <div className="icon-text">
                <div className="rp-flex-baseline">
                  {props.rp_metrics[0].Average_Processing_Time &&
                  props.rp_metrics[0].Average_Processing_Time < 60 ? (
                    <>
                      <div className="rp-metrics-numbers">
                        {props.rp_metrics[0].Average_Processing_Time}
                      </div>
                      <div className="rp-mini-text">sec</div>
                    </>
                  ) : (
                    <>
                      <div className="rp-metrics-numbers">
                        {props.rp_metrics[0].Average_Processing_Time ?
                          SecondsToMinutes(props.rp_metrics[0].Average_Processing_Time).minutes + " " : "0"}
                      </div>
                      <div className="rp-mini-text" style = {{marginRight : "5px"}}>min</div>
                      <div className="rp-metrics-numbers">
                        {props.rp_metrics[0].Average_Processing_Time ?
                          SecondsToMinutes(props.rp_metrics[0].Average_Processing_Time).seconds : "0"}
                      </div>
                      <div className="rp-mini-text">sec</div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="rp-text-metrics">Average Processing Time</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Metrics;
