import React from 'react';
import { Grid} from '@mui/material';
import {Info} from '@mui/icons-material';
import './DialogModel.css';
import * as msg from "../../utils/constants/messages";

const DialogModal = (props) => {
    let message = '';
        if(props.page === 'review'){
           message = msg.ext_info;
        }else 
        message = msg.no_data;
      return(
            <Grid>
                <div >
                    <Info style = {{color : 'var(--hti-title)'}} fontSize = 'large' />
                <p style = {{fontWeight : '500',marginLeft : '5px',color : '#8a8787'}}>{message}</p>
                </div>
            </Grid>
        );
    
}
export default DialogModal;

