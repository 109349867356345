import React from 'react';
import * as c from '../../utils/constants/constants';
class SSORedirect extends React.Component{
    componentDidMount = () => {
        var sso_app = c.SSO_APP;
       if(localStorage.getItem(c.SSO_ID)){
        if(localStorage.getItem(c.LOGOUT) === 'inactive') 
            window.location = sso_app+'?logout_inactive_t_id='+localStorage.getItem(c.SSO_ID);
        else if(localStorage.getItem(c.LOGOUT) === 'expired')
            window.location = sso_app+'?logout_expired_t_id='+localStorage.getItem(c.SSO_ID);
        else
            window.location = sso_app+'?logout_t_id='+localStorage.getItem(c.SSO_ID);
        }else
            window.location = sso_app;
        localStorage.clear();
      
    }
    render(){
        return(
            <>
        
            </>
        )
    }
}

export default SSORedirect;