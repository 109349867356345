import React from "react";
import { Paper, Divider, IconButton, CircularProgress} from "@mui/material";
import { Close, Add, Remove, Edit } from "@mui/icons-material";
import "./RightPlane.css";
class RightPlane extends React.Component {
  zoomIn = () => {
    var image = document.getElementById("docImage");
    var currWidth = image.clientWidth;
    image.style.width = currWidth + 50 + "px";
  };
  zoomOut = () => {
    var image = document.getElementById("docImage");
    var currWidth = image.clientWidth;
    image.style.width = currWidth - 50 + "px";
  };
  findClick = (e) => {
    e.preventDefault();
  }
  render() {
    return (
      <Paper elevation={3} className="right-plane-width">
        <div className="title-box-flex">
          <p
            className="sub-title"
            style={{ padding: "10px", textAlign: "left" }}
          >
            Document preview
          </p>
          <div className="icon-text">
            <div
              className="edit-action"
              onClick={() =>
                this.props.goToIntervention()
              }
            >
              <p className="edit-text-style">Edit</p>
              <Edit className="icon-action-edit" fontSize="small" />
            </div>

            <IconButton onClick={() => this.props.showRightPlane("close")} size="large">
              <Close className="icon-action-edit" fontSize="small" />
            </IconButton>
          </div>
        </div>
        <Divider />
        {this.props.doc_format !== 'pdf' &&
          <div className="icon-text" style={{ float: "right" }}>
            <IconButton onClick={this.zoomOut} size="large">
              <Remove className="icon-action-edit" fontSize="small" />
            </IconButton>
            <p>Zoom</p>
            <IconButton onClick={this.zoomIn} size="large">
              <Add className="icon-action-edit" fontSize="small" />
            </IconButton>
          </div>}
        <div className={this.props.doc_format === "pdf" ? "img-div-pdf" : "img-div"}>

          <div style={{ height: '50vh' }}>
            {this.props.image_url && this.props.image_url.length > 0 ?
              <>
                {this.props.doc_format === 'pdf' ?
                  <embed id='docImage' src={this.props.image_url[0].signed_url} key={this.props.image_url[0].signed_url} alt='doc_image' style={{ width: '120%', height: '100%' }}
                    onClick={() => this.props.goToIntervention()} />
                  :
                  <img id='docImage' src={this.props.image_url[0].signed_url} key={this.props.image_url[0].signed_url} alt='doc_image' draggable='false' onContextMenu={(event) => this.findClick(event)}
                    style={{ width: '90%' }}
                    onClick={() => this.props.goToIntervention()} />
                }
              </>
              :
              <CircularProgress style={{ color: 'grey', marginTop: '50%' }} />
            }
          </div>
        </div>
        <div className="key-value">
          <div className="heading">
            <p>Document ID </p>
            <p>Document Format </p>
          </div>
          <div className="values">
            <div style={{ margin: "3.5em" }}>
              <div>
                <p>{this.props.doc_id}</p>
              </div>
              <div>
                <p>{this.props.doc_format}</p>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

export default RightPlane;
