import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Table,
  TableContainer,
  Paper,
  CircularProgress,
  FormControl,
  Select,
} from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import SearchBar from "../../components/SearchBar/SearchBar";
import DialogModel from "../../components/DialogModel/DialogModel";
import TablePage from "../../components/TablePage/TablePage";
import TableHeader from "./DocTable/TableHeader/TableHeader";
import TableData from "./DocTable/TableData/TableData";
import AddUser from "./AddUser/AddUser";
import api from "../../utils/API/api";
import {
  getUsersTableData,
  getAllLocations_user,
  addUser,
  editUser,
} from "../../redux/usermanagement/actions/actions";
import * as c from "../../utils/constants/constants";
import * as msg from "../../utils/constants/messages";
import {forceLogout} from "../../commonFunctions/forceLogout";
import "./UserManagement.css";
import UserExport from "./UserExport";
import {Launch} from '@mui/icons-material';

const defaultValues = {
  pageNum: 0,
  recordsPerPage: 15,
  status: true,
  role: "",
  search: "",
  sort_order: "asc",
  sort_key: "Last Name",
  tableHeadData: [
    { name: "First Name", order: true, icon: false },
    { name: "Last Name", order: false, icon: true },
    { name: "Email", order: true, icon: false },
    { name: "Role", order: true, icon: false },
    { name: "Status", order: true, icon: false },
    { name: "Last Login", order: true, icon: false },
    { name: "Last Edited", order: true, icon: false },
    { name: "Action" },
  ]
}
class UserManagement extends React.Component {
  state = {
    open: false,
    data: [],
    locationlist: [],
    tableHeadData: JSON.parse(JSON.stringify(defaultValues.tableHeadData)),
    total_records: 0,
    recordsPerPage: defaultValues.recordsPerPage,
    pageNum: defaultValues.pageNum,
    search: "",
    suggest_box: "",
    status: defaultValues.status,
    role: defaultValues.role,
    is_search: false,
    sort_order: "", //stores the order of sort true/false for selected column
    sort_key: "", //stores the selected column header name for sorting,
    userdetails: {
      first_name: "",
      last_name: "",
      email: "",
      active: "true",
      role: "Admin",
      location_ids: [],
      phone: "",
      user_id: null,
    },
    showErrorMessage: false,
    errorMessage: "",
    adduserresponse: [],
    isLoading: true,
    loc_loader: true,
    action : '',
    refresh_list : false
  };
  

  constructor(props) {
    super(props);
    this.csvLinkEl = React.createRef();
  }
  /*API call is made on mount to load user list table data */
  componentDidMount = () => {
    document.title = c.PRODUCT_NAME + " - User Management";
    localStorage.removeItem(c.DOC_STATUS);
    this.sort("Last Name");
    if (this.props.usertabledata.usertabledata)
      this.setState({
        data: this.props.usertabledata.usertabledata.data,
        total_records: this.props.usertabledata.usertabledata.total_records,
      });
    if (this.props.alllocations_user.alllocations_user)
      this.setState({
        locationlist: this.props.alllocations_user.alllocations_user,
      });
  };
  componentDidUpdate = (prevProps) => {
    if (
      this.props.usertabledata.hasError === 401 ||
      this.props.alllocations_user.hasError === 401
    ) {
      forceLogout();
    } else if (
      this.props.usertabledata.hasError ||
      this.props.alllocations_user.hasError 
    ){
      this.setState({isLoading : false, refresh_list : false});
      window.alert(msg.api_error);
    }
    if (
      this.props.usertabledata.usertabledata &&
      this.props.usertabledata.usertabledata !==
        prevProps.usertabledata.usertabledata
    )
      this.setState({
        data: this.props.usertabledata.usertabledata.data,
        total_records: this.props.usertabledata.usertabledata.total_records,
        isLoading: false,
        refresh_list: false
      });
    if (
      this.props.alllocations_user.alllocations_user &&
      this.props.alllocations_user.alllocations_user !==
        prevProps.alllocations_user.alllocations_user
    ) {
      this.setState({
        locationlist: this.props.alllocations_user.alllocations_user,
        loc_loader: false,
      });
    }
  };
  /*handles input change of filter fields*/
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if(event.target.name === "search"){
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.findData("suggest");
      }, 500); 
    }
  };

  findData = (modal, search_val) => {
    /*================alters table data based on searched text==========*/
    if (modal === "results") {
      this.setState({refresh_list: true,is_search: true,suggest_box : []});
      this.setState({ search : search_val,pageNum: 0 }, () => {
        this.props.getUsersTableData(
          c.ROOT_URL +
          `/getUsersList?search_string=${search_val}&page_number=0&page_limit=` +
          this.state.recordsPerPage +
          "&sort_column=" +
          this.state.sort_key +
          "&sort_order=" +
          this.state.sort_dir,
          { status: this.state.status, role: this.state.role }
        );
      });
    } else {
      /*=================displays suggestion box based on searched txt==============*/
        var suggest = [];
        let query = {
          search_string : this.state.search
        }
        if(this.state.search.length > 2){
        this.setState({is_search: false});
        api.search_suggest_results_user(query).then((response) => {
          if(response.status === 200) {
            response.data.data && response.data.data.map((data,i) => {
              suggest.push(data);
            })
            this.setState({ suggest_box: suggest });
          }
        }).catch((error) => {
          if(error.response.status === 401){
           forceLogout();
          }else{
              this.setState({refresh_list : false});
              window.alert(msg.api_error);
          }
          });
        }else{
            suggest = [];
            this.setState({suggest_box : suggest,is_search: true});
          }
      }
  };
  /*sorts user list table based on the icon present in column headers*/
  sort = (key) => {
    this.setState({refresh_list : true});
    let sort_order = "";
    let sort_dir = "";
    let table_head = JSON.parse(JSON.stringify(this.state.tableHeadData));
    for (var i = 0; i < table_head.length; i++) {
      if (
        key !== "Action" &&
        key.replace("_", " ") === table_head[i].name
      ) {
        table_head[i].order = !table_head[i].order;
        sort_order = table_head[i].order;
        sort_order ? (sort_dir = "asc") : (sort_dir = "desc");
        table_head[i].icon = true;
      } else table_head[i].icon = false;
    }
    this.setState({tableHeadData : table_head, sort_order: sort_order, sort_key: key, sort_dir: sort_dir, pageNum: defaultValues.pageNum, recordsPerPage: defaultValues.recordsPerPage }, () => {
      this.props.getUsersTableData(
        c.ROOT_URL +
          "/getUsersList?page_number=" + this.state.pageNum + "&page_limit=" + this.state.recordsPerPage + "&sort_column=" +
          key +
          "&sort_order=" +
          sort_dir,
          {status: this.state.status, role: this.state.role}
      );
    });
  };

  closeDialog = () => {
    this.setState({ open: false, locationlist: [] });
  };

  getRowData = (obj) => {
    this.setState({
      userdetails: {
        first_name: obj.first_name,
        last_name: obj.last_name,
        email: obj.email,
        active: JSON.stringify(obj.status),
        role: obj.role,
        location_ids: [],
        phone: obj.phone,
        user_id: obj.user_id,
      },
      open: true,
      loc_loader: true,
      showErrorMessage : false,
      errorMessage : "",
      locationlist : [],
      action : 'edit'
    });
    this.props.getAllLocations_user(
      c.ROOT_URL + "/getLocationsForUser/" + obj.user_id
    );
  };
/*function to handle paginaiton of user list table*/
  handleChangePage = (event, newPage) => {
    this.setState({refresh_list : true, pageNum: newPage});
    if(this.state.search)
      this.props.getUsersTableData(
        c.ROOT_URL +
          "/getUsersList?search_string=" +
          this.state.search +
          "&page_number=" +
          newPage +
          "&page_limit=" +
          this.state.recordsPerPage +
          "&sort_column=" +
          this.state.sort_key +
          "&sort_order=" +
          this.state.sort_dir,
          {status: this.state.status, role: this.state.role}
      );
    else
      this.props.getUsersTableData(
        c.ROOT_URL +
          "/getUsersList?page_number=" +
          newPage +
          "&page_limit=" +
          this.state.recordsPerPage +
          "&sort_column=" +
          this.state.sort_key +
          "&sort_order=" +
          this.state.sort_dir,
          {status: this.state.status, role: this.state.role}
      );
  };
  /*function to handle checkbox component of locations listed inside add/edit user popup*/
  _handleCheckboxChange = (event) => {
    let val = parseInt(event.target.value, 10);
    if (event.target.checked) {
      this.state.locationlist.forEach((loc) => {
        if (loc.id === val) {
          loc.is_auth = true;
        }
      });
    }
    if (!event.target.checked) {
      this.state.locationlist.forEach((loc) => {
        if (loc.id === val) {
          loc.is_auth = false;
        }
      });
    }
    this.setState({ locationlist: this.state.locationlist });
  };
/*function to create a user and post to backend*/
  createNewUser = (userdata) => {
    var comp = c.UI_COMP.user.toString();
    var action = c.UI_ACTION.User_added;

    api
      .create_user(userdata, comp, action)
      .then((response) => {
        if (response.data.data[0].Status === "Failure") {
          this.setState({
            showErrorMessage: true,
            errorMessage: response.data.data[0].message,
          });
        } else {
          this.setState({ showErrorMessage: false, errorMessage: "",pageNum : 0,search : '' });
          this.closeDialog();
          this.setState({refresh_list : true});
          this.props.getUsersTableData(
            c.ROOT_URL +
              "/getUsersList?page_number=0&page_limit=" +
              this.state.recordsPerPage +
              "&sort_column=" +
              this.state.sort_key +
              "&sort_order=" +
              this.state.sort_dir,
              {status: this.state.status, role: this.state.role}
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
         forceLogout();
        }
        else{
          this.setState({refresh_list : false});
          window.alert(msg.api_error);
      }
      });
  };
/*function to edit existing user and post to backend*/
  editExistingUser = (userdata, user_id) => {
    var comp = c.UI_COMP.user.toString();
    var action = c.UI_ACTION.User_updated;

    api
      .update_user(userdata, user_id, comp, action)
      .then((response) => {
        if (response.data.data[0].Status === "Failure") {
          this.setState({
            showErrorMessage: true,
            errorMessage: response.data.data[0].message,
          });
        } else {
          this.setState({ showErrorMessage: false, errorMessage: "" });
          this.closeDialog();
          this.setState({refresh_list : true});
          this.props.getUsersTableData(
            c.ROOT_URL +
              "/getUsersList?page_number=" +
              this.state.pageNum +
              "&search_string="+ this.state.search +
              "&page_limit=" +
              this.state.recordsPerPage + 
              "&sort_column=" +
              this.state.sort_key +
              "&sort_order=" +
              this.state.sort_dir,
              {status: this.state.status, role: this.state.role}
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          forceLogout();
        }
        else{
          this.setState({refresh_list : false});
          window.alert(msg.api_error);
      }
      });
  };
  /*function to handle radio button present inside popup of add/edit user */
  _handleRadioFieldChange = (event) => {
    let val = event.target.value;
    let id = event.target.name;
    this.state.userdetails[id] = val;
    if (val === "Admin") {
      var modified_loc = [...this.state.locationlist];
      modified_loc.forEach((loc) => {
        loc.is_auth = true;
      });
      this.setState({ locationlist: modified_loc});
    }
    else if(val === "Staff"){
      this.getLocs();
    }
  };
  /* This gets called when reset filter button is clicked*/
  cancelFilter = (modal) => {
    if(modal === "search" && this.state.search){
      this.setState({refresh_list : true});
      this.setState(
        { search: "", suggest_box: [], is_search: false, pageNum: defaultValues.pageNum },
        () => {
          this.props.getUsersTableData(
            c.ROOT_URL +
              `/getUsersList?page_number=0&page_limit=` +
              this.state.recordsPerPage +
              "&sort_column=" +
              this.state.sort_key +
              "&sort_order=" +
              this.state.sort_dir,
              {status: this.state.status, role: this.state.role}
          );
        }
      );
    }
  };
  /*function to get locations belonging to a user*/
  getLocs = async (act) => {
    if(this.state.action === 'add' || act === 'add'){
      await this.props.getAllLocations_user(c.ROOT_URL + "/getLocationsList");
      
    }else if(this.state.action === 'edit'){
      await this.props.getAllLocations_user(c.ROOT_URL + "/getLocationsForUser/" + this.state.userdetails.user_id);
    }
    this.setState({loc_loader : true});
  };
  /*function to track which checkbox is checked*/
  checkboxAction = (action) => {
    if (action === "all") {
      this.state.locationlist.forEach((loc) => {
        loc.is_auth = true;
      });
    } else {
      this.state.locationlist.forEach((loc) => {
        loc.is_auth = false;
      });
    }
    this.setState({ locationlist: this.state.locationlist });
  };

  handleApplyBtn = () => {
    this.setState({
      pageNum: defaultValues.pageNum,
      recordsPerPage: defaultValues.recordsPerPage,
      refresh_list: true,
    }, () => {
      this.props.getUsersTableData(
        c.ROOT_URL +
          "/getUsersList?page_number=" +
          this.state.pageNum +
          "&search_string="+ this.state.search +
          "&page_limit=" +
          this.state.recordsPerPage +
          "&sort_column=" +
          this.state.sort_key +
          "&sort_order=" +
          this.state.sort_dir,
          {status: this.state.status, role: this.state.role}
      );
    })
    
  }

  generateExport() {
    this.setState({
      refresh_list: true
    })
    api.getUserManagementExport({role: this.state.role, status: this.state.status}, this.state.search, this.state.total_records)
    .then(res => {
      this.setState({
        exportData: res.data?.data || []
      }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
          this.setState({
            refresh_list: false
          });
        }, 1000);
      })
    })
  }
  render() {
    let latest_data = this.state.data;
    return (
      <Grid className="full-view">
        <Navbar
          handlePageChange={this.handlePageChange}
          changeCurrentPage={(page) => this.setState({ currentPage: page })}
        />
         {this.state.isLoading ? 
            <CircularProgress style={{ color: "grey", margin: "40vh auto" }} />
           : 
         <>
        { this.state.refresh_list &&
          <CircularProgress size ={30} className = "common-spinner"
         />}
        <div className= {this.state.refresh_list ? "block-area-common" : ""}>
        <Grid className="usermgmtpage">
         
            <>
              <Grid style={{ margin: "0px" }}>
                    <div className="titlebox">
                      <p className="page-title">User Management</p>
                      
                    </div>
                    <div className="top-section">
                      <div className="filters-container">
                        <div className="flex-style searchbox">
                          <SearchBar
                            search={this.state.search}
                            handleChange={this.handleChange}
                            findData={this.findData}
                            suggest_box={this.state.suggest_box}
                            cancelFilter={this.cancelFilter}
                            is_search={this.state.is_search}
                          />

                        </div>
                        <div>
                          <div style={{ display: "flex" }}>Role</div>
                          <FormControl style={{ width: "120px", marginRight: "10px" }}>
                            <Select
                              native
                              variant="standard"
                              value={this.state.role}
                              onChange={(e) => this.setState({role: e.target.value})}
                              inputProps={{
                                name: "status",
                                id: "age-native-simple",
                              }}
                            >
                              <option value="">All</option>
                              <option value="Admin">Admin</option>
                              <option value="Staff">Staff</option>

                            </Select>
                          </FormControl>
                        </div>
                        <div>
                          <div style={{ display: "flex" }}>Status</div>
                          <FormControl style={{ width: "120px", marginRight: "10px" }}>
                            <Select
                              native
                              variant="standard"
                              value={this.state.status === false ? "inactive" : (this.state.status == true ? "active" : "inactive")}
                              onChange={(e) => {
                                if(e.target.value == "active") {
                                  this.setState({
                                    status: true
                                  })
                                } else {
                                  this.setState({
                                    status: false
                                  })
                                }
                              }}
                              inputProps={{
                                name: "status",
                                id: "age-native-simple",
                              }}
                            >
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>

                            </Select>
                          </FormControl>
                        </div>
                        
                        <div className="buttons-filter">
                          <button
                            className="apply-button"
                            type="submit"
                            onClick={() => this.handleApplyBtn()}
                          >
                            <p className="apply-button-text">Apply</p>
                          </button>
                          <button
                            style={{ width: "120px" }}
                            className="reset-button"
                            type="submit"
                            onClick={() => {
                              this.setState({
                                ...defaultValues,
                              }, () => this.sort("Last Name"));
                              
                            }}
                          >
                            <p className="reset-button-text">Reset all filters</p>
                          </button>
                          <button
                            className="adduserbtn apply-button apply-button-text"
                            onClick={() => {
                              this.getLocs('add');
                              this.setState({
                                open: true,
                                errorMessage: "",
                                showErrorMessage: false,
                                userdetails: {
                                  first_name: "",
                                  last_name: "",
                                  email: "",
                                  active: "true",
                                  role: "Staff",
                                  location_ids: [],
                                  phone: "",
                                  user_id: null,
                                },
                                loc_loader: true,
                                action: 'add'
                              });
                            }}
                          >
                            Add User
                          </button>
                          <AddUser
                            openDialog={this.state.open}
                            closeDialog={this.closeDialog.bind(this)}
                            userdetails={this.state.userdetails}
                            showErrorMessage={this.state.showErrorMessage}
                            errorMessage={this.state.errorMessage}
                            createNewUser={this.createNewUser}
                            editExistingUser={this.editExistingUser}
                            locationlist={this.state.locationlist}
                            _handleCheckboxChange={this._handleCheckboxChange}
                            loc_loader={this.state.loc_loader}
                            checkboxAction={this.checkboxAction}
                            _handleRadioFieldChange={this._handleRadioFieldChange}
                          />
                        </div>
                      </div>
                      
                      <div className="user-export-icon" style={{ display: "flex", alignItems: "center", marginRight: "20px" }} onClick={e => {
                        
                      }}>
                        <Launch style={{ marginRight: "5px" }} />
                        <UserExport
                          data={this.state.exportData}
                          generateExport={this.generateExport.bind(this)}
                          csvLinkEl={this.csvLinkEl}
                        /> 
                      </div>
                      
                    </div>

                
              </Grid>
              <Grid>
                <TableContainer component={Paper} className = 'full-table'>
                  <Table stickyHeader aria-label="customized table">
                    <TableHeader
                      sort={this.sort}
                      tableHeadData={this.state.tableHeadData}
                    />
                    <TableData
                      getRowData={this.getRowData}
                      finalData={latest_data}
                      recordsPerPage={this.state.recordsPerPage}
                      data_id={this.state.data_id}
                      open={this.props.open}
                      doc_id={this.state.doc_id}
                      goToIntervention={this.goToIntervention}
                    />
                  </Table>
                  {latest_data.length === 0 && <DialogModel />}
                </TableContainer>
                <TablePage
                  count={this.state.total_records}
                  recordsPerPage={this.state.recordsPerPage}
                  pageNum={this.state.pageNum}
                  handleChangePage={this.handleChangePage}
                  refresh_list={this.state.refresh_list}
                />
              </Grid>
            </>
        </Grid>
        </div>
        </>}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usertabledata: state.usertabledata,
    alllocations_user: state.alllocations_user,
    adduserresponse: state.adduserresponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersTableData: (url, query) => dispatch(getUsersTableData(url, query)),
    getAllLocations_user: (url) => dispatch(getAllLocations_user(url)),
    addUser: (url, userdata, comp, action) =>
    dispatch(addUser(url, userdata, comp, action)),
    editUser: (url, userdata, comp, action) =>
    dispatch(editUser(url, userdata, comp, action)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
