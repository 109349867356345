import React, { Component } from "react";
import  Categories from './Categories/Categories';
import Locations from './Locations/Locations';
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import './LeftPlane.css';
import { Search, Close } from '@mui/icons-material';

class LeftPlane extends Component {
  state = {
    search_loc: "",
    search_cat: "",
    locationSearch: "",
    openLocation: true,
    openCategory: true,
    categorySearch: ""
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  getAccordionHeader = (filterData, filterOpen, searchKey, searchText, filter_type ) => {     
    return (
      <div className="title-box">
        {this.state[searchKey] === true ? (

          <div className={this.state[searchKey] ? "search-div" : "search-div-hide"}>
            <input type='text' autoFocus autoComplete='off'
              className='search-native-input'
              placeholder='Search...'
              name={searchText}
              value={this.state[searchText]}
              onChange={(e) => {
                this.handleChange(e);
                this.props.findLocation(this.state[searchText], filter_type);
              }} />
            <Close fontSize='small' className="icon-action"
              onClick={() => this.setState({ [searchKey]: false, [searchText]: '' })} />

          </div>

        ) : (
          <div className='loc-text-icon'>

            <p
              className="sub-title"
              style={{ padding: "10px", textAlign: "left", marginTop: "2px" }}
              onClick={() => this.setState({ [filterOpen]: !this.state[filterOpen] })}
            >
              {filterData}
            </p>
            <Search
              fontSize="small"
              className="icon-action"
              onClick={() => this.setState({ [searchKey]: true, [filterOpen]: true })}
            />

          </div>
        )}


      </div>
    )
  }
  render() {
   
    return (
      <>
        <div className="left-plane-width">
         
        <Accordion className="accordion_container" expanded={this.state.openLocation}>
            <AccordionSummary
              className="acoordion_cutom"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {this.getAccordionHeader("Location", "openLocation", "search_loc", "locationSearch", "doc_loc")}
            </AccordionSummary>
            <AccordionDetails className="accordion_details">
              <Locations
                doc_loc={this.props.doc_loc}
                handleLocations={this.props.handleCheckBoxes}
                findLocation={this.props.findLocation}
                loc_search_result={this.props.loc_search_result}
                searchVal={this.state}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordion_container" expanded={this.state.openCategory}>
            <AccordionSummary
              className="acoordion_cutom"
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {this.getAccordionHeader("Category", "openCategory", "search_cat", "categorySearch", "doc_cat")}
            </AccordionSummary>
            <AccordionDetails className="accordion_details">
              <Categories
                handleCategories={this.props.handleCheckBoxes}
                doc_cat={this.props.doc_cat}
                findLocation={this.props.findLocation}
                cat_search_result={this.props.cat_search_result}
                searchVal={this.state}
              />
            </AccordionDetails>
          </Accordion>
        
        </div>
      </>
    );
  }
}

export default LeftPlane;
