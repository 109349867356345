import { CSVLink } from "react-csv";
import React from "react";
import * as c from "../../../utils/constants/constants";
import { GetCurrentDateAndTime } from "../../../commonFunctions/commonFunctions";
const ExportSubCategory = (props) => {
    const dummy_headers = [
        { label: "Document: " + c.PRODUCT_NAME + " Sub category report from " + props.fromDate + " to " + props.toDate, key: "s_no" },
        { label: "", key: "SubCategory" },
        { label: "", key: "Total_files" },
        { label: "", key: "Changed" },
        { label: "", key: "Unchanged" },
        { label: "", key: "Percentage" },
    ];
    const export_info = [
        { s_no:""},
        { s_no: "Export By:", SubCategory: localStorage.getItem(c.USER_NAME) },
        { s_no: "Export Date/Time:", SubCategory: GetCurrentDateAndTime("MM/DD/YYYY, hh:mm A") },
        { s_no:""}
    ]
    const subCategory_headers = [
        {
            s_no: "S.no",
            SubCategory: "Sub Category",
            Total_files: "Total Files",
            Changed: "Changed",
            Unchanged: "Unchanged",
            Percentage: "Percentage"
        }
    ]
    const report_end = [
        { s_no:""},
        { s_no: "<End of Report>" }
    ]
    
    let metrics_data = props.tableData.map((item, index) => {
        return {
            s_no: index+1,
            Changed: item.changed,
            Percentage: item.percentage,
            SubCategory: item.sub_category,
            Total_files: item.total_file,
            Unchanged: item.unchanged
        }
    })
    let combined_json = [];
    combined_json = export_info.concat(subCategory_headers).concat(metrics_data).concat(report_end);
    var name_formatted = `${props.fromDate} and ${props.toDate}`;
    
    return (
        <div className="export-report-link">
            <CSVLink
                className="export-report-link"
                data={combined_json}
                headers={dummy_headers}
                filename={"readabl_subcategory_report_" + name_formatted + ".csv"}>
                Export
            </CSVLink>
        </div>
    )
}
export default ExportSubCategory;