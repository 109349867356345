import React, { Component } from "react";
import "./Filter.css";
import {
  Grid,
  TextField, Select, InputLabel, FormControl
} from "@mui/material";
import * as c from '../../../utils/constants/constants';
import * as dayjs from "dayjs";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";

class Filter extends Component {
  state = {
    showFlags: false,
    dateError: false,
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  getMinMax() {
    let fromDate = dayjs(this.props.audit_filter?.fromDate);
    let max = fromDate.add(90, "day").format("MM/DD/YYYY");
    if (dayjs(max).isAfter(dayjs())) {
      max = dayjs().format("MM/DD/YYYY");
    }
    this.props.handleChange("toDate", max)
    this.setState({
      toMaxDate: max
    })
  }

  componentDidMount() {
    this.getMinMax();
  }

  render() {
    return <>
      <div style={{ width: '100%' }}>

        <Grid className='filter-fields'>
          <Grid style={{ paddingRight: "20px" }}>
            <TextField id="standard-basic"
              variant="standard"
              name='user_name'
              value={this.props.audit_filter.user_name}
              label="User name"
              onChange={(event) => this.props.handleChange(event)} />
          </Grid>
          <Grid style={{ paddingRight: "20px" }}>
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                Action
              </InputLabel>
              <Select
                native
                value={this.props.audit_filter.action}
                variant="standard"
                label="Action"
                onChange={(event) => this.props.handleChange(event)}
                inputProps={{
                  name: "action",
                  id: "outlined-age-native-simple",
                }}
              >
                <option value=""></option>
                {Object.keys(c.UI_ACTION).map((action) => (
                  <option key={action} value={action}>
                    {action.replace(/(^|_)./g, str => " " + str.slice(-1).toUpperCase())}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid style={{ paddingRight: "20px" }}>
            <CustomDatePicker
              maxDate={dayjs().format("MM/DD/YYYY")}
              onChange={val => {
                this.setState({ dateError: false });
                this.props.handleChange('fromDate', val)
              }}
              onError={error => {
                if (error) this.setState({ dateError: true });
                else this.setState({ dateError: false });
              }}
              value={this.props.audit_filter.fromDate}
              label="From"
              format={'MM/DD/YYYY'}
            />
          </Grid>
          <Grid style={{ paddingRight: "20px" }}>
            <CustomDatePicker
              minDate={dayjs(this.props.audit_filter.fromDate).format("MM/DD/YYYY")}
              maxDate={this.state.toMaxDate}
              onChange={val => {
                this.setState({ dateError: false });
                this.props.handleChange('toDate', val);
              }}
              onError={error => {
                if (error) this.setState({ dateError: true });
                else this.setState({ dateError: false });
              }}
              value={this.props.audit_filter.toDate}
              label="To"
              format={'MM/DD/YYYY'}
            />
          </Grid>
          <div className="buttons-filter">
            <button
              className="apply-button"
              type="submit"
              onClick={() => this.props.handleSubmit()}
            >
              <p className="apply-button-text">Apply</p>
            </button>
            <button
              className="reset-button"
              style={{ width: '120px' }}
              type="submit"
              onClick={() => {
                this.props.cancelFilter();
              }}
            >
              <p className="reset-button-text"  >Reset all filters</p>
            </button>
          </div>
        </Grid>
      </div>

    </>;
  }
}

export default Filter;
