import React, {useState} from "react";
import "./DocStuckTable.css";

const RenderHeader = (props) => {
    return (
        <tr className="doc-stuck-table-header">
            {props.rowSelection ? <th>
                <input 
                    type="checkbox" 
                    id="selectAll" 
                    name="selectAll" 
                    className="row-selection-checkbox select-all" 
                    checked={props.selectedRows?.length == props.data?.length} 
                    onChange={e => props.handleRowSelection(e)}  />
                </th> : null}
            {(props.columns || []).map((m, i) => <th key={i}>{m.label}</th>)}
        </tr>
    )
}

const RenderBody = (props) => {
    return (
        (props.data || []).map((obj, index) => (
            <tr key={index} className="doc-stuck-table-header">
                {props.rowSelection ? <th><input className="row-selection-checkbox" type="checkbox" id="selectAll" name={index} checked={props.selectedRows.includes(index)}  onChange={e => props.handleRowSelection(e, index)} /></th> : null}
                {(props.columns || []).map((m, i) => 
                    <td key={i}>{
                        Object.hasOwn(m, 'render') && typeof m.render == "function" ? m.render(obj, obj[m.column], index) : (
                            Object.hasOwn(m, 'onClick') && typeof m.onClick == "function" ? 
                                <span className={m.hasLink ? "doc-stuck-table-link" : ""} style={{cursor:  "pointer"}} onClick={e => m.onClick(obj, obj[m.column], index)}>{obj[m.column]}</span> : obj[m.column])
                            }</td>
                )}
            </tr>
        ))
    )
}

const DocStuckTable = (props) => {
    const [selected, updateSelected] = useState([]);

    const handleRowSelection = (e, index) => {
        let { checked, name } = e.target, tempArray = JSON.parse(JSON.stringify(selected));
        if(name == "selectAll") {
            tempArray = checked ? (props.data || []).map((m, i) => i) : [];
        } else {
            if(checked) tempArray.push(index)
            else tempArray = tempArray.filter(m => m != index);
        }
        let filteredData = (props.data || []).filter((m, i) => tempArray.includes(i));
        updateSelected(tempArray);
        typeof props.handleFunction == "function" && props.handleFunction("rowSelection", filteredData);
    }
    return (
        <div className="doc-stuck-table-container">
            <table className="doc-stuck-table">
                <RenderHeader {...props} selectedRows={selected} handleRowSelection={handleRowSelection} />
                <RenderBody {...props} selectedRows={selected} handleRowSelection={handleRowSelection} />
            </table>
            {!props.data?.length ? <div className="no-data-content">No Data!</div> : null}
        </div>
    )
}

export default DocStuckTable;