import React, { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { Edit, Check, Close } from "@mui/icons-material";
import FetchID from "../FetchID/FetchID";
import { ConvertToTitleCase } from "../../../../commonFunctions/commonFunctions";

/*This component contains category card (category, sub-category) and fetch EHR information link*/
const Classification = (props) => {
  const [showCheck, setShowCheck] = useState(false);
  const [classification, setClassification] = useState({ category: "", subCategory: "", docLevel: "" });
  /*handles edit action of category card*/
  const editData = (val) => {
    props.handleCategoryCardAction(true);
    setClassification(prevState => {
      return { ...prevState, category: ConvertToTitleCase(val.docClassification), subCategory: val.documentSubCategory }
    });
  };
  /*returns category and doc level based mapping data returned from API*/
  const mapCategory = (type, sub_category) => {
    var sub_category_val = sub_category ? sub_category : props.all_state.data.Entity_Details.classify
      .documentSubCategory;
    var res = "";
    if (sub_category_val && type === "category")
      res = props.all_state.category_map[sub_category_val].category.display_name;
    else if (sub_category_val && type === "doc_level")
      res = props.all_state.category_map[sub_category_val].doc_level.display_name;
    return res;
  };
  /* handles onChange of category card */
  const handleCategory = (event, val) => {
    setClassification(prevState => {
      return { ...prevState, category: mapCategory("category", val), subCategory: val, docLevel: mapCategory("doc_level", val) }
    });
    if (val)
      setShowCheck(true);
    else
      setShowCheck(false);
  };
  /*handles save/cancel of category card*/
  const categoryData = (action) => {
    setShowCheck(false);
    props.handleCategoryCardAction(false);
    if (action === "save")
      props.saveData("edit", "classify", "", classification);
  }

  const getSubCategoryOptions = (data = {}) => {
    let optionArray = Object.keys(data).map(m => {
      return {
        label: m,
        id: m,
        disable: !data[m]?.enabled
      }
    });

    optionArray = optionArray.sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    });

    let enabledOptions = optionArray.filter(m => !m.disable) || [];
    let disabledOptions = optionArray.filter(m => m.disable) || [];

    // needs to remove once other categories are came
    let chOptions = enabledOptions.filter(m => m.id == "AUC" || m.id == "Others");
    let otherOptions = enabledOptions.filter(m => m.id != "AUC" && m.id != "Others");

    return [...chOptions, ...otherOptions, ...disabledOptions]
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="flex-margin" style={{ margin: "0px" }}>
          <div className="each-section-category">
            <div
              className={
                props.all_state.data.Entity_Details &&
                  props.all_state.data.Entity_Details.classModify
                  ? "card-flag"
                  : "card-flag-hide"
              }
            >
              <div className="tri-flag" />
            </div>
            {props.all_state.category_card ? (
              <div>
                <div style={{ textAlign: "right" }}>
                  {showCheck === true && (
                    <Check
                      onClick={() => {
                        categoryData("save");
                      }}
                      className="icon-review-style"
                      fontSize="small"
                    />
                  )}

                  <Close
                    fontSize="small"
                    className="icon-review-style"
                    onClick={() => {
                      categoryData("close");
                    }}
                  />
                </div>
                <div className="category-display">
                  <div className="each-category">
                    <div className="icon-text">
                      <div className="label-font" style={{ marginTop: "0px", marginRight: "15px" }}>
                        Category
                      </div>
                    </div>
                    <div className="value-font">{classification.category}</div>
                  </div>
                  <div className="each-category">
                    <div className="label-font" style={{ marginTop: "0px" }}>
                      Sub-Category
                    </div>
                    <Autocomplete
                      options={getSubCategoryOptions(props.all_state?.category_map)}
                      name="subCategory"
                      value={classification.subCategory}
                      onChange={(event, newValue) =>
                        handleCategory(event, newValue?.label)
                      }
                      getOptionDisabled={(option) => option?.disable}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" label="" />
                      )}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="icon-review" style={{ textAlign: "right", visibility: !props.all_state.isDuplicateView ? "visible" : "hidden" }}>
                  <Edit
                    className={
                      props.all_state.card_edit || props.all_state.card_add
                        ? "icon-disable"
                        : "icon-review-style"
                    }
                    onClick={(e) =>
                      !props.all_state.isDuplicateView && (props.all_state.card_edit || props.all_state.card_add
                        ? e.preventDefault()
                        : editData(props.all_state.data.Entity_Details.classify))
                    }
                    fontSize="small"
                  />
                </div>
                <div className="category-display">
                  <div className="each-category">
                    <div className="icon-text">
                      <div className="label-font" style={{ marginTop: "0px", marginRight: "15px" }}>
                        Category
                      </div>
                    </div>
                    <div
                      className="value-font"
                      style={{ textTransform: "capitalize" }}
                    >
                      {Object.entries(props.all_state.data.Entity_Details.classify)
                        .length === 0
                        ? props.all_state.data.Category
                        : props.all_state.data.Entity_Details.classify &&
                        props.titleCase(
                          props.all_state.data.Entity_Details.classify
                            .docClassification
                        )}

                    </div>
                  </div>
                  <div className="each-category">
                    <div className="label-font" style={{ marginTop: "0px" }}>
                      Sub-Category
                    </div>
                    <div
                      className="value-font"
                    >
                      {
                        props.all_state.data.Entity_Details.classify
                          .documentSubCategory
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*fetch EHR popup link. visible only if MRN is present for a document*/}
      {props.all_state.data.MRN &&
        <FetchID
          doc_level={props.all_state.data.Entity_Details.classify.documentLevel}
          all_data={props.all_state.data}
          saveEHRDetails={props.saveEHRDetails}
          all_state={props.all_state}
          onDialogAction={props.onDialogAction}
          updateDocumentLevel={props.updateDocumentLevel}
        />}
    </>

  );
};

export default Classification;
