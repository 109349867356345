import React from "react";
import { connect } from "react-redux";
import "./UserProfile.css";
import {
  Grid,
  Divider,
  CircularProgress
} from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import { MailOutline } from "@mui/icons-material";
import { getUserProfileData } from "../../redux/userprofile/actions/actions";
import {getLocations_user_doc} from "../../redux/docmanagement/actions/actions";
import * as c from "../../utils/constants/constants";
import { forceLogout } from "../../commonFunctions/forceLogout";
import * as msg from "../../utils/constants/messages";

const initialState = {
  userprofiledata: {},
  locations: [],
  isLoading : true
};

export class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }
/*Make API call and display all details pertaining to a user*/
  componentDidMount = () => {
    document.title = c.PRODUCT_NAME + " - User Profile";
    localStorage.removeItem(c.DOC_STATUS);
    this.props.getUserProfileData(c.ROOT_URL + "/getUserProfile");
    this.props.getLocations_user_doc(c.ROOT_URL + "/getLocationsForUser/" +localStorage.getItem(c.USER_ID));
    if(this.props.userprofiledata.userprofiledata)
      this.setState({userprofiledata: this.props.userprofiledata.userprofiledata.data[0]});
    if(this.props.locations_user.locations_user)
      this.setState({locations : this.props.locations_user.locations_user});
    
  };
 componentDidUpdate = (prevProps) => {
  if(this.props.userprofiledata.hasError  === 401 ){
    forceLogout();
  } else if(this.props.userprofiledata.hasError)
      window.alert(msg.api_error);
  if(this.props.userprofiledata.userprofiledata && this.props.userprofiledata.userprofiledata !== prevProps.userprofiledata.userprofiledata)
      this.setState({userprofiledata: this.props.userprofiledata.userprofiledata.data[0],isLoading : false});
  if(this.props.locations_user.locations_user && this.props.locations_user.locations_user !== prevProps.locations_user.locations_user)
      this.setState({locations : this.props.locations_user.locations_user});
 }
  render() {
    return (
        <Grid id="userprofile" className="full-view full-height">
          <Navbar
            handlePageChange={this.handlePageChange}
            changeCurrentPage={(page) => this.setState({ currentPage: page })}
          />
           {this.state.isLoading ? 
          <CircularProgress  style={{ color: "grey", margin: "40vh auto" }} />
        :
        <>
          <Grid className="userprofilepage full-height" style = {{padding: "0px"}}>
            <Grid className="user-details full-height" container>
              <div className="user-details-left">
              <div style = {{padding: "15px 0px 0px 15px"}}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="titlebox">
                    <h1 className="page-title">User Profile</h1>
                  </div>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    className="basic-info"
                    container
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{display:"initial"}}
                  >
                    <div className="title">Basic Info</div>
                    <Divider className="userprofile-divider" />
                    <div className="basic-info-list">
                      <div className="each-user-item">
                        <p className="label-font">Name</p>
                        <p className="value-font">
                          {this.state.userprofiledata.name}
                        </p>
                      </div>
                      <div className="each-user-item">
                        <p className="label-font">Email ID</p>
                        <p className="value-font">
                          {this.state.userprofiledata.email}
                        </p>
                      </div>
                      <div className="each-user-item">
                        <p className="label-font">Phone</p>
                        <p className="value-font"> {this.state.userprofiledata && this.state.userprofiledata.phone}</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className="basic-info"
                    container
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{display:"initial"}}
                  >
                    <div className="title">System Info</div>
                    <Divider className="userprofile-divider" />
                    <div className="basic-info-list">
                      <div className="each-user-item">
                        <p className="label-font">Status</p>
                        <p className="value-font">
                          {this.state.userprofiledata.status
                            ? "Active"
                            : "Inactive"}
                        </p>
                      </div>
                      <div className="each-user-item">
                        <p className="label-font">Role</p>
                        <p className="value-font">
                          {this.state.userprofiledata.role}
                        </p>
                      </div>

                      <div>
                        <p className="label-font"> Locations </p>
                        {
                          <div className="locationsbox">
                            {this.state.locations.length > 0 &&
                              this.state.locations.map(
                                (loc, i) =>
                                  loc.is_auth === true &&
                                    <p key = {i} className="value-font">{loc.name}</p>
                                  
                              )}
                          </div>
                        }
                      </div>
                    </div>
                  </Grid>
                </Grid>
                </div>
              </div>
              <div className="user-details-right full-height">
                <Grid
                  container
                  className="admin-info full-height"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{display:"inline-block"}}
                >
                  <div className="title">Admin Info</div>
                  <div>
                    <MailOutline
                      style={{
                        color: "#e0e0e0",
                        width: "100px",
                        height: "80px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className="details">
                    <div>To update any information,</div>
                    <div> Contact the admin.</div>
                  </div>
                  {localStorage.getItem(c.SETTINGS) &&
                    <div className="adminemail">
                      <p className="adminemailinfo">{JSON.parse(localStorage.getItem(c.SETTINGS)).Support_Email}</p>
                    </div>
                  }
                </Grid>
              </div>
            </Grid>
          </Grid>
         </>}
        </Grid>
     
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userprofiledata: state.userprofiledata,
    locations_user: state.locations_user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfileData: (url) => dispatch(getUserProfileData(url)),
    getLocations_user_doc: (url) => dispatch(getLocations_user_doc(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
