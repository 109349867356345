import React from "react";
import "./Switcher.css";

const getClassName = (props, m) => {
    let classname = "radio-btn-item";
    if(props.selected == m.value) classname += " radio-btn-selected";
    if(props.disabled?.includes(m.value)) classname += " radio-btn-disabled";
    return classname;
}

const Switcher = (props) => (<div className="radio-btn-group">
  {(props.options || []).map((m, i) => 
    <div 
      key={i} 
      className={getClassName(props, m)} 
      onClick={e => typeof props.onChange == "function" && !props.disabled?.includes(m.value) && props.onChange(e, m.value, i)}>{m.label}
    </div>)}

</div>)

export default Switcher;