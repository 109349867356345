import React, { Component } from "react";
import {IconButton} from "@mui/material";
import * as c from "../../../utils/constants/constants";
import { 
        DoneAll,
        TaskAlt,
        CloudUpload,
        Computer,
        ErrorOutline,
        Visibility,
        Check,
        Autorenew,
        History
      } from '@mui/icons-material';
import "./DocsStatusView.css";

/* get the response from API call made in dashboard.js and display the data*/
class DocsStatusView extends Component {
  render() {
    return <>
     
      <div className="doc-status">
        <p className="title" style={{ paddingTop: "0.5em" }}>
          Document Status
        </p>
        {this.props.doc_status &&
          this.props.doc_status.map(
            (data, i) =>
              data.doc_type === "Documents Received" && (
                <div key={i}>(Received {data.doc_count.toLocaleString()})</div>
              )
          )}
        <div className="custom-divider-chart"></div>
        {/*=================================flow chart===================================*/}

        <div className="chart-top">
          <div className="flex-justify-unique">
            <div className="flow-chart-part1">
              <p className="mini-text">{c.status.queued}</p>
              <div className="flex-justify">
                <IconButton
                  style={{ backgroundColor: "#aa1572", zIndex: "1" }}
                  onClick={() =>
                    this.props.goToIntervention("mgmt", c.status.queued)
                  }
                  size="large">
                  <History className="status-icon-action" />
                </IconButton>
              </div>
              {this.props.doc_status &&
                this.props.doc_status.map(
                  (data, i) =>
                    data.doc_type === c.status.queued && (
                      <p
                        key={i}
                        className={
                          data.doc_count.toString().length > 6
                            ? "mini-number-style"
                            : "number-style"
                        }
                      >
                        {data.doc_count.toLocaleString()}
                      </p>
                    )
                )}
            </div>
            
          </div>
          <div className="flow-chart-part2">
          <div className="chart-bottom-status">
              <div className="bottom-status">
                <IconButton
                  style={{ backgroundColor: "#711865", zIndex: "1" }}
                  onClick={() =>
                    this.props.goToIntervention("mgmt", c.status.in_progress )
                  }
                  size="large">
                  <Autorenew className="status-icon-action" />
                </IconButton>

                {this.props.doc_status &&
                  this.props.doc_status.map(
                    (data, i) =>
                      data.doc_type === c.status.in_progress && (
                        <p
                          key={i}
                          className={
                            data.doc_count.toString().length > 6
                              ? "mini-number-style"
                              : "number-style"
                          }
                        >
                          {data.doc_count.toLocaleString()}
                        </p>
                      )
                  )}

                <p className="mini-text">{c.status.in_progress}</p>
              </div>
              <div className="bottom-status">
              <IconButton
                style={{ backgroundColor: "#27AE60", zIndex: "1" }}
                onClick={() =>
                  this.props.goToIntervention("mgmt", c.status.processed)
                }
                size="large">
                <Check className="status-icon-action" />
              </IconButton>

              {this.props.doc_status &&
                this.props.doc_status.map(
                  (data, i) =>
                    data.doc_type === c.status.processed && (
                      <p
                        key={i}
                        className={
                          data.doc_count.toString().length > 6
                            ? "mini-number-style"
                            : "number-style"
                        }
                      >
                        {data.doc_count.toLocaleString()}
                      </p>
                    )
                )}
              <p className="mini-text">{c.status.processed}</p>
            </div>
              
            </div>
            
          </div>
          <div className="flow-chart-part3">
            <div className="curve-divider"></div>
            <div className="chart-bottom-status">
              <div className="bottom-status">
                <IconButton
                  style={{ backgroundColor: "#F2994A", zIndex: "1" }}
                  onClick={() =>
                    this.props.goToIntervention(
                      "mgmt",
                      c.status.intervention_rqd
                    )
                  }
                  size="large">
                  <Visibility className="status-icon-action" />
                </IconButton>

                {this.props.doc_status &&
                  this.props.doc_status.map(
                    (data, i) =>
                      data.doc_type === c.status.intervention_rqd && (
                        <p
                          key={i}
                          className={
                            data.doc_count.toString().length > 6
                              ? "mini-number-style"
                              : "number-style"
                          }
                        >
                          {data.doc_count.toLocaleString()}
                        </p>
                      )
                  )}

                <p className="mini-text">{c.status.intervention_rqd}</p>
              </div>
              <div className="bottom-status">
                <IconButton
                  style={{ backgroundColor: "#EB5757", zIndex: "1" }}
                  onClick={() =>
                    this.props.goToIntervention("mgmt", "Failed")
                  }
                  size="large">
                  <ErrorOutline className="status-icon-action" />
                </IconButton>

                {this.props.doc_status &&
                  this.props.doc_status.map(
                    (data, i) =>
                      data.doc_type === "Failed" && (
                        <p
                          key={i}
                          className={
                            data.doc_count.toString().length > 6
                              ? "mini-number-style"
                              : "number-style"
                          }
                        >
                          {data.doc_count.toLocaleString()}
                        </p>
                      )
                  )}

                <p className="mini-text">Failed</p>
              </div>

            </div>
            <div className="background-circle-connector"></div>
            <div className="chart-bottom-status">
              <div className="bottom-status left-circle">
                <div className="bottom-status-background-circle left-circle">
                  <IconButton
                    style={{
                      backgroundColor: "#4F4F4F", zIndex: "1"
                    }}
                    onClick={() =>
                      this.props.goToIntervention("mgmt", c.status.uploading)
                    }
                    size="large">
                    <CloudUpload
                      className="status-icon-action"
                    />
                  </IconButton>
                </div>

                {this.props.doc_status &&
                  this.props.doc_status.map(
                    (data, i) =>
                      data.doc_type === c.status.uploading && (
                        <p
                          key={i}
                          className={
                            data.doc_count.toString().length > 6
                              ? "mini-number-style"
                              : "number-style"
                          }
                        >
                          {data.doc_count.toLocaleString()}
                        </p>
                      )
                  )}
                <p className="mini-text">{c.status.uploading}</p>
              </div>
              <div className="bottom-status">
                <div className="bottom-status-background-circle right-circle">
                  <IconButton
                    style={{
                      backgroundColor: "#9B51E0", zIndex: "1"
                    }}
                    onClick={() =>
                      this.props.goToIntervention("mgmt", c.status.uploaded)
                    }
                    size="large">
                    <Computer
                      className="status-icon-action"
                    />
                  </IconButton>
                </div>

                {this.props.doc_status &&
                  this.props.doc_status.map(
                    (data, i) =>
                      data.doc_type === c.status.uploaded && (
                        <p
                          key={i}
                          className={
                            data.doc_count.toString().length > 6
                              ? "mini-number-style"
                              : "number-style"
                          }
                        >
                          {data.doc_count.toLocaleString()}
                        </p>
                      )
                  )}
                <p className="mini-text">{c.status.uploaded}</p>
              </div>
            </div>
            <div className="background-circle-connector"></div>
            <div className="chart-bottom-status">
              <div className="bottom-status left-circle">
                <div className="bottom-status-background-circle left-circle">
                  <IconButton
                    style={{
                      backgroundColor: "#b4abb2", zIndex: "1"
                    }}
                    onClick={() =>
                      this.props.goToIntervention("mgmt", c.status.completing)
                    }
                    size="large">
                    <TaskAlt
                      className="status-icon-action"
                    />
                  </IconButton>
                </div>

                {this.props.doc_status &&
                  this.props.doc_status.map(
                    (data, i) =>
                      data.doc_type === c.status.completing && (
                        <p
                          key={i}
                          className={
                            data.doc_count.toString().length > 6
                              ? "mini-number-style"
                              : "number-style"
                          }
                        >
                          {data.doc_count.toLocaleString()}
                        </p>
                      )
                  )}
                <p className="mini-text">{c.status.completing}</p>
              </div>
              <div className="bottom-status ">
                <div className="bottom-status-background-circle right-circle">
                  <IconButton
                    style={{
                      backgroundColor: "#2D9CDB", zIndex: "1"
                    }}
                    onClick={() =>
                      this.props.goToIntervention("mgmt", c.status.completed)
                    }
                    size="large">
                    <DoneAll
                      className="status-icon-action"
                    />
                  </IconButton>
                </div>

                {this.props.doc_status &&
                  this.props.doc_status.map(
                    (data, i) =>
                      data.doc_type === c.status.completed && (
                        <p
                          key={i}
                          className={
                            data.doc_count.toString().length > 6
                              ? "mini-number-style"
                              : "number-style"
                          }
                        >
                          {data.doc_count.toLocaleString()}
                        </p>
                      )
                  )}
                <p className="mini-text">{c.status.completed}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>;
  }
}

export default DocsStatusView;
