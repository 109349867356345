import React, { Component } from "react";
import "./Filter.css";
import {
  Grid,
  TextField
} from "@mui/material";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import { GetCurrentDateAndTime } from "../../../commonFunctions/commonFunctions";


class Filter extends Component {
  render() {
    return (
      <>
        <div className = 'status-icon'>
          <Grid className="filter-fields">
            <Grid style={{marginLeft: "10px"}}>
              <CustomDatePicker
                maxDate={GetCurrentDateAndTime("MM/DD/YYYY")}
                onChange={(val, date) => {
                  this.setState({ dateError: false });
                  this.props.handleChange(date.startOf('month').format("MM/DD/YYYY"), date.endOf('month').format("MM/DD/YYYY"));
                }}
                onError={error => {
                  if (error) this.setState({ dateError: true });
                  else this.setState({ dateError: false });
                }}
                value={this.props.value}
                views={['year', 'month']}
                openTo="month"
                format={'MMM - YYYY'}
              />
            </Grid>
          </Grid>
          </div>
      </>
    );
  }
}

export default Filter;
