import React from "react";
import {
  Typography,
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import { ArrowUpward ,ArrowDownward } from "@mui/icons-material";
import "./TableHeader.css";

class TableHeader extends React.Component {
  
  render() {
    return (
      <TableHead className="thBorder">
        <TableRow>
          {this.props.tableHeadData.map((data, i) => (
            <TableCell key = {i}
              className="tableHeadStyle"
          
              align={data.name === 'Status' ? "center" : "left"}
              onClick={() => this.props.sort(data.name)}
            >
              <div className="sortSection">
                <Typography className="textTheme">{data.name}</Typography>
               
                <div
                  className={
                    data.icon === true && data.name !== 'Action' ? "sort-icons" : "sort-icons-hide"
                  }
                >
                  {data.order === true ? (
                    <ArrowUpward
                      fontSize="small"
                      style={{ color: "grey" }}
                    />
                  ) : (
                    <ArrowDownward
                      style={{ color: "grey" }}
                      fontSize="small"
                    />
                  )}
                  </div>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default TableHeader;
