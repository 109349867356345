import React, { useEffect, useState } from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import "./SubCategoryTable.css";
import Filter from '../Filter/Filter';
import api from '../../../utils/API/api';
import ExportSubCategory from './ExportSubCategory';
import { Launch } from '@mui/icons-material';
import { FormatDate, GetDateRange } from '../../../commonFunctions/commonFunctions';


const SubCategoryTable = (props) => {
  const [headers, updateHeaders] = useState(["Sub Category", "Total Files", "Changed", "Unchanged", "Percentage"]);
  const [tableData, updateTableData] = useState([]);
  const [loading, updateLoading] = useState(false);
  const [filters, updateFilters] = useState({});
  useEffect(() => {
    let defaultDates = GetDateRange({type: "month", format: "MM/DD/YYYY"});
    updateFilters(defaultDates);
    getSubCategory(defaultDates);
  }, []);
  const getSubCategory = (filter) => {
    let fromDate = FormatDate({date: filter.fromDate, format: "MM-DD-YYYY"});
    let toDate = FormatDate({date: filter.toDate, format: "MM-DD-YYYY"});
    updateLoading(true)
    api.getSubCategory({from_date: fromDate, to_date: toDate})
    .then(res => {
      if(res.data) {
        const {data = {}} = res.data || {};
        updateTableData(data);
        updateLoading(false)
      }
    })
  }
  const handleFilterChange = (fromDate, toDate) => {
    updateFilters({
      fromDate,
      toDate
    });
    getSubCategory({fromDate, toDate});
  }
  const cancelFilter = () => {
    let defaultDates = GetDateRange({type: "month", format: "MM/DD/YYYY"});
    updateFilters(defaultDates);
    getSubCategory(defaultDates);
  }
  return (
    <div style={{padding: "16px"}}>
      <div className='page-title'>Sub Category Change</div>
      <div style={{display:'flex',justifyContent:'space-between'}}>
      <div className='superadmin-page-filter'>
        <Filter 
          handleChange={handleFilterChange}
          cancelFilter={cancelFilter}
          value={filters.fromDate}
        />
      </div>
      <div style={{display:'flex', justifyContent:'row'}}>
      <div className="rp-print-section">
                  <Launch style={{ marginRight: "5px" }} /></div>
      <div className="rp-print-section">
        {headers && tableData && 
        <ExportSubCategory
        headers={headers}
        tableData={tableData}        
        fromDate={FormatDate({date: filters.fromDate, format: "MM_DD_YYYY"})}
        toDate={FormatDate({date: filters.toDate, format: "MM_DD_YYYY"})}
        />
        }
      </div></div>
      </div>
      {loading ? <CircularProgress style={{ color: "grey", margin: "40vh auto" }} /> :  <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: "10px" }} className="subCategory-table">
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table stickyHeader>
            <TableHead sx={{ '& > *': { border: '1px solid rgba(224, 224, 224, 1)', display: "flex !important", width: "100%", background: "#cfcfcf" } }}>
              <TableRow sx={{width: "100%"}}>
                {headers.map((m, i) => {
                  return <TableCell key={i} className='table-header table-column' sx={{}}>{m}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody sx={{ '& > *': { border: 'unset', display: "flex !important", width: "100%" } }}>
              {tableData.map((row, i) => (
				  <TableRow key={i} sx={{ '& > *': { border: 'unset', display: "flex !important", width: "100%" } }}>
					  <TableCell className='table-column' sx={{}}>{row.sub_category}</TableCell>
					  <TableCell className='table-column' sx={{}}>{row.total_file}</TableCell>
					  <TableCell className='table-column' sx={{}}>{row.changed}</TableCell>
					  <TableCell className='table-column' sx={{}}>{row.unchanged}</TableCell>
					  <TableCell className='table-column' sx={{}}>{row.percentage}</TableCell>
				  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>}
    </div>
  )
}
export default SubCategoryTable;

