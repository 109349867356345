import React, { useState, useEffect } from "react";
import api from "../../../utils/API/api";
import * as msg from "../../../utils/constants/messages";
import * as c from "../../../utils/constants/constants";
import { ConvertUtcToLocal } from "../../../commonFunctions/commonFunctions"
import { forceLogout } from "../../../commonFunctions/forceLogout";
import {
  Button,
  TextField,
  Divider,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import "./DocStuck.css";
import * as dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import DocStuckTable from "./DocStuckTable/DocStuckTable";

dayjs.extend(utc);
dayjs.extend(duration);

const RenderStartEndTime = (props) => {
  return (<div className={!props.data[props.startTime] && !props.data[props.endTime] ? "sa-bg-highlight" : ""}>
    <div>Start: {props.data[props.startTime]}</div>
    {props.showEndTime != false ? <div>End: {props.data[props.endTime]}</div> : null}
  </div>)
}

const navigateDocDetails = (data, props) => {
  if(localStorage.getItem(c.PREV_ROUTE) == "superAdmin" && localStorage.getItem("admin_screen") == "unlocked")
    props.history.push({
      pathname: c.PAGE_DOC_INFO,
      search: '?'+data.ID
    });
  else props.history.push({
    pathname: c.PAGE_MANUAL_REVIEW,
    search: '?doc_id='+data.ID
  });
}

const DocStuck = (props) => {
  const TableColumns = [
    { column: "ID", label: "Doc Id" },
    { column: "Name", 
      label: "Doc Name", 
      hasLink: true, 
      onClick: (data, val) => navigateDocDetails(data, props)},
    { column: "Received_On", label: "Received on" },
    { column: "Updated_On", label: "Updated on" },
    {
      column: "Status", label: "Status", render: (data, val, i) => (
        <div>
          <div>{data.Status}</div>
          <div style={{ color: "grey" }}>{data.Stuck_Duration}</div>
        </div>
      )
    },
    {
      column: "classification_time", label: "Classification", disableForClient: true, render: (data, val, i) => <RenderStartEndTime data={data} startTime="Classfication_Start" endTime="Classification_End" />
    },
    {
      column: "extraction_time", label: "Extraction", disableForClient: true, render: (data, val, i) => <RenderStartEndTime data={data} startTime="Extraction_Start" endTime="Extraction_End" />
    },
    {
      column: "patient_match", label: "Patient Match", disableForClient: true, render: (data, val, i) => <RenderStartEndTime data={data} startTime="Patient_Match_Start" showEndTime={false}/>
    },
    {
      column: "in_progress_action", label: "Update", render: (data, val, i) => (
        <Button
          variant="outlined"
          className="mui-buttons-custom"
          onClick={() => {
            updateSelectedDocuments([data.ID]);
            updateDialog(true);
          }}
        >
          Update
        </Button>
      )
    },
    {
      column: "uploading_action", label: "Update", render: (data, val, i) => (
        <Button
          variant="outlined"
          className="mui-buttons-custom"
          onClick={() => {
            updateSelectedDocuments([data.ID]);
            updateDialog(true);
          }}
        >
          Mark as Complete
        </Button>
      )
    }
  ]
  const [config, updateConfig] = useState({
    "In Progress": {
      key: "In Progress",
      duration: 60,
      title: 'Documents stuck in progress for more than 60 minutes',
      dialogButtonText: "Change Status to Intervention Required",
      columnNames: ["ID", "Name", "Received_On", "classification_time", "extraction_time", "patient_match", "Status", "in_progress_action"],
      columns: []

    },
    // "Uploading to EHR": {
    //   key: "Uploading",
    //   duration: 15,
    //   dialogButtonText: "Change to Mark as Complete",
    //   columnNames: ["ID", "Name", "Updated_On", "Status", "uploading_action"],
    //   columns: TableColumns
    // }
  })
  const [duration, updateDuration] = useState(config["In Progress"]["duration"]);
  const [toggleSelected, updateToggleSelected] = useState("In Progress");
  const [docStuckData, updateStuckData] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [selectedDocuments, updateSelectedDocuments] = useState([]);
  const [comments, updateComments] = useState("");
  const [dialog, updateDialog] = useState(false);

  useEffect(() => {
    const IN_PROGRESS = "In Progress", UPLOADING_TO_EHR = "Uploading to EHR";
    if (localStorage.getItem(c.PREV_ROUTE) == "superAdmin") {
      if (localStorage.getItem("admin_screen") !== "unlocked") {
        props.handlePageChange(c.SUPER_ADMIN);
        props.history.push(c.PAGE_URLS[c.SUPER_ADMIN]);
      } else {
        updateConfig({
          ...config,
          [IN_PROGRESS]: {
            ...config[IN_PROGRESS],
            columns: filterTableColumns(IN_PROGRESS)
          },
          // [UPLOADING_TO_EHR]: {
          //   ...config[UPLOADING_TO_EHR],
          //   columns: filterTableColumns(UPLOADING_TO_EHR)
          // }
        });
      }
    } else {
      updateConfig({
        ...config,
        [IN_PROGRESS]: {
          ...config[UPLOADING_TO_EHR],
          columns: filterTableColumns(IN_PROGRESS).filter(m => !m.disableForClient)
        },
        // [UPLOADING_TO_EHR]: {
        //   ...config[UPLOADING_TO_EHR],
        //   columns: filterTableColumns(UPLOADING_TO_EHR)
        // }
      });
    }
  }, []);

  useEffect(() => {
    updateDuration(config[toggleSelected]["duration"]);
    updateLoading(true);
    fetchData();
  }, [toggleSelected]);

  const filterTableColumns = (key) => {
    return TableColumns.filter(m => config[key]["columnNames"].includes(m.column))
  }

  const updateInProgressStatus = (doc_id, user_comment) => {
    var query = {
      Status: c.status.intervention_rqd,
      Comment: user_comment
    };
    api
      .change_doc_status(doc_id, query)
      .then((response) => {
        if (response.status === 200) {
          fetchData();
          updateDialog("");
          updateSelectedDocuments([]);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          forceLogout();
        } else {
          window.alert(msg.api_error);
        }
      });
  }
  const updateUploadingStatus = (doc_id) => {
    var query = {
      doc_id,
      revision_id: docStuckData.reduce((acc, m) => {
        if (m.ID == doc_id) acc = m.Revision_ID;
        return acc;
      }, "")
    };
    api
      .skip_upload(query, c.UI_COMP.document, c.UI_ACTION.Document_marked_as_completed)
      .then((response) => {
        if (response.status === 200) {
          fetchData();
          updateDialog("");
          updateSelectedDocuments([]);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          forceLogout();
        } else {
          window.alert(msg.api_error);
        }
      });
  }

  const handleFunction = (col, val) => {
    if (col == "rowSelection") {
      let selected = val.map(m => m.ID);
      updateSelectedDocuments(selected);
    }
  }

  const fetchData = () => {
    let isnum = /^\d+$/.test(duration);
    if (!isnum) {
      window.alert("Invalid input");
      updateDuration('');
      return 0;
    }
    let query = {
      status: toggleSelected,
      in_progress_time: duration
    }
    updateLoading(true);
    api.getDocStuck(query)
      .then(res => {
        const { data = [], total_records = 0 } = res.data || {};
        data.forEach(m => {
          let stuckOn = m.Received_Date + ' ' + m.Received_Time + " UTC"
          if(m.Status == "Uploading to EHR") {
            stuckOn = m.Last_Updated_Date + ' ' + m.Last_Updated_Time + " UTC"
          }
          let stuck_date = dayjs.utc(stuckOn).local();
          let diff = dayjs().diff(stuck_date);
          const duration = dayjs.duration(diff);
          m.Stuck_Duration = `${duration.days()} ${duration.days() > 1 ? "days" : "day"} ${duration.hours()} hr ${duration.minutes()} min ${duration.seconds()} sec`;

          m.Received_On = ConvertUtcToLocal(m.Received_Date + ' ' + m.Received_Time + " UTC", "MM/DD/YYYY, hh:mm:ss A");
          m.Updated_On = ConvertUtcToLocal(m.Last_Updated_Date + ' ' + m.Last_Updated_Time + " UTC", "MM/DD/YYYY, hh:mm:ss A");
          m.Classification_Start = ConvertUtcToLocal(m.Classification_Start_Time + " UTC", "MM/DD/YYYY, hh:mm:ss A");
          m.Classification_End = ConvertUtcToLocal(m.Classification_Start_Time + " UTC", "MM/DD/YYYY, hh:mm:ss A");
          m.Extraction_Start = ConvertUtcToLocal(m.Extraction_Start_Time + " UTC", "MM/DD/YYYY, hh:mm:ss A");
          m.Extraction_End = ConvertUtcToLocal(m.Extraction_Start_Time + " UTC", "MM/DD/YYYY, hh:mm:ss A");
          m.Patient_Match_Start = ConvertUtcToLocal(m.Patient_Match_Start_Time + " UTC", "MM/DD/YYYY, hh:mm:ss A");
        });
        updateStuckData(data);
        updateLoading(false);
        updateComments("");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          forceLogout();
        } else {
          window.alert(msg.api_error);
        }
      });
  }

  const handleStatusChange = () => {
    if (toggleSelected == "In Progress") updateInProgressStatus(selectedDocuments.join(), comments);
    else if (toggleSelected == "Uploading to EHR") updateUploadingStatus(selectedDocuments.join())
  }

  const closeDialog = () => {
    updateDialog(false);
    updateComments("");
  }

  return (
    <>
      <div style={{ margin: "20px" }}>
        <div className="toggle-buttons-group">
          <ToggleButtonGroup
            color="primary"
            value={toggleSelected}
            exclusive
            onChange={(e, val) => updateToggleSelected(val)}
            aria-label="Platform"
          >
            {Object.keys(config).map((m, i) =>
              <ToggleButton key={i} className={`toggle-button ${toggleSelected == m ? "toggle-selected" : ""}`} value={m}>{m}</ToggleButton>)}
          </ToggleButtonGroup>
        </div>

        <p className="page-title" style={{ textAlign: "left" }}>{toggleSelected == "In Progress" ? `Documents stuck in progress for more than ${duration} minutes` : (toggleSelected == "Uploading to EHR" ? `Documents stuck in uploading to EHR for more than ${duration} minutes` : "")}</p>
        {
          localStorage.getItem(c.PREV_ROUTE) == "superAdmin" ? <>
            <br />
            <div className="doc-stuck-icon-text">

              <div className="doc-stuck-text-field">
                <div style={{ textAlign: "left" }}>Enter time in minutes</div>
                <TextField
                  variant="outlined"
                  margin="dense"
                  className="text-box"
                  sx={{
                    width: "120px",
                    marginRight: "20px",
                    ' > div': {
                      display: "flex",
                      justifyContent: "space-between"
                    },
                    'input': {
                      padding: "10px"
                    }
                  }}
                  name="dur"
                  value={duration}
                  onChange={e => updateDuration(e.target.value)}
                />
              </div>

              <Button style={{ marginBottom: "5px"}} variant="contained" className="mui-buttons-custom-filled" onClick={() => fetchData()}>
                Fetch
              </Button>
            </div>
          </> : null
        }
        <br />
        <Divider />
        <br />
        {loading ? (
          <CircularProgress style={{ color: "grey" }} />
        ) : (
          <>
            <div className="doc-stuck-table-headers">
              <div style={{ textAlign: "left" }}>Total no. of documents: {docStuckData && docStuckData.length}</div>
            </div>
            <br />
            <DocStuckTable
              rowSelection={false}
              columns={config[toggleSelected].columns}
              data={docStuckData}
              handleFunction={handleFunction} />
          </>
        )}
        <Dialog open={dialog} onClose={() => closeDialog()}>
          <DialogTitle className="sa-dia-title">
            <div>Doc Id: {selectedDocuments.join(", ")}</div>
          </DialogTitle>
          <Divider />
          <div className="sa-cmnt-dialog">
            {toggleSelected == "In Progress" ? <TextareaAutosize
              aria-label="empty textarea"
              placeholder="Provide Comments here..."
              minRows={5}
              style={{ width: 500 }}
              name="user_comment"
              value={comments}
              onChange={e => updateComments(e.target.value)}
            /> : null}
            <div className="sa-action-section">
              <Button
                className={(toggleSelected == "In Progress" && comments) || toggleSelected != "In Progress" ? "mui-buttons-custom" : "mui-buttons-custom-disabled"}
                onClick={handleStatusChange}
                disabled={(toggleSelected == "In Progress" && comments) || toggleSelected != "In Progress" ? false : true}
                variant="outlined">{config[toggleSelected].dialogButtonText}</Button>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default DocStuck;
