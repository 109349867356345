import * as c from "../utils/constants/constants";
import api from "../utils/API/api";
import { TokenExpiry } from "./commonFunctions";

const getRefreshToken = async() => {
    let resp = await api.refresh();
    localStorage.setItem(c.ACCESS_TOKEN, resp.data.data[0].auth_token);
    return localStorage.getItem(c.ACCESS_TOKEN);
        
    
}
export const getToken =  () => {
  if(!(localStorage.getItem(c.LOGOUT) === 'inactive' )){
    
  var header_data = null;
  if (localStorage.getItem(c.ACCESS_TOKEN) && TokenExpiry(localStorage.getItem(c.ACCESS_TOKEN)) === true) {
    header_data = localStorage.getItem(c.ACCESS_TOKEN);
  } else {
    if (localStorage.getItem(c.REFRESH_TOKEN)){
      if(TokenExpiry(localStorage.getItem(c.REFRESH_TOKEN)) === true) {
          header_data =   getRefreshToken();
      } else {
        localStorage.setItem(c.LOGOUT,'expired');
        localStorage.removeItem(c.ACCESS_TOKEN);
        localStorage.removeItem(c.REFRESH_TOKEN);
        if(c.SSO === 'true')
          window.location.pathname = '/ssoRedirect';
        else
          window.location.pathname = '/info';
      
    }}
  }
 
  return header_data;
}
};
