import React, { useEffect, useState } from 'react';
import {Paper, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Typography, CircularProgress } from '@mui/material';
import {KeyboardArrowDown, KeyboardArrowUp, Launch } from '@mui/icons-material';
import "./Accuracy.css";
import Filter from '../Filter/Filter';
import api from '../../../utils/API/api';
import ExportAccuracy from './ExportAccuracy';
import { FormatDate, GetDateRange } from '../../../commonFunctions/commonFunctions';

const accuracyObjKeys = {
  "final_or_current_status": "Final/Current Status",
  "auto_approved": "Auto Approved (Completed)",
  "manual_approved": "Manual Approved",
  "still_intervention_required": "Still Intervention Required (to be done)",
  "status_change": "Status Change",
  "changed_category": "Category changed - Manual change",
  "changed_dob": "DOB that changed - Manual change",
  "changed_name": "Names that changed - Manual change",
  "unchanged_category": "Category that did not change",
  "unchanged_dob": "DOB that did not change",
  "unchanged_name": "Names that did not change",
  "status_change_percentage": "Status Change in Percentage",
  "changed_category": "Category changed - Manual change ",
  "changed_dob": "DOB that changed ",
  "changed_name": "Names that changed ",
  "unchanged_category": "Category that did not change ",
  "unchanged_dob": "DOB that did not change ",
  "unchanged_name": "Names that did not change ",
  "total_document": "Total Documents",
  "total_doc": "Total Documents"
}
const getTableHeaders = (data) => {
  let { status_change = [] } = data || {};
  let uniqueDates = status_change.reduce((acc, m) => {
    Object.keys(m).forEach(n => {
      if (!acc.includes(n)) acc.push(n);
    });
    return acc;
  }, []);
  let headers = [];
  for (let i = 0; i < uniqueDates.length; i++) {
    headers.push(uniqueDates[i]);
  }
  return headers || []
}
const generateTableData = (data, headers) => {
  return Object.keys(data).map(m => {
    let obj = { category: m };
    let tempArray = data[m];
    let keys = [];
    if (tempArray?.length) {
      Object.keys(tempArray[0]).forEach(n => {
        keys = Object.keys(tempArray[0][n]);
      })
    }
    obj.children = keys.map(n => {
      let childRow = { category: n };
      headers.forEach((d, i) => {
        let l = tempArray.find(ob => ob.hasOwnProperty(d));
        childRow["column_" + i] = !l?.[d]?.[n] && l?.[d]?.[n] != 0 ? "Nill" : l[d][n];
      })
      return childRow;
    })
    return obj;
  })
}
const parseCategory = (str) => {
  if (accuracyObjKeys[str]) return accuracyObjKeys[str];
  let arrayStr = str && str.split("_");
  return arrayStr.map(m => {
    if (m.length > 3) {
      return m
    } else {
      return m.charAt(0).toUpperCase() + m.slice(1);
    }
  }).join(" ")
}

const AccuracyTable = (props) => {
  const [headers, updateHeaders] = useState([]);
  const [tableData, updateTableData] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [filters, updateFilters] = useState({});
  useEffect(() => {
    let defaultDates = GetDateRange({type: 'month', format: 'MM/DD/YYYY'});
    updateFilters(defaultDates)
    getAccuracyTracker(defaultDates);
  }, []);
  const getAccuracyTracker = (filter) => {
    let fromDate = FormatDate({date: filter.fromDate, format: "MM-DD-YYYY"});
    let toDate = FormatDate({date: filter.toDate, format: "MM-DD-YYYY"});
    updateLoading(true)
    api.getAccuracyTracker({from_date: fromDate, to_date: toDate})
    .then(res => {
      if(res.data) {
        const {data = {}} = res.data || {};
        let headerArray = getTableHeaders(data);
        updateHeaders(headerArray);
        updateTableData(generateTableData(data, headerArray));
        updateLoading(false)
      }
    })
  }
  const handleFilterChange = (fromDate, toDate) => {
    updateFilters({
      fromDate,
      toDate
    });
    getAccuracyTracker({fromDate, toDate});
  }
  const cancelFilter = () => {
    let defaultDates = GetDateRange({type: 'month', format: 'MM/DD/YYYY'});                                           
    updateFilters(defaultDates);
    getAccuracyTracker(defaultDates);
  }
  const handleSubmit = () => {
    if(filters.fromDate && filters.toDate)
      getAccuracyTracker({fromDate: FormatDate({date: filters.fromDate, format: "MM-DD-YYYY"}), toDate: FormatDate({date: filters.toDate, format: "MM-DD-YYYY"})});
  }
  return (
    <div style={{padding: "16px"}}>
      <div className='page-title'> Tracking the performance of text extraction and classification models</div>
      <div style={{display:'flex',justifyContent:'space-between'}}>
      <div className='superadmin-page-filter'>
        <Filter 
          handleChange={handleFilterChange}
          cancelFilter={cancelFilter}
          value={filters.fromDate}
        />
      </div>
      <div style={{display:'flex', justifyContent:'row'}}>
      <div className="rp-print-section">
                  <Launch style={{ marginRight: "5px" }} /></div>
      <div className="rp-print-section">
        {headers && tableData &&
        <ExportAccuracy
        accuracyObjKeys={accuracyObjKeys}
        headers={headers}
        tableData={tableData}
        fromDate={FormatDate({date: filters.fromDate, format: "MM_DD_YYYY"})}
        toDate={FormatDate({date: filters.toDate, format: "MM_DD_YYYY"})}
        />
        }
      </div></div>
      </div>
     
      {loading ? <CircularProgress style={{ color: "grey", margin: "40vh auto" }} /> :  <Paper sx={{ width: '100%', overflow: 'hidden' }} className="accuracy-table">
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table stickyHeader>
            <TableHead sx={{ '& > *': { border: 'unset', display: "flex !important", width: "100%", background: "#cfcfcf" } }}>
              <TableRow >
                <TableCell className='table-header table-column table-first-column' sx={{
                }}>Category</TableCell>
                {headers.map((m, i) => {
                  return <TableCell key={i} className='table-header table-column' sx={{}}>{FormatDate({date: m, format: "MMM DD, YYYY"})}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody sx={{ '& > *': { border: 'unset', display: "flex !important", width: "100%" } }}>
              {tableData.map((row, i) => (
                <Row key={i} row={row} headers={headers} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>}
    </div>
  )
}
const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(true);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { border: 'unset', display: "flex !important", width: "100%" } }}>
        <TableCell sx={{
          width: "340px",
          display: "flex",
          justifyContent: "left",
          borderBottom: "unset",
          alignItems: "center",
          position: "sticky",
          left: 0,
          zIndex: 1,
          borderRight: "1px solid #ccc",
          backgroundColor: "#fff",
        }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <Typography className="parent-header">{parseCategory(props.row?.category)}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className='child-table-cell' style={{ padding: "0px !important", width: "100%", borderTop: "unset" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table sx={{ '& > *': {} }}>
              <TableBody sx={{ '& > *': { border: 'unset', display: "flex !important", width: "100%" } }}>
                {row.children.map((childRow) => {
                  return <TableRow key={childRow.catgeory} sx={{ '& > *': { border: 'unset', display: "flex !important", width: "100%" } }}>
                    <TableCell className='table-column table-first-column' sx={{}}>{parseCategory(childRow.category)}</TableCell>
                    {(props.headers || []).map((header, i) => <TableCell key={i} className='table-column' sx={{}}>{childRow["column_" + i]}</TableCell>)}
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default AccuracyTable;