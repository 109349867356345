import React from "react";
import api from "../../../utils/API/api";
import {
  Button,
  CircularProgress,
  TextField,
  Divider,
} from "@mui/material";
import ReactJson from "react-json-view";
import "../SuperAdmin.css";
import TableFormat from "./TableFormat/TableFormat";
import * as msg from "../../../utils/constants/messages";
import * as c from "../../../utils/constants/constants";
import { forceLogout } from "../../../commonFunctions/forceLogout";

/*All information pertaining to a document is listed here with the response got from the endpoint*/
class DocInfo extends React.Component {
  state = {
    data: "",
    isFetch: false,
    isLoading: false,
    doc_id: "",
    err_msg: "",
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
 componentDidMount = () => {
  if(localStorage.getItem("admin_screen") !== "unlocked"){
    var _props = this.props;
   _props.handlePageChange(c.SUPER_ADMIN);
   _props.history.push(c.PAGE_URLS[c.SUPER_ADMIN]);
 }else if(this.props.location.search){
     let doc_id = this.props.location.search.split('?')[1];
     this.fetchData(doc_id);
     this.setState({doc_id : doc_id});
   }
 }
  fetchData = (doc_id) => {
    if (doc_id) {
      this.setState({ isFetch: true, isLoading: true });
      api
        .all_doc_details(doc_id)
        .then((response) => {
          const data = response.data.data;
          if (response.status === 200) {
            this.setState({ data: data, isLoading: false });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            forceLogout();
          } else {
            this.setState({ isLoading: false });
            window.alert(msg.api_error);
          }
          // this.setState({err_msg : "Error occured!",isLoading : false});
        });
    } else {
      this.setState({ data: "", isFetch: false });
    }
  };
  render() {
    return (
      <>
        <div style={{ margin: "20px" }}>
          <p className="page-title" style={{ textAlign: "left" }}>
            Document Details
          </p>
          <br />
          <div style = {{textAlign : "left"}}>Enter doc ID</div>
          <div className="icon-text">
            <TextField
              variant="outlined"
              margin="dense"
              className="text-box"
              //label="Enter doc id"
              style={{ marginRight: "20px" }}
              name="doc_id"
              value={this.state.doc_id}
              onChange={(e) => this.handleChange(e)}
            />

            <Button
              className = "mui-buttons-custom-filled"
              variant="contained"
              onClick={() => this.fetchData(this.state.doc_id)}
            >
              Fetch
            </Button>
            {this.state.err_msg && (
              <div style={{ marginLeft: "10px", color: "red" }}>
                {this.state.err_msg}
              </div>
            )}
          </div>
          <br />
          <Divider />
          <div style={{ marginTop: "20px" }}>
            {this.state.isLoading ? (
              <CircularProgress style={{ color: "grey" }} />
            ) : (
              this.state.isFetch && (
                <>
                  {this.state.data[0] && this.state.data[0].ID && (
                    <div
                      className="title-sa-main"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Doc ID: {this.state.data[0].ID}, No. of Revisions:{" "}
                      {this.state.data.length}
                    </div>
                  )}
                  <br />
                  <div style={{ display: "flex", flexFlow: "wrap" }}>
                    {this.state.data &&
                      this.state.data.map((data, i) => (
                        <>
                          <div key={i} style={{ width: "35%" }}>
                            <ReactJson
                              name="data"
                              collapsed={true}
                              displayObjectSize={false}
                              displayDataTypes={false}
                              style={{ textAlign: "left" }}
                              src={data}
                            />
                          </div>
                        </>
                      ))}
                  </div>
                  <br />
                  {this.state.data &&
                    this.state.data.map((data, i) => (
                      <TableFormat key={i} data={data} id={i} />
                    ))}
                </>
              )
            )}
          </div>
        </div>
      </>
    );
  }
}

export default DocInfo;
