import React from 'react';
import {Divider} from '@mui/material';
import './AdminConfig.css';
import * as c from "../../utils/constants/constants";

class AdminConfig extends React.Component {
    render(){
        var data = JSON.parse(localStorage.getItem(c.SETTINGS));
        return(
            <>
            <div style={{ padding: "15px" }}>
            <p className="title"style = {{textAlign : 'left'}}>GENERAL SETTINGS</p>
          </div>
            <Divider variant = 'middle' />
            <div className = 'setting-view'>
              
                    <div>
                        <p>Authentication type: {data.Authentication_Type}</p>
                        <p>Environment: {data.Environment_Display_Name}</p>
                        <p>Dashboard refresh duration(sec): {data.Page_Refresh_In_Sec}</p>
                        <p>Session timeout duration(min): {data.Session_Time_Out_In_Min}</p>
                        <p>Support email: {data.Support_Email}</p>
                       
                    </div>
                <br />
               <Divider />
                <div>
                    <p style = {{fontWeight : '300'}}>To install DocuRoute File Watcher for Linux, please download the below file.</p>
                    <a  style = {{color : 'var(--hti-title)'}} href = 'https://storage.googleapis.com/hti-exec-files/docuroute_filewatcher_linux_ver202001.zip?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=restapi%40docuroute.iam.gserviceaccount.com%2F20210108%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20210108T104714Z&X-Goog-Expires=86400&X-Goog-SignedHeaders=host&X-Goog-Signature=37bc91a8cc6e80484783e6e15ef09af5228017cfe6a616e6107b94a049eb8dd89988901a07fe66b1110a18f2cedb3dbf8d250cf96c101483d1fc68abd4006e749168ad2c89bc813ee0a265e73d1683f90b3a45ca01c77d59607e4d91597586e779ee6a4db3e435386822f21b31f58698f720c5659d9178bb113ccd8616a9ef42df656312f1942f800c87800e0c2463048e624677c45550df43c37bd1bc21dafee1f245289b60ea7c4533f0dad311a888fd7231a7ef5a51cdd83af9c6973ef6f94a5798b32acdba3d357849cebaa4382460fc9c19db9e0e8dcd3a7f316c8cf389dd5433e46d7a78d9e9bc503488e0c24e97a7ecc0080f54024b7faf9fc96f7350'>docuroute_filewatcher_linux_ver202001.zip</a>
                </div>
                <br />
                <div>
                    <p style = {{fontWeight : '300'}}>To install DocuRoute File Watcher for Windows, please download the below file.</p>
                    <a  style = {{color : 'var(--hti-title)'}} href = 'https://storage.googleapis.com/hti-exec-files/docuroute_filewatcher_windows_ver202001.zip?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=restapi%40docuroute.iam.gserviceaccount.com%2F20210108%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20210108T104830Z&X-Goog-Expires=86400&X-Goog-SignedHeaders=host&X-Goog-Signature=1f1ab7e523e075d567814d1cdcc4d6246dd80c63a24776c77abd0b0599a1eafcbc85db501f7e2c7917e0a1edf61a60334c0370295a2bda56f197e079dc41fde4b62f6ef297dcd856597cf31d9d2d4c83b70ac28d7a2b007f31112d0f0fcd111bc86e56bcba2a6c07d63c95fd3b28e6e65d1fbb807daaf86761ced140c4d34629983281303761815a8395dd1cafcc42a546c7733387519ce44439a0d972be134d2d7dad38609c1f165f30cf453d1440f4ea1a58fb5734914d6c7d79705a974df3d3de61943a2ee26240b7a9fbf566e56ad0ce3989a7fc34cde3709bbcf4989f35b6d9bc100388d8e899f6db6a28938f8297ada8797888086b78493f2ca68bf8b5'>docuroute_filewatcher_windows_ver202001.zip</a>
                </div>
                <br />
                <div>
                    <p style = {{fontWeight : '300'}}>Installation guide is available in below link.</p>
                    <a style = {{color : 'var(--hti-title)'}} href = "https://storage.googleapis.com/hti-exec-files/DocuRoute%20File%20Watcher%20Setup.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=restapi%40docuroute.iam.gserviceaccount.com%2F20210108%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20210108T104931Z&X-Goog-Expires=86400&X-Goog-SignedHeaders=host&X-Goog-Signature=79de84678ba98de12b3bb599e110a0bb917a7d547942f006d0b8bb9ea024d7775f0a620a82a77a40f7a2bc99c84cd42d577f3d3121aca15f76b0aab68a15a48c3ee11f2cfced974d7f799acdb386eacee729299196231aae30c044d8a50884bac541126cb4c88f8a96c7503a8129dc1354df531006b301b1c3a9b255419ad381448e7cd3794595795c7a0d58b60e1e1e92b1586732eb51bb05e3e3a94f7bc5f0cd6869ef66ba0a96462d8a2b12568b0d8eb9fca39d6c0bf8653508767a99e533417434a3820bd61ee5eae56c827618b1846d44f871e443ea5c98a790c96226446f2bc085214aa8db8fbd73dbfa991e728d707f46b93d97ed804eb189de4dc3f7">DocuRoute File Watcher Setup.pdf</a>
                </div>
            </div>
            </>
        );
    }
}

export default AdminConfig;