import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  CircularProgress,
  Checkbox,
  Grid
} from "@mui/material";
import "./Encounters.css";
import DialogModel from "../../../../../../components/DialogModel/DialogModel";
import * as c from "../../../../../../utils/constants/constants";
import { ConvertUtcToLocal, ConvertToTitleCase, GetCurrentDateAndTime, FormatDate, GetDefaultDate } from "../../../../../../commonFunctions/commonFunctions";
import CustomDatePicker from "../../../../../../components/CustomDatePicker/CustomDatePicker";

const Encounters = (props) => {
  const defaultDates = GetDefaultDate({ duration: 6, type: "month", format: "MM/DD/YYYY" });
  const [encFilter, setencFilter] = useState({ fromDate: '', toDate: "" });
  const [selectedEnc, setselectedEnc] = useState([]); //stores selected encounters
  const [dateError, setDateError] = useState(false)

  useEffect(() => {
    /*save data on load if encounters is alreadty present for a document*/
    // setselectedEnc(props.all_data.Entity_Details.ehrDetails ? props.all_data.Entity_Details.ehrDetails.encounter : {});
    var selected_enc = [];
    props.all_data.Entity_Details.ehrDetails && Object.values(props.all_data.Entity_Details.ehrDetails.encounter).forEach((enc) => {
      selected_enc.push(enc);
    })
    setselectedEnc(selected_enc);
    if (JSON.parse(localStorage.getItem(c.ENC_DATA))) {
      setencFilter(JSON.parse(localStorage.getItem(c.ENC_DATA)));
    } else {
      setencFilter(defaultDates);
      props.patientEncounters(defaultDates);
    }
  }, []);

  const handleDate = (date, value) => {
    if (date === "from") {
      setencFilter(prevState => {
        return { ...prevState, fromDate: value }
      });
    }
    else
      setencFilter(prevState => {
        return { ...prevState, toDate: value }
      });
  };
  const resetFilter = () => {
    setencFilter(prevState => {
      return { ...prevState, fromDate: "", toDate: "" }
    });
    props.patientEncounters({
      fromDate: "",
      toDate: ""
    });
  };
  const handleSubmit = () => {
    props.patientEncounters(encFilter);
  };
  const setEncID = (event, data) => {
    const new_selected_enc = [];
    if (event.target.checked) {
      /*Taking only the keys that are required to be displayed in main screen*/
      var new_obj = {
        "identifier": data.identifier,
        "period": data.period,
        "type": data.type
      }
      new_selected_enc.push(new_obj);
    } else
      new_selected_enc.splice(new_selected_enc.indexOf(data), 1);
    setselectedEnc(new_selected_enc);
  };

  return (
    <>
      {props.tabLoader && props.value === 1 && (
        <CircularProgress size={30} className="popup-spinner" />
      )}

      <div className={props.tabLoader ? "block-area-common" : ""}>
        <div className="flex-style">
          <div>Encounters for {props.all_data.Entity_Details.patientMatch.official_name}.</div>
          {props.doc_level === "Encounter" && selectedEnc[0] && <div style={{ color: "#aa1572" }}> Account Number: {selectedEnc[0].identifier}</div>}
        </div>
        {/*======Encounter filters=============*/}
        <div className="enc-filters">
          <div>Encounter Start Date: </div>
          <div className="enc-filter-fields">
            <Grid style={{ paddingRight: "10px", marginLeft: "10px" }}>
              <CustomDatePicker
                maxDate={GetCurrentDateAndTime("MM/DD/YYYY")}
                onChange={val => {
                  setDateError(false);
                  handleDate("from", val)
                }}
                onError={error => {
                  if (error) setDateError(true);
                  else setDateError(false);
                }}
                label="From"
                format={'MM/DD/YYYY'}
                variant="standard"
                id="fromDate"
                type="date"
                value={FormatDate({ date: encFilter.fromDate, format: "MM/DD/YYYY" })}
                name="fromDate"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid style={{ paddingRight: "10px" }}>
              <CustomDatePicker
                minDate={FormatDate({ date: encFilter.fromDate, format: "MM/DD/YYYY" })}
                maxDate={GetCurrentDateAndTime("MM/DD/YYYY")}
                onChange={val => {
                  setDateError(false);
                  handleDate('to', val);
                }}
                onError={error => {
                  if (error) setDateError(true);
                  else setDateError(false);
                }}
                value={FormatDate({ date: encFilter.toDate, format: "MM/DD/YYYY" })}
                label="To"
                format={'MM/DD/YYYY'}
              />
            </Grid>
          </div>
          <div className="buttons-filter">
            <button className="apply-button" type="submit" onClick={handleSubmit}>
              <p className="apply-button-text">Apply</p>
            </button>
            <button
              className="reset-button"
              type="submit"
              onClick={resetFilter}
            >
              <p className="reset-button-text">Reset</p>
            </button>
          </div>
        </div>
        <br />
        {/*=========Encounter grid=============*/}
        <div className="enc-data-section">

          <TableContainer component={Paper} className="enc-table">
            <Table stickyHeader aria-label="customized table">
              <TableHead className="thBorder">
                <TableRow>
                  <TableCell className="tableHeadStyle">Select</TableCell>
                  <TableCell className="tableHeadStyle">No.</TableCell>
                  <TableCell className="tableHeadStyle">Account Number</TableCell>
                  <TableCell className="tableHeadStyle">Date of Service</TableCell>
                  <TableCell className="tableHeadStyle">Date of Discharge</TableCell>
                  <TableCell className="tableHeadStyle">Status</TableCell>
                  <TableCell className="tableHeadStyle">Account Type</TableCell>
                  <TableCell className="tableHeadStyle">Location</TableCell>
                  <TableCell className="tableHeadStyle">Provider</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.encData &&
                  props.encData.map((data, i) => (
                    <TableRow
                      key={i}
                      className={props.doc_level === "Encounter" && selectedEnc[0] && selectedEnc[0].identifier === data.identifier ? "ehr-table-row-color" : "table-row"}>
                      <TableCell className="tableCellStyle" align="left">
                        <Checkbox
                          size="small"
                          checked={props.doc_level === "Encounter" && selectedEnc[0] && selectedEnc[0].identifier === data.identifier ? true : false}
                          onChange={(event) => setEncID(event, data)}
                          disabled={props.doc_level !== "Encounter"}
                        />
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {i + 1}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.identifier}
                      </TableCell>
                      <TableCell className="tableCellStyle" style={{ whiteSpace: "nowrap" }} align="left">
                        {data.period["start"] && ConvertUtcToLocal(data.period["start"],  "MM/DD/YYYY")}
                      </TableCell>
                      <TableCell className="tableCellStyle" style={{ whiteSpace: "nowrap" }} align="left">
                        {data.period["end"] && ConvertUtcToLocal(data.period["end"], "MM/DD/YYYY")}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {ConvertToTitleCase(data.status, "-")}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.type.toString()}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.location}
                      </TableCell>
                      <TableCell className="tableCellStyle" align="left">
                        {data.participant[0]?.individual &&
                          data.participant[0]?.individual?.display}
                      </TableCell>

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {props.encData && props.encData.length === 0 && <DialogModel />}

          </TableContainer>

        </div>

        {props.value === 1 && props.doc_level === "Encounter" && (
          <div className="div-center">
            <button
              className="apply-button"
              type="submit"
              onClick={() => props.saveEHRData(selectedEnc, "encounter")}
            >
              <p className="apply-button-text">Done</p>
            </button>
            <button
              className="reset-button"
              type="submit"
              onClick={() => props.fetchID("cancel")}
            >
              <p className="reset-button-text">Cancel</p>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Encounters;