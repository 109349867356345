import * as url from '../../params.json';

export const SSO = 'false';
export const SSO_ID = 'sso_id';
export const ROOT_URL = url.ROOT_URL;
export const PRODUCT_NAME = 'readabl.ai';
export const ACCESS_TOKEN = "access";
export const REFRESH_TOKEN = "refresh";
export const DOC_ID = "doc_id";
export const DOC_STATUS = "status";
export const USER_ROLE = "user_role";
export const USER_ID = "user_id";
export const USER_NAME = "user_name";
export const LOGOUT = 'logout';
export const SSO_APP = url.SSO_APP;
export const FILTER = 'filter';
export const FROM_DETAILS = 'from_details';
export const SETTINGS = "settings";
export const SECRET_KEY = "rD_fL_dt1s";
export const RP_MONTH = "rp_month";
export const PREV_ROUTE = "prev_route";
export const ENC_DATA = "enc_data";
export const ORDER_DATA = "order_data";
/*====================================================*/
export const PAGE_LOGIN = "Login";
export const SSO_LANDING = "SSO_Landing";
export const SSO_REDIRECT = "SSO_Redirect";
export const PAGE_DASHBOARD = "Overview";
export const PAGE_DOC_MGMT = "DocManagement";
export const PAGE_USER_MGMT = "UserManagement";
export const PAGE_MANUAL_REVIEW = "ManualReview";
export const PAGE_AUDIT_LOG = "AuditLog";
export const ADMIN_CONFIG = "AdminConfig";
export const PDF_VIEW = "PdfView";
export const PAGE_INFO = "info";
export const LANDING_PAGE = PAGE_DASHBOARD;
export const PAGE_USER_PROFILE = "UserProfile";
export const ALL_URLS = "Urls";
export const SUPER_ADMIN = "SuperAdmin";
export const PAGE_DOC_INFO = "DocInfo";
export const PAGE_DOC_STUCK = "DocStuck";
export const PAGE_DOC_STUCK_MH = "DocStuckMH";
export const PAGE_ACCURACY_TRACKER = "AccuracyTracker";
export const PAGE_SUBCATEGORY = "SubCategory";
export const PAGE_REPORTS = "Reports";
export const PAGE_URLS = {};
PAGE_URLS[PAGE_LOGIN] = "/";
PAGE_URLS[SSO_LANDING] = "/ssoLanding";
PAGE_URLS[SSO_REDIRECT] = "/ssoRedirect";
PAGE_URLS[PAGE_DASHBOARD] = "/overview";
PAGE_URLS[PAGE_DOC_MGMT] = "/docManagement";
PAGE_URLS[PAGE_USER_MGMT] = "/userManagement";
PAGE_URLS[PAGE_MANUAL_REVIEW] = "/manualReview";
PAGE_URLS[PAGE_AUDIT_LOG] = "/auditLog";
PAGE_URLS[ADMIN_CONFIG] = "/adminConfig";
PAGE_URLS[PDF_VIEW] = "/pdfView";
PAGE_URLS[PAGE_USER_PROFILE] = "/userprofile";
PAGE_URLS[PAGE_INFO] = "/info";
PAGE_URLS[ALL_URLS] = "/urls";
PAGE_URLS[SUPER_ADMIN] = "/superAdmin";
PAGE_URLS[PAGE_DOC_INFO] = "/docInfo";
PAGE_URLS[PAGE_DOC_STUCK] = "/docStuck";
PAGE_URLS[PAGE_DOC_STUCK_MH] = "/docStuckMH";
PAGE_URLS[PAGE_ACCURACY_TRACKER]= "/accuracytracker";
PAGE_URLS[PAGE_SUBCATEGORY]="/subcategory";
PAGE_URLS[PAGE_REPORTS] = "/reports";

/*===================================================================*/

export const status = {
  queued: "Queued",
  in_progress: "In Progress",
  intervention_rqd: "Intervention Required",
  failed: "Failed",
  processed: "Processed",
  uploading: "Uploading",
  uploaded: "Uploaded",
  completing: "Marked for Completion",
  completed: "Completed"
}

export const UI_SCREEN = {
  login: 0,
  dashboard: 1,
  doc_mgmt: 2,
  review: 3,
  audit: 4,
  user_mgmt: 5,
};
export const UI_COMP = {
  document: 1,
  user: 2,
};
export const UI_ACTION = {
  Document_uploaded: 1,
  Document_viewed: 2,
  Document_updated: 3,
  Document_previewed: 4,
  User_added: 5,
  User_updated: 6,
  User_deleted: 7,
  Document_uploaded_to_meditech: 8,
  Document_completed: 9,
  Document_marked_as_completed: 10
};
export const IMAGE = {
  
}

export const MONTH_ARR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

