import React, { Component, useEffect, useState, useRef, useMemo } from "react";
import "./Filter.css";
import {
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox, Fade, Paper, Popper
} from "@mui/material";
import FlagList from "../../../components/FlagList/FlagList";
import * as dayjs from "dayjs";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";

const FailedList = (props) => {
  const popperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [failedList, updateFailedList] = useState([
    {name: "ingestion_fail", label: "Processing Failed", isChecked: false}, 
    {name: "ehr_fail", label: "Upload Failed", isChecked: false}
  ]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
      loadDefault();
  }, [JSON.stringify(props.status_arr)]);

  const handleOutsideClick = (event) => {
    if (popperRef.current && !popperRef.current.contains(event.target) && event.target?.id != "Failed-status") {
      setOpen(false)
    }
  };

  const loadDefault = () => {
    if(props.status_arr) {
      let isFailedChecked = props.status_arr.reduce((acc, m) => {
        if(m.name == "Failed" && m.isChecked) acc = true;
        return acc;
      }, false);

      let tempArray = JSON.parse(JSON.stringify(failedList));
      if(isFailedChecked) {
        let isAnyChecked = tempArray.some(element => element.isChecked );
        if(!isAnyChecked) {
          tempArray.forEach(element => {
            element.isChecked = true;
          });
        }
        updateFailedList(tempArray) 
      } else {
        tempArray.forEach(element => {
          element.isChecked = false;
        });
      }
      updateFailedList(tempArray)
    }
  }

  const handleCheckBoxClick = (e) => {
    let index = 0;
    const {checked = false, id} = e.target; 

    props.status_arr.forEach((m, i) => {
      if (m.name == "Failed") index = i
    });
    
    let tempArray = JSON.parse(JSON.stringify(failedList));
    tempArray.forEach(m => {
      if(m.name == id) m.isChecked = checked
    });
    updateFailedList(tempArray)
    props.handleCheckBoxes(e, index, 'status', tempArray.filter(m => m.isChecked))
  }
  
  return (<Popper style={{zIndex: 99}} ref={popperRef} open={open} anchorEl={props.anchorEl} placement={'bottom-start'} transition>
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350} style={{padding: "10px 0px"}}>
        <Paper>
          {
            failedList.map((m, i) => (<FormGroup key={i}>
              <FormControlLabel
                key={i}
                style = {{
                  paddingRight : "5px",
                  backgroundColor : m.isChecked ? "#ececec" : "",
                  marginLeft: "10px",
                  marginBottom: "10px",

                }}
                name={"Failed"}
                control={
                  <Checkbox
                   size = "small"
                   checked = {m.isChecked}
                   className = 'check-box-color'
                   id={m.name}
                   onChange = {handleCheckBoxClick}
                  />
                }
                label={<div style = {{textAlign : 'left'}}>{m.label}</div>}
                labelPlacement="end"
              />
            </FormGroup>))
          }
        </Paper>
      </Fade>
    )}
  </Popper>)
}

class Filter extends Component {
  state = {
    showFlags : false,
    dateError: false,
    failedStatusPopper: false
  }
  showFlagList = () => {
    this.setState({ showFlags: !this.state.showFlags});
  };
  updateFlags = (event,i,modal) => {
    this.setState({ showFlags: false});
    if(modal === "save")
      this.props.updateFlags();
  };
  getMinMax() {
    let fromDate = dayjs(this.props.fromDate);
    let max = fromDate.add(90, "day").format("MM/DD/YYYY");
    if(dayjs(max).isAfter(dayjs())) {
      max = dayjs().format("MM/DD/YYYY");
    }
    this.props.handleChange("toDate", max)
    this.setState({
      toMaxDate: max
    })
  }

  componentDidMount() {
    this.getMinMax();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.fromDate != this.props.fromDate) {
      this.getMinMax();
    }
  }

  handleStatusClick(event, status) {
    if(status == "Failed") {
      this.setState({failedStatusPopper: !this.state.failedStatusPopper, statusAnchorEl: event.target});
      event.preventDefault();
    } else this.setState({failedStatusPopper: false})
  }


  render() {
    return (
      <>
        <div className = 'status-icon'>
          <Grid className="filter-fields">
           <div style = {{marginRight : '10px'}}>Date: </div>
            <Grid style={{ paddingRight: "10px" }}>
              <CustomDatePicker 
                maxDate={dayjs().format("MM/DD/YYYY")}
                onChange={val => {
                  this.setState({ dateError: false });
                  this.props.handleChange('fromDate', val)
                }}
                onError={error => {
                  if(error) this.setState({ dateError: true });
                  else this.setState({ dateError: false });
                }}
                value={this.props.fromDate}
                label="From"
                format={'MM/DD/YYYY'}
              />
            </Grid>
            <Grid style={{ paddingRight: "10px" }}>
              <CustomDatePicker 
                minDate={dayjs(this.props.fromDate).format("YYYY-MM-DD")}
                maxDate={this.state.toMaxDate}
                onChange={val => {
                  this.setState({ dateError: false });
                  this.props.handleChange('toDate', val);
                }}
                onError={error => {
                  if(error) this.setState({ dateError: true });
                  else this.setState({ dateError: false });
                }}
                value={this.props.toDate}
                label="To"
                format={'MM/DD/YYYY'}
              />
            </Grid>
            <div className="buttons-filter">
              <button
                className="apply-button"
                type="submit"
                style={{cursor: this.state.dateError ? 'not-allowed': 'pointer'}}
                onClick={() => !this.state.dateError && this.props.handleSubmit()}
              >
                <p className="apply-button-text">Apply</p>
              </button>
              <button
                style={{ width: "120px" }}
                className="reset-button"
                type="submit"
                onClick={() => {
                  this.props.cancelFilter();
                }}
              >
                <p className="reset-button-text">Reset all filters</p>
              </button>
            </div>
          </Grid>
          </div>
          <div className = "status-icon">
            <div className = "status-checkbox">
                {this.props.status_arr && this.props.status_arr.map((status,i) => 
                   <FormGroup key={i}>
                   <FormControlLabel
                    key={i}
                      style = {{
                        paddingRight : "5px",
                        backgroundColor : status.isChecked ? "#ececec" : ""
                      }}
                      name={status.name}
                     control={
                       <Checkbox
                        size = "small"
                        checked = {status.isChecked}
                        disabled={status.disabled}
                        id={status.name + "-checkbox"}
                        className = 'check-box-color'
                        onChange = {(event) => this.props.handleCheckBoxes(event,i, 'status')}
                       />
                     }
                      label={
                        <div
                          id={status.name + "-status"}
                          style={{ textAlign: 'left' }}
                          // onClick={e => this.handleStatusClick(e, status.name)}
                        >{status.name}</div>
                      }
                      labelPlacement="end"
                   />
                 </FormGroup>
                )}

                  <FailedList {...this.props} open={this.state.failedStatusPopper} anchorEl={this.state.statusAnchorEl}/>
                </div>
                <div className="vertical-mini-divider" style = {{height: "3em"}}></div>
               <div style={{ margin: "5px 0px 0px 10px" }}>
                <FlagList
                  flag_list={this.props.flag_data}
                  updateFlags={this.updateFlags}
                  handleFlags={this.props.handleFlags}
                  showFlagList = {this.showFlagList}
                  showFlags = {this.state.showFlags}
                  flag_glph = {this.props.flag_glph}
                  modal = "doc_mgmt"
                />
            </div>
        </div>
      </>
    );
  }
}

export default Filter;
