import React from "react";
import "./PreviewSection.css";

import { IconButton } from "@mui/material";
import { Remove, Add} from '@mui/icons-material';
class PreviewSection extends React.Component {
  zoomIn = () => {
    var image = document.getElementById("docImage");
    var currWidth = image.clientWidth;
    image.style.width = currWidth + 40 + "px";
    document.getElementById("img-div").style.overflow = "scroll";
  };
  zoomOut = () => {
    var image = document.getElementById("docImage");
    var currWidth = image.clientWidth;
    image.style.width = currWidth - 40 + "px";
  };
  scrollDetect = () => {
    var scrollPos = 0;
    if (document.body.getBoundingClientRect().top > scrollPos)
      console.log("up");
    else console.log("down");
  };
  findClick = (e) => {
    e.preventDefault();
  };
  render() {
    return <>
      <div className="title-box-preview">
        <p
          className="sub-title"
          style={{ padding: "10px", textAlign: "left" }}
        >
          Document preview
        </p>
        {this.props.data && this.props.data.Format !== "pdf" && (
          <div className="icon-text">
            <IconButton onClick={this.zoomOut} size="large">
              <Remove className="icon-action-edit" fontSize="small" />
            </IconButton>
            <p>Zoom</p>
            <IconButton onClick={this.zoomIn} size="large">
              <Add className="icon-action-edit" fontSize="small" />
            </IconButton>
          </div>
        )}
      </div>
      {this.props.image_url && this.props.image_url.length > 0 && (
        <div
          className={
            this.props.data &&  this.props.data.Format === "pdf"
              ? "img-preview-pdf"
              : "img-preview"
          }
          id="img-div"
        >
          <div>
            {this.props.data && this.props.data.Format === "pdf" ? (
              <embed
                id="docImage"
                style={{ width: "47vw", height: "100vh" }}
                src={this.props.image_url[0].signed_url }
                key={this.props.image_url[0].signed_url}
                alt="doc image"
              />
            ) : (
              <img
                id="docImage"
                draggable="false"
                onContextMenu={(event) => this.findClick(event)}
                style={{ width: "47vw", height: "100vh"}}
                src={this.props.image_url[0].signed_url}
                key={this.props.image_url[0].signed_url}
                alt="doc"
              />
            )}
            <div id="highlight-a" className="highlight-ocr"></div>
          </div>
        </div>
      )}
    </>;
  }
}

export default PreviewSection;
