import React from "react";
import {
  Checkbox,
  FormGroup,
  FormControlLabel
} from "@mui/material";
import "./Categories.css";

class Categories extends React.Component {

  expandCollapse = () => {
    if (this.props.searchVal?.openCategory === true && this.props.searchVal?.openLocation === false) {
      return '90vh';
    }else  if (this.props.searchVal?.openCategory === true && this.props.searchVal?.openLocation === true) {
      return '50vh';
    }
  }

  render() {
    let _searchData = this.props.searchVal;
    let all_categories = (_searchData?.search_cat === true && _searchData?.categorySearch.length > 2) ? this.props.cat_search_result : _searchData?.search_cat === false ? this.props.doc_cat : this.props.doc_cat;

    return (
      <div className='category-section'>
        <div id="doc-cat-box" style={{height: this.expandCollapse()}}>

          {all_categories && all_categories.map((data, i) => (
            <div key = {i} style = {{display : 'flex' , alignItems : 'center'}}>
            <div className = {data.name === 'Select All' ? 'margin-first' : 'margin-all'}>
              <FormGroup key={i}>
                <FormControlLabel
                  key={i}
                  name={data.name}
                  control={
                    <Checkbox
                      size = "small"
                      checked = {data.isChecked}
                     className = 'check-box-color'
                      onChange = {(event) => this.props.handleCategories(event,i, 'category', all_categories)}
                    />
                  }
                  label={<div style = {{textAlign : 'left'}}>{data.name}</div>}
                  labelPlacement="end"
                />
              </FormGroup>
            </div>
            </div>
          ))}
        </div>


      </div>
    );
  }
}

export default Categories;
