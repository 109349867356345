import React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import * as dayjs from "dayjs";
import './TableData.css';
import { ConvertUtcToLocal } from "../../../../commonFunctions/commonFunctions";

class TableData extends React.Component {
    render() {
        return (
            <TableBody>
        {this.props.finalData &&
          this.props.finalData.map((mp, i) => (
          
              <TableRow
                key = {i}
              >
                <TableCell
                  className="tableCellStyle"
                  align={mp.first_name === "" ? "center" : "left"}
                >
                  <div>{mp.first_name}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.last_name === "" ? "center" : "left"}
                >
                  <div>{mp.last_name}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.email === "" ? "center" : "left"}
                >
                  <div>{mp.email}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.role === "" ? "center" : "left"}
                >
                  <div>{mp.role}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.status === "" ? "center" : "left"}
                >
                  {mp.status ? "Active" : "Inactive"}
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.last_logged_time === "" ? "center" : "left"}
                >
                 <div> {mp.last_logged_date ? ConvertUtcToLocal( mp.last_logged_date + " " + mp.last_logged_time + " UTC", "MM/DD, hh:mm A") : null}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={mp.last_edited_date === "" ? "center" : "left"}
                >
                 <div> {mp.last_edited_date && dayjs(mp.last_edited_date).format("MM/D/YYYY, hh:mm A") || ""}</div>
                </TableCell>
                <TableCell
                  className="tableCellStyle"
                  align={"left"}
                >
                  <Edit onClick={() => this.props.getRowData(mp)} className = 'icon-review-style'></Edit>
                </TableCell>
              </TableRow>
            
          ))}
      </TableBody>
        )
    }
}

export default TableData;