import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import * as c from "../utils/constants/constants";
import * as msg from "../utils/constants/messages";
import api from "../utils/API/api";
import { forceLogout } from "../commonFunctions/forceLogout";

export default function (props) {
  const history = useHistory();
  const handleOnIdle = async(event) => {
    console.log("session idle ",window.location.href);
    if(window.location.href.indexOf('manualReview') !== -1){
      var data = {
        "Is_Active": false
      }
    await api.exit_doc_details(localStorage.getItem(c.DOC_ID),data)
      .then((response) => {
        if(response.status === 200){ 
         localStorage.removeItem("access");
         localStorage.removeItem("refresh");
         localStorage.setItem(c.LOGOUT, "inactive");
       
         setTimeout(() => {
          if (c.SSO === "true") history.push("/ssoRedirect");
          else history.push("/info");
        }, 250);
       
      }})
      .catch((error) => {
       if (error.response.status === 401) {
          forceLogout();
        }else
          window.alert(msg.api_error);
      });
    }
    else {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.setItem(c.LOGOUT, "inactive");
    setTimeout(() => {
      if (c.SSO === "true") history.push("/ssoRedirect");
      else history.push("/info");
    }, 500);
    
    }
    
  };

  const handleOnActive = (event) => {
    /*console.log("user is active", event);
    console.log("time remaining", getRemainingTime());*/
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: parseInt(JSON.parse(localStorage.getItem(c.SETTINGS)).Session_Time_Out_In_Min)*60*1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    // onAction: handleOnAction,
    // debounce: 500
  });

  return <div>{/* your app here */}</div>;
}
