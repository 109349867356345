import React, { useState } from "react";
import "./Flags.css";
import FlagList from "../../../components/FlagList/FlagList";

const Flags = (props) => {
  const [showFlags, setshowFlags] = useState(false);
  const showFlagList = () => {
    setshowFlags(!showFlags);
  };
  const updateFlags = (event, i, modal) => {
    setshowFlags(false)
    if (modal === "save")
      props.handleFlags(event, i, modal);
  };
  return (

    <>
      {/*block cursor action of flag component if any card is edited and not saved*/}

      <div className="fg-section">
        {!props.all_state.isDuplicateView ? <FlagList

          flag_list={props.flag_list}
          updateFlags={updateFlags}
          handleFlags={props.handleFlags}
          showFlagList={showFlagList}
          showFlags={showFlags}
          flag_glph={props.flag_glph}
        /> : <div className="flags-selection-title">Flags</div>}
        <div className="fg-wrap">
          {props.flag_list && props.flag_list.map(
            (flag, i) =>
              flag.enabled && (
                <div key={i} className=" fg-each-chip">
                  {flag.tag_display_name}
                </div>
              )
          )}
        </div>
      </div>

    </>
  )
}
export default Flags;
