import React from 'react'
import DailyChart from './DailyChart/DailyChart';
import "./index.css";
import LocationDailyTable from './LocationDailyTable/LocationDailyTable';
import LocationHourlyCharts from './LocationHourlyCharts/LocationHourlyCharts';


class DailyReport extends React.Component {

    render() {
        return (
            <div className="daily-report-container">
                <div className="daily-report-left-portion">
                    <DailyChart
                        loader={this.props.loader}
                        handleChartClick={this.props.handleChartClick}
                        handeDateChange={this.props.handeDateChange}
                        name="dailyStatics"
                        data={this.props.dailyStatics}
                    />
                    <LocationDailyTable
                        loader={this.props.loader}
                        data={this.props.locationStaticsByDate}
                        selectedDate={this.props.selectedDate}
                        selectedDateRange={this.props.selectedDateRange}
                    />
                </div>
                <div className="daily-report-right-portion">
                    <LocationHourlyCharts
                        loader={this.props.loader}
                        data={this.props.hourlyLocationStatics}
                        selectedDate={this.props.selectedDate} />
                </div>
            </div>
        )
    }
}
export default DailyReport;