import React from "react";
import "./TableFormat.css";
import { Divider} from "@mui/material";
import { ConvertUtcToLocal, ConvertToTitleCase } from "../../../../commonFunctions/commonFunctions";

class TableFormat extends React.Component{
    render(){
        return(
            <div className = "table-format">
            {/*================General=====================*/}
            {/*this.props.id === 0 &&
            <div className = "title-sa-main" style = {{backgroundColor : "transparent"}}>Doc ID: {this.props.data.ID}</div>*/}
            <br />
            <div className = "title-sa-main" >
                Revision ID: {this.props.data.Revision_ID}, Created on:  {ConvertUtcToLocal(this.props.data.Received_Date + " " + this.props.data.Received_Time + " UTC", "MM/DD/YYYY, hh:mm:ss A")}</div>
            <div className = "title-sa">GENERAL</div>
            <table>
                <tr>
                    <th>Doc name</th>
                    <th>Doc original name</th>
                    <th>Doc status</th>
                    <th>Location</th>
                    <th>Category</th>
                    <th>Confidence (%)</th>
                    <th>Flags</th>
                    <th>Other info</th>
                </tr>
                <tr>
                    <td>{this.props.data.Name }</td>
                    <td>{this.props.data.Original_File_Name }</td>
                    <td style = {{backgroundColor : "#c1e1eb"}}>{this.props.data.Status }</td>
                    <td>{this.props.data.Location}</td>
                    <td>{this.props.data.Entity_Details.classify && Object.entries(
                                  this.props.data.Entity_Details
                                    .classify
                                ).length === 0
                                  ? this.props.data.Category 
                    : (this.props.data.Entity_Details.classify && 
                     ConvertToTitleCase(this.props.data.Entity_Details.classify.docClassification))}</td>
                    <td>
                        <div>Overall: {this.props.data.Confidence_Level && this.props.data.Confidence_Level}</div>
                        <div>Classification: {this.props.data.Entity_Details.classifyConfidence && (this.props.data.Entity_Details.classifyConfidence*100).toFixed(2)}</div>
                        <div>Extraction: {this.props.data.Entity_Details.extractConfidence && (this.props.data.Entity_Details.extractConfidence*100).toFixed(2)}</div>
                        <div>Avg extraction: {this.props.data.Entity_Details.avgExtractConfidence && (this.props.data.Entity_Details.avgExtractConfidence*100).toFixed(2)}</div>
                    </td>
                    <td>
                        <div>STAT: {this.props.data.Entity_Details.is_stat !== undefined && this.props.data.Entity_Details.is_stat.toString()}</div>
                        <div>Multi patient: {this.props.data.Entity_Details.multiPatientDocument !== undefined && this.props.data.Entity_Details.multiPatientDocument.toString()}</div>
                        <div>Large no. of pages: {this.props.data.Entity_Details.largeNumberOfPages !== undefined && this.props.data.Entity_Details.largeNumberOfPages.toString()}</div>
                    </td>
                    <td>
                        <div>Format: {this.props.data.Format && this.props.data.Format}</div>
                        <div>Source: {this.props.data.Source && this.props.data.Source}</div>
                        <div>Total no. of pages: {this.props.data.Entity_Details.totalPageCount && this.props.data.Entity_Details.totalPageCount}</div>
                    </td>
                </tr>
           </table>
           <br />
            {/*===============Timelog=================*/}
            <div className = "title-sa">CLASSIFICATION TIMELOG</div>
            {this.props.data.Entity_Details.classificationTimeLog && 
            <table>
                <tr>
                {/*Object.keys(this.props.data.Entity_Details.classificationTimeLog).map((dt,i) =>
                <th>
                    {dt.split('_').join(" ").replace('tym','time').replace('class', 'classification').replace('st','start')}
               </th>)
                */}
                <th>Total start time</th>
                <th>OCR start time</th>
                <th>OCR end time</th>
                <th>Cleaning start time</th>
                <th>Cleaning end time</th>
                <th>Classification start time</th>
                <th>Classification end time</th>
                <th>Total end time</th>
                </tr>
                <tr>
                {/*Object.values(this.props.data.Entity_Details.classificationTimeLog).map((dt,i) =>
                <td>
                    {dt}</td>)
                */}
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.classificationTimeLog.total_st_tym + " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.classificationTimeLog.ocr_st_tym+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.classificationTimeLog.ocr_end_tym+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.classificationTimeLog.cleaning_st_tym+" UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.classificationTimeLog.cleaning_end_tym+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.classificationTimeLog.class_st_tym+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.classificationTimeLog.class_end_tym+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.classificationTimeLog.total_end_tym+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>

              </tr>
            </table>}
         
          <br/>
          <div className = "sub-title-sa">Extraction Timelog</div>
            {this.props.data.Entity_Details.extractTimeLog && 
            <table >
                <tr>
                {/*Object.keys(this.props.data.Entity_Details.extractTimeLog).map((dt,i) =>
                <th>
                    {dt.split('_').join(" ")}
               </th>)
                */}
                <th>Extraction start time</th>
                <th>Doc AI start time</th>
                <th>Doc AI end time</th>
                <th>Entity start time</th>
                <th>Entity end time</th>
                <th>Extraction end time</th>
                </tr>
                <tr>
                {/*Object.values(this.props.data.Entity_Details.extractTimeLog).map((dt,i) =>
                <td>
                    {dt}</td>)
                */}
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.extractTimeLog.Extract_start_time+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.extractTimeLog.Doc_Ai_start_time+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.extractTimeLog.Doc_Ai_end_time+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.extractTimeLog.Entity_start_time+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
                <td>{ConvertUtcToLocal(this.props.data.Entity_Details.extractTimeLog.Extract_end_time+ " UTC", "MM/DD/YYYY, hh:mm:ss A")}</td>
              </tr>
            </table>}
            <br />
            {/*=====================Extraction==================*/}
            <br />
            <div className = "sub-title-sa">Extracted data</div>
            <div className = "mini-text-sa">By default, 6 DOB and 8 patient name combinations are sent for patient matching.</div>
            <table>
            <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th>Confidence (%)</th>
                  <th>Page</th>
              </tr>
              {this.props.data.Entity_Details.extraction["dateOfBirth"] &&
              <tr style = {{backgroundColor : "#c1e1eb"}}>
                      <td>{this.props.data.Entity_Details.extraction["dateOfBirth"].key}
                      <div style = {{color : "grey",marginTop : '5px'}}> 
                      {this.props.data.Entity_Details.extraction["dateOfBirth"].keyVertices && 
                      <td>{this.props.data.Entity_Details.extraction["dateOfBirth"].keyVertices.map((vert,i) => 
                          <div key={i}>x: {vert.x}, y: {vert.y}</div>
                      )}</td>}</div></td>
                      <td>{this.props.data.Entity_Details.extraction["dateOfBirth"].value}
                      <div style = {{color : "grey",marginTop : '5px'}}>
                      {this.props.data.Entity_Details.extraction["dateOfBirth"].valueVertices &&
                      <td>{this.props.data.Entity_Details.extraction["dateOfBirth"].valueVertices.map((vert,i) => 
                          <div key={i}>x: {vert.x}, y: {vert.y}</div>
                      )}</td>}
                          </div></td>
                      <td>{(this.props.data.Entity_Details.extraction["dateOfBirth"].confidence*100).toFixed(2)}</td>
                      <td>{this.props.data.Entity_Details.extraction["dateOfBirth"].page}</td>
                  </tr>}
                  {this.props.data.Entity_Details.extraction["patientName"] && 
                  <tr style = {{backgroundColor : "#c1e1eb"}}>
                      <td>{this.props.data.Entity_Details.extraction["patientName"].key}
                      <div style = {{color : "grey",marginTop : '5px'}}>
                      {this.props.data.Entity_Details.extraction["patientName"].keyVertices &&
                      <td>{this.props.data.Entity_Details.extraction["patientName"].keyVertices.map((vert,i) => 
                          <div key={i}>x: {vert.x}, y: {vert.y}</div>
                      )}</td>}
                      </div></td>
                      <td>{this.props.data.Entity_Details.extraction["patientName"].value}
                      <div style = {{color : "grey",marginTop : '5px'}}>
                      {this.props.data.Entity_Details.extraction["patientName"].valueVertices &&
                      <td>{this.props.data.Entity_Details.extraction["patientName"].valueVertices.map((vert,i) => 
                          <div key={i}>x: {vert.x}, y: {vert.y}</div>
                      )}</td>}
                      </div></td>
                      <td>{(this.props.data.Entity_Details.extraction["patientName"].confidence*100).toFixed(2)}</td>
                      <td>{this.props.data.Entity_Details.extraction["patientName"].page}</td>
                      
                  </tr>}
              {this.props.data.Entity_Details.additionalDateOfBirth && this.props.data.Entity_Details.additionalDateOfBirth.map((dt,i) =>
              <tr key={i}>
                  <td>{dt.key}</td>
                  <td>{dt.value}</td>
                  <td>{(dt.confidence*100).toFixed(2)}</td>
                  <td>{dt.page}</td>
              </tr>
              )}
              {this.props.data.Entity_Details.additionalPatientName && this.props.data.Entity_Details.additionalPatientName.map((dt,i) =>
              <tr key={i}>
                  <td>{dt.key}</td>
                  <td>{dt.value}</td>
                  <td>{(dt.confidence*100).toFixed(2)}</td>
                  <td>{dt.page}</td>
              </tr>
               )}
            </table>
            <br />
           
          {/*====================Patient Match===================*/}
          <div className = "title-sa">PATIENT MATCH</div>
          <table>
              <tr>
                  <th>Patient name</th>
                  <th>Matched Patient name</th>
                  <th>Official name</th>
                  <th>Patient found in EMR</th>
                  <th>MRN</th>
                  <th>Match confidence (%)</th>
                  
              </tr>
              <tr>
                 <td>{this.props.data.Patient_Name && this.props.data.Patient_Name}</td>
                 <td>{this.props.data.Matched_Patient_Name && this.props.data.Matched_Patient_Name}</td>
                 <td>{this.props.data.Entity_Details.patientMatch && this.props.data.Entity_Details.patientMatch.official_name}</td>
                 <td>{this.props.data.Patient_Match_In_EMR !== undefined && this.props.data.Patient_Match_In_EMR.toString()}</td>
                 <td>{this.props.data.MRN && this.props.data.MRN}</td>
                 <td>{this.props.data.Match_Confidence && (this.props.data.Match_Confidence*100).toFixed(2)}</td>
              </tr>
          </table>
          <br/>
          {/*=================File rename transactions (commands provided to FWA)=====================*/}
           {this.props.id === 0 &&
           <>
          <div className = "title-sa">File rename transactions </div>
          <div className = "mini-text-sa">(commands provided to FWA)</div>
          <table>
              <tr>
                  <th>Client ID</th>
                  <th>IP address</th>
                  <th>New file name</th>
                  <th>File path</th>
                  
              </tr>
              {this.props.data.Meta_Data && this.props.data.Meta_Data.map((data,i)=> 
                    <tr key={i}>
                        <td>{data.client_id && data.client_id}</td>
                        <td>{data.file_path && data.ip_address}</td>
                        <td>{data.new_file_name && data.new_file_name}</td>
                        <td ><div>{data.file_path && data.file_path}</div></td>

                    </tr>
              )}
              
            </table>
            </>}

                  <Divider />
                  <br />
                  <br />
                  <br />
        
          </div>
        )
    }
}

export default TableFormat