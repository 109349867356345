import React from "react";
import "./InfoScreen.css";
import {Info} from "@mui/icons-material";
import * as c from "../../utils/constants/constants";

class InfoScreen extends React.Component {
  state = {
    info: "",
  };
  componentDidMount = () => {
    localStorage.clear();
  };
  goToLogin = () => {
    if (c.SSO === "true") window.location = c.SSO_APP;
    else this.props.history.push(c.PAGE_URLS[c.PAGE_LOGIN]);
  };
  render() {
    let path = window.location.href.split("?");
    let info = "";
    if (path[1]) {
      if (path[1] === "inactive")
        info = "Session has been timed out due to inactivity.";
      else if (path[1] === "expired") 
        info = "Session has been expired.";
      else if(path[1] === "user_not_registered")
        info = "You are not registered or your status is inactive."
    }else {
      if (localStorage.getItem(c.LOGOUT) === "inactive")
        info = "Session has been timed out due to inactivity.";
      else if (localStorage.getItem(c.LOGOUT) === "expired")
        info = "Session has been expired.";
    }
    return (
      <>
        <div className="info-div">
          <Info className="info-icon" />
          <div className="info">{info}</div>
          { path[1] !== 'user_not_registered' ?
          <div className="info">
            {" "}
            Please{" "}
            <span className="info-link" onClick={() => this.goToLogin()}>
              click here
            </span>{" "}
            to continue using the application.
          </div> :
          <div className="info">
            Please contact the administrator.
          </div>
          }
        </div>
      </>
    );
  }
}

export default InfoScreen;
