import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  ListItem,
  MenuItem,
  Menu,
  IconButton
} from "@mui/material";
import { NavLink, Link } from "react-router-dom";
import "./Navbar.css";
import rd_logo from "../../utils/images/rd_logo.svg";
import rd_name_nav from "../../utils/images/rd_name_nav.svg";
import * as c from "../../utils/constants/constants";
import * as msg from "../../utils/constants/messages";
import { forceLogout } from "../../commonFunctions/forceLogout";
import { getUserProfileData } from "../../redux/userprofile/actions/actions";
import { ArrowDropDown } from '@mui/icons-material';


class Navbar extends Component {
  state = {
    value: 0,
    mobile: "",
    anchorEl: null
  };
  componentDidMount() {
    this._isMounted = true;
    if (!localStorage.getItem(c.USER_NAME)) {
      this.props.getUserProfileData(c.ROOT_URL + "/getUserProfile");
    }

  }
  componentDidUpdate = (prevProps) => {
    if (this.props.userprofiledata.hasError === 401) {
      forceLogout();
    } else if (this.props.userprofiledata.hasError)
      window.alert(msg.api_error);
    if (this.props.userprofiledata.userprofiledata && this.props.userprofiledata.userprofiledata !== prevProps.userprofiledata.userprofiledata)
      localStorage.setItem(c.USER_NAME, this.props.userprofiledata.userprofiledata.data[0].name);

  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };


  render() {
    let tab = window.location.href.toString().split("/")[3];
    const classes = "doc-route tabs";
    let { changeCurrentPage } = this.props;
    return (
      <div style={{ padding: this.props.all_state?.isDuplicateView ? "5px 10px" : "unset" }} className={localStorage.getItem(c.SETTINGS) && JSON.parse(localStorage.getItem(c.SETTINGS)).Environment === "ch-test" ? "nav-bar-blue" : "nav-bar"}>
        <Grid className="left-grid">
          <div className="logo-name">
            <img alt="logo" src={rd_logo} className="logo-grp1" />
            <div className={classes}>
              <img className='rd-nav' src={rd_name_nav} alt="rd_name" />
            </div>
          </div>

          {
            !this.props.all_state?.isDuplicateView ? <>
              <NavLink
                exact
                activeClassName="activeHeadLinkStyle"
                to={c.PAGE_URLS[c.PAGE_DASHBOARD]}
                className="headLinkStyle"
                onClick={() => {
                  changeCurrentPage(c.PAGE_DASHBOARD);
                }}
              >
                <ListItem style={{ display: "grid" }}>
                  <p className="tab-text">Overview</p>
                  {tab === "overview" ? (
                    <div className="under-line"></div>
                  ) : (
                    <div className="under-line-hide"></div>
                  )}
                </ListItem>
              </NavLink>
              <NavLink
                exact
                activeClassName="activeHeadLinkStyle"
                to={c.PAGE_URLS[c.PAGE_DOC_MGMT]}
                className="headLinkStyle"
                onClick={() => {
                  changeCurrentPage(c.PAGE_DOC_MGMT);
                }}
              >
                <ListItem style={{ display: "grid" }}>
                  <p className="tab-text">Doc Management</p>
                  {tab === "docManagement" || tab === "manualReview" ? (
                    <div className="under-line-mgmt"></div>
                  ) : (
                    <div className="under-line-mgmt-hide"></div>
                  )}
                </ListItem>
              </NavLink>
              {localStorage.getItem(c.USER_ROLE) && localStorage.getItem(c.USER_ROLE) === 'Admin' &&
                <>
                  <NavLink
                    exact
                    activeClassName="activeHeadLinkStyle"
                    to={c.PAGE_URLS[c.PAGE_AUDIT_LOG]}
                    className="headLinkStyle"
                    onClick={() => {
                      changeCurrentPage(c.PAGE_AUDIT_LOG);
                    }}
                  >
                    <ListItem style={{ display: "grid" }}>
                      <p className="tab-text">Audit</p>
                      {tab === "auditLog" ? (
                        <div className="under-line"></div>
                      ) : (
                        <div className="under-line-hide"></div>
                      )}
                    </ListItem>
                  </NavLink>
                  <NavLink
                    exact
                    activeClassName="activeHeadLinkStyle"
                    to={c.PAGE_URLS[c.PAGE_USER_MGMT]}
                    className="headLinkStyle"
                    onClick={() => {
                      changeCurrentPage(c.PAGE_USER_MGMT);
                    }}
                  >
                    <ListItem style={{ display: "grid" }}>
                      <p className="tab-text">User Management</p>
                      {tab === "userManagement" ? (
                        <div className="under-line"></div>
                      ) : (
                        <div className="under-line-hide"></div>
                      )}
                    </ListItem>
                  </NavLink>
                  <NavLink
                    exact
                    activeClassName="activeHeadLinkStyle"
                    to={c.PAGE_URLS[c.PAGE_REPORTS]}
                    className="headLinkStyle"
                    onClick={() => {
                      changeCurrentPage(c.PAGE_REPORTS);
                    }}
                  >
                    <ListItem style={{ display: "grid" }}>
                      <p className="tab-text">Reports</p>
                      {tab === "reports" || tab === "reports#" ? (
                        <div className="under-line"></div>
                      ) : (
                        <div className="under-line-hide"></div>
                      )}
                    </ListItem>
                  </NavLink>
                </>
              }
            </> : null
          }
        </Grid>
        <Grid className="menu-holder">
          <div className="right-grid">

            <div className="icon-text">
              <div className="user-name-disp">
                {localStorage.getItem(c.USER_NAME) &&
                  <div>{localStorage.getItem(c.USER_NAME)}</div>}
                {localStorage.getItem(c.SETTINGS) &&
                  <div style={{ color: "white", fontSize: "0.8em" }}>
                    {JSON.parse(localStorage.getItem(c.SETTINGS)).Environment_Display_Name} environment
                  </div>}
              </div>
              <div className="vertical-line-nav"></div>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={e => !this.props.all_state?.isDuplicateView && this.handleClick(e)}
                size="large">
                <ArrowDropDown style={{ color: "white" }} />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                onClose={this.handleClose}
                open={Boolean(this.state.anchorEl)}
              >
                <Link to="/userprofile">
                  <MenuItem>Profile</MenuItem>
                </Link>
                {/* <MenuItem>My account</MenuItem> */}
                <Link className="logout-item" to={c.SSO === 'true' ? c.PAGE_URLS[c.SSO_REDIRECT] : '/'} >
                  <MenuItem>Logout</MenuItem>
                </Link>

              </Menu>
            </div>
          </div>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userprofiledata: state.userprofiledata
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfileData: (url) => dispatch(getUserProfileData(url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

