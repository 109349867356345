import React from "react";
import {
  TextField,
  Select,
  FormControl,
  FormHelperText,
  Autocomplete
} from "@mui/material";
import "./EditManual.css";
import AlertBox from "../../../components/AlertBox/AlertBox";
import Classification from "./Classification/Classification";
import Flags from "../Flags/Flags";
import * as c from "../../../utils/constants/constants";
import { Warning, Close, Edit, Check, Add } from '@mui/icons-material';
import { CustomTooltip } from "../../../components/CustomTooltip/CustomTooltip";
import * as msg from "../../../utils/constants/messages";

const original_list_of_keys = {
  "Patient": ["Patient Name", "Patient Date of Birth", "Provider Details", "Service Date"],
  "Encounter": ["Patient Name", "Patient Date of Birth"]
};
class EditManual extends React.Component {
  state = {
    data: [],
    label_type: "", //label of existing card
    value_type: "", //value of existing card
    confidence: "",
    normalised: "", //normalised value of existing card
    value_type_new: "",  //value of newly added card
    normalised_new: "", //normalised value of newly added card
    label_type_new: "", //label of newly added card
    err_duplicate: "",
    list_of_keys: JSON.parse(JSON.stringify(original_list_of_keys)),
    ref: "",
    showCheck: "", //handles check icon for cards
    static_save: false, // to indicate if user has statically saved data by clicking on check icon
    block_card_save: false, // work around to check if card has all required fields before saving them
    free_text: "", //handles input of flag notes
    free_text_check: false, //handles check icon for flag notes
    doc_desc: "", //handles input of doc description
    doc_desc_check: false //handles check icon for doc description
  };

  filterExtractionKeys(isEncounter) {
    let extractionDetails = JSON.parse(JSON.stringify(this.props.all_state.data?.Entity_Details?.extraction || {}));
    let keys = ["patientName", "dateOfBirth", "provider_details", "service_date"];
    if (isEncounter) keys = ["patientName", "dateOfBirth"];
    let sortedObject = keys.reduce((acc, m) => {
      if (extractionDetails[m]) acc[m] = extractionDetails[m];
      return acc;
    }, {});

    return { ...sortedObject }

    // return { ...sortedObject, ...extractionDetails };
  }

  /*calls when edit icon on a card is clicked */
  editData = (label, value, conf, normalised, ref, index) => {
    /*to hide duplicate keys in 'normalised key' dropdown for extracted data*/
    var all_keys = JSON.parse(JSON.stringify(original_list_of_keys[this.props.all_state?.data?.Entity_Details?.classify?.documentLevel]));
    let obj = this.filterExtractionKeys(this.props.all_state?.data?.Entity_Details?.classify?.documentLevel == "Encounter");
    for (var i = 0; i < Object.entries(obj).length; i++) {
      for (var j = 0; j < all_keys.length; j++) {
        if (all_keys[j] === Object.entries(obj)[i][1].normalized && all_keys[j] !== normalised) {
          all_keys.splice(j, 1);
        }
      }
    }
    this.setState(prevState => ({
      list_of_keys: {
        ...prevState.list_of_keys,
        [this.props.all_state?.data?.Entity_Details?.classify?.documentLevel]: all_keys
      }
    }));

    /*sets the new value to each parameter*/
    this.setState({
      label_type: label,
      value_type: value,
      confidence: conf,
      normalised: normalised,
      ref: ref
    });
    this.props.handleCardAction("edit", true, "add", false);
  };
  /*calls when a new card is added */
  addData = () => {
    var all_keys = JSON.parse(JSON.stringify(original_list_of_keys[this.props.all_state?.data?.Entity_Details?.classify?.documentLevel]));
    let obj = this.filterExtractionKeys(this.props.all_state?.data?.Entity_Details?.classify?.documentLevel == "Encounter");
    this.setState({ ref: "" });
    this.props.handleCardAction("edit", false, "add", true);
    this.setState({
      value_type_new: "",
      normalised_new: "",
      label_type_new: "",
    });
    /*to hide duplicate keys in 'normalised key' dropdown*/
    for (var i = 0; i < Object.entries(obj).length; i++) {
      for (var j = 0; j < all_keys.length; j++) {
        if (all_keys[j] === Object.entries(obj)[i][1].normalized) {
          all_keys.splice(j, 1);
        }
      }
    }
    this.setState(prevState => ({
      list_of_keys: {
        ...prevState.list_of_keys,
        [this.props.all_state?.data?.Entity_Details?.classify?.documentLevel]: all_keys
      }
    }));
  };

  /*close the card without saving data when user clicks on cross icon*/
  closeData = (modal, key) => {
    this.setState({ showCheck: false });
    if (modal === "edit") {
      if (this.state.ref === key)
        this.props.handleCardAction("edit", false);
    } else if (modal === "add")
      this.props.handleCardAction('', '', "add", false);
  };
  /*save data statically by clicking on check icon*/
  saveFlags = (modal, data) => {
    if ((modal === "edit" && this.state.normalised === "") || (modal === "add" && this.state.normalised_new === "")) {
      this.setState({ block_card_save: true });
    }
    else {
      this.setState({ showCheck: false, static_save: true, block_card_save: false });
      if (modal === "edit") {
        this.props.handleCardAction("edit", false);
        if (data === "extraction")
          this.props.saveData(modal, data, this.state);
      } else if (modal === "add") {
        this.props.handleCardAction('', '', "add", false);
        if (data === "extraction")
          this.props.saveData(
            modal,
            data,
            this.state
          );
      }
    }
  };
  deleteFlags = () => {
    this.handleCardAction('', '', "add", false);
  };

  handleChange = (col, value) => {
    this.setState({ [col]: value });
    if (col === "free_text")
      this.setState({ free_text_check: true });
    else if (col === "doc_desc" && value.length <= 50) {
      this.setState({ [col]: value, doc_desc_check: true });
    } else if (col !== "free_text" && col !== "doc_desc") {
      this.setState({ [col]: value, showCheck: true });
    }

  };
  titleCase = (str) => {
    if (str === "DME")
      return str;
    else {
      var splitStr =
        str.indexOf("_") === -1
          ? str.toLowerCase().split(" ")
          : str.toLowerCase().split("_");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    }
  };
  /*handles flag note section. edit,save,cancel action. sets value and calls fucntion present in manualReview.js*/
  handleNotes = (action) => {
    if (action === "edit") {
      this.setState({ free_text: this.props.all_state.data.Entity_Details.notes });
      this.props.handleNotes(action);
    } else if (action === "save") {
      this.props.handleNotes(action, this.state.free_text);
    }
    else if (action === "cancel") {
      this.setState({ free_text: this.props.all_state.data.Entity_Details.notes, free_text_check: false });
      this.props.handleNotes(action);
    }
  }
  /*handles doc desc section. edit,save,cancel action. sets value and calls fucntion present in manualReview.js*/
  // handleDocDesc = (action) => {
  //   if(action === "edit"){
  //     this.setState({doc_desc : this.props.all_state.data.Entity_Details.doc_description});
  //     this.props.handleDocDesc(action);
  //   }else if(action  ===  "save"){
  //     this.props.handleDocDesc(action,this.state.doc_desc);
  //   }
  //   else if(action === "cancel"){
  //     this.setState({doc_desc : this.props.all_state.data.Entity_Details.doc_description,doc_desc_check: false});
  //     this.props.handleDocDesc(action);
  //   }
  // }

  disableEdit = () => {
    const { Entity_Details = {} } = this.props.all_state.data || {};

    if (this.props.all_state.data.Status === c.status.uploaded && !Entity_Details.is_epic_confirmation_received) return true;
    return false;
  }
  openDuplicate(id) {
    window.open(window.location.origin + window.location.pathname + "?doc_id=" + id + "&duplicate=true")
  }

  getOptionList = (data) => {
    let keyList = {
      "Patient Name": "additionalPatientName",
      "Patient Date of Birth": "additionalDateOfBirth",
      "Address": "additional_address",
      "Phone Number": "additional_phone",
      "Provider Details": "additional_provider_details",
      "Service Date": "service_date"
    }
    let key = keyList[data.normalized];
    let options = this.state.additionalOptions?.[key];
    if (!options) {
      options = this.props.all_state.data.Entity_Details?.[key]
    }
    return <div className="each-category">
      <div className="label-font" style={{ marginTop: "0px" }}>
        Value
      </div>
      <Autocomplete
        options={(options || [])?.map((key, i) => ({
          label: key.value,
          value: key.value
        }))}
        value={this.state.value_type || data.value}
        onChange={(event, newValue) => {
          this.handleChange("value_type", newValue?.label)
        }
        }
        onInputChange={(event, value) => {
          this.setState({
            additionalOptions: {
              ...(this.state.additionalOptions || []),
              [key]: [...(this.props.all_state.data.Entity_Details?.[key] || {}), { value: value }]
            }
          })
        }
        }
        sx={{ width: 150 }}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="" />
        )}
      />
    </div>
  }

  getPatientName(data) {
    const { value } = data;
    let patientName = '', lists = value?.split(" ") || [];
    if ((lists.length == 1 || (lists.length == 2 && lists.some(e => e?.length < 2))) && this.props.all_state.data?.matched_patient_name?.length && !data.modified) {
      patientName = this.props.all_state.data?.matched_patient_name;
      return patientName;
    }
    else return data.value;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.all_state?.data?.Tags !== this.props.all_state?.data?.Tags) {
      this.setState({ free_text: "" });
    }
  }
  openDuplicate(id) {
    window.open(window.location.origin + window.location.pathname + "?doc_id=" + id + "&duplicate=true")
  }
  render() {
    let extractionFinalObject = this.filterExtractionKeys(this.props.all_state?.data?.Entity_Details?.classify?.documentLevel == "Encounter");
    return (
      <>

        {/* Button section will have "Save changes", "Discard changes", "Match patient", "Complete" buttons accordingly*/}
        <div className={this.props.all_state.data.Status === c.status.uploading || this.props.all_state.data.Status === c.status.completing || this.props.all_state.data.Status === c.status.completed ||
          this.props.all_state.data.Status === c.status.uploaded || this.props.all_state.data.Error_Code === 5001 || this.props.all_state.data.Error_Code === 5002 || this.props.all_state.data.Error_Code === 5003 || this.props.all_state.data.Error_Code === 5004 || this.disableEdit() ? "block-events-cursor" : ""}>
          {this.props.all_state.data &&
            this.props.all_state.data.Notifications &&
            this.props.all_state.data.Notifications.Duplicate_Document_IDs && (
              <div className="warning_msg">
                <div>
                  <Warning />
                  <div className="msg-content">
                    <div>This document could be a probable duplicate of &nbsp;</div>
                    {this.props.all_state.data.Notifications?.Duplicate_Document_IDs.split(
                      ","
                    ).map((item, index) => (
                      <div className={!this.props.all_state.isDuplicateView ? "showLink" : ""} onClick={e => !this.props.all_state.isDuplicateView && this.openDuplicate(item)}>
                        {`${item}${this.props.all_state.data.Notifications?.Duplicate_Document_IDs.split(",").length - 1 !== index ? ',' : ''}`}
                      </div>
                    ))}
                  </div>
                </div>
                {this.props.all_state.isSave ||
                  this.props.all_state.flag_save ? (
                  <CustomTooltip title={msg.stat_tooltip} placement="top-end">
                    <Close className="block-cursor" />
                  </CustomTooltip>
                ) : (
                  !this.props.all_state.isDuplicateView ? <Close
                    onClick={() =>
                      this.props.onDialogAction("duplicate", "open")
                    }
                  /> : null
                )}
              </div>
            )}
          <div className="scrollable-cards">
            <div className="flag-notes-section">
              {/*===================Flag section=========================*/}
              <Flags
                flag_list={this.props.all_state.data.Tags}
                handleFlags={this.props.handleFlags}
                flag_glph={this.props.all_state.flag_glph}
                all_state={this.props.all_state}
              />
              {/*===================Notes section=========================*/}
              <div className="notes-section">
                <div className="icon-text">
                  <div className="notes-label">Flag Notes</div>
                  {this.props.all_state.edit_free_text ?
                    <>
                      {this.state.free_text_check &&
                        <Check
                          className="icon-review-style"
                          fontSize="small"
                          onClick={() => this.handleNotes("save")}
                        />}
                      <Close
                        fontSize="small"
                        className="icon-review-style"
                        onClick={() => this.handleNotes("cancel")}
                      />
                    </> :
                    (<Edit
                      fontSize="small"
                      className={!this.props.all_state.isDuplicateView ? "icon-review-style" : "icon-remove-style"}
                      onClick={() => !this.props.all_state.isDuplicateView && this.handleNotes("edit")} />)}
                </div>
                {this.props.all_state.edit_free_text ?
                  <TextField
                    name="free_text"
                    value={this.state.free_text}
                    onChange={(event) => this.handleChange("free_text", event.target.value)}
                    id="outlined-textarea"
                    rows={2}
                    multiline
                  //autoFocus
                  />
                  :
                  <div>{this.props.all_state.data.Entity_Details?.notes}</div>}
              </div>
            </div>
            {/*=============================classification section===============*/}
            <Classification
              all_state={this.props.all_state}
              saveData={this.props.saveData}
              titleCase={this.titleCase}
              handleCategoryCardAction={this.props.handleCategoryCardAction}
              saveEHRDetails={this.props.saveEHRDetails}
              onDialogAction={this.props.onDialogAction}
              updateDocumentLevel={this.props.updateDocumentLevel}
            />
            {/*================================Description section=================*/}
            {/* <div className="doc-desc-section">
                <div className="icon-text">
                   <div className="notes-label">Document Description</div>
                   {this.props.all_state.edit_doc_desc ?
                   <>
                   {this.state.doc_desc_check &&
                    <Check
                    className="icon-review-style"
                    fontSize="small"
                    onClick={() => this.handleDocDesc("save")}
                  />}
                 <Close
                  fontSize="small"
                  className="icon-review-style"
                  onClick={() => this.handleDocDesc("cancel")}
                 /> 
                 </>:
                (<Edit 
                fontSize="small"
                className = {!this.props.all_state.isDuplicateView ? "icon-review-style" : "icon-remove-style"} 
                onClick={() => !this.props.all_state.isDuplicateView && this.handleDocDesc("edit")}/>) }
                </div>
                {this.props.all_state.edit_doc_desc ?
                <>
                <TextField
                  name = "doc_desc"
                  value = {this.state.doc_desc}
                  onChange = {(event) => this.handleChange("doc_desc", event.target.value)}
                  id="outlined-textarea"
                  rows={2}
                  multiline
                  //autoFocus
                />
                <div className="grey-txt">Maximum 50 characters.</div>
                </>
                :
                <div>{this.props.all_state.data.Entity_Details.doc_description}</div>}
                </div> */}
            {/*==============================extraction section==================*/}
            <div className="extract-text-area">
              {/*===========display all extracted data if present===================*/}
              <div className="flex-style">
                {Object.keys(extractionFinalObject).length &&
                  Object.values(
                    extractionFinalObject
                  ).map(
                    (data, i) =>
                      data.normalised !== "Category" && (
                        <div key={i} className="each-section">
                          <div
                            className={
                              data.modified && data.modified === true
                                ? "card-flag"
                                : "card-flag-hide"
                            }
                          >
                            <div className="tri-flag" />
                          </div>

                          {this.props.all_state.card_edit === true &&
                            this.state.ref ===
                            Object.keys(
                              extractionFinalObject
                            )[i] ? (
                            <>
                              <div className="each-box-view">
                                <div style={{ textAlign: "right" }}>
                                  {this.state.showCheck && (
                                    <Check
                                      className="icon-review-style"
                                      onClick={() => {
                                        this.saveFlags("edit", "extraction");
                                      }}
                                      fontSize="small"
                                    />
                                  )}

                                  <Close
                                    className="icon-review-style"
                                    onClick={() => {
                                      this.closeData("edit", this.state.ref);
                                    }}
                                    fontSize="small"
                                  />
                                </div>
                                <div className="left-fields">
                                  <div>
                                    <FormControl variant="standard" style={{ width: "100%" }}>
                                      <Select

                                        native
                                        value={this.state.normalised}
                                        margin="dense"
                                        onChange={event => this.handleChange("normalised", event.target.value)}
                                        inputProps={{
                                          name: "normalised",
                                          id: "outlined-age-native-simple",
                                        }}
                                      >
                                        <option value="" disabled>
                                          Select key
                                        </option>

                                        {(this.state.list_of_keys[this.props.all_state?.data?.Entity_Details?.classify?.documentLevel] || []).map((key, i) => (
                                          <option key={i} value={key}>
                                            {key}
                                          </option>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div>
                                    <p className="label-font">Label</p>
                                    <TextField
                                      variant="standard"
                                      style={{ width: "100%" }}
                                      autoComplete="off"
                                      name="label_type"
                                      value={this.state.label_type}
                                      onChange={event => this.handleChange("label_type", event.target.value)}
                                    />
                                  </div>
                                  {this.props.all_state.data.Status === c.status.intervention_rqd && this.state.list_of_keys[this.props.all_state?.data?.Entity_Details?.classify?.documentLevel]?.includes(data.normalized) ?
                                    this.getOptionList(data) : <div>
                                      <p className="label-font">Value</p>
                                      <TextField
                                        variant="standard"
                                        style={{ width: "100%" }}
                                        autoComplete="off"
                                        name="value_type"
                                        value={this.state.value_type}
                                        onChange={event => this.handleChange("value_type", event.target.value)}
                                      />
                                    </div>}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className="each-box-view"
                            >
                              <div
                                className="icon-review"
                                style={{ textAlign: "right" }}
                              >
                                {<Edit
                                  className={
                                    this.props.all_state.card_edit || this.props.all_state.card_add || this.props.all_state.category_card
                                      ? "icon-disable"
                                      : (!this.props.all_state.isDuplicateView ? "icon-review-style" : "icon-remove-style")
                                  }
                                  onClick={(e) =>
                                    this.props.all_state.card_edit || this.props.all_state.card_add
                                      ? e.preventDefault()
                                      :
                                      !this.props.all_state.isDuplicateView && this.editData(
                                        data.key,
                                        data.normalized == "Patient Name" ? this.getPatientName(data) : data.value,
                                        data.confidence,
                                        data.normalized,
                                        Object.keys(
                                          extractionFinalObject
                                        )[i],
                                        i
                                      )
                                  }
                                  fontSize="small"
                                />}
                              </div>
                              <div className="left-fields">
                                <div>
                                  <p className="card-title">{data.normalized}</p>
                                </div>
                                <div>
                                  <p className="label-font">Label</p>
                                  <p className="value-font">{data.key}</p>
                                </div>
                                <div>
                                  <p className="label-font">Value</p>
                                  <p className="value-font">{data.normalized == "Patient Name" ? this.getPatientName(data) : data.value}</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                  )}

                {this.props.all_state.card_add ? (
                  <div className="each-section-new-card">
                    <div className="card-flag-hide">
                      <div className="tri-flag" />
                    </div>
                    <div
                      className="each-box-view"
                    >
                      <div style={{ textAlign: "right" }}>
                        {this.state.showCheck && (
                          <Check
                            className="icon-review-style"
                            onClick={() => {
                              this.saveFlags("add", "extraction");
                            }}
                            fontSize="small"
                          />
                        )}

                        <Close
                          className="icon-review-style"
                          onClick={() => {
                            this.closeData("add");
                          }}
                          fontSize="small"
                        />
                      </div>
                      <div className="left-fields">
                        <FormControl variant="standard" style={{ width: "100%" }}>
                          <Select
                            native
                            value={this.state.normalised_new}
                            margin="dense"
                            onChange={event => this.handleChange("normalised_new", event.target.value)}
                            inputProps={{
                              name: "normalised_new",
                              id: "outlined-age-native-simple",
                            }}
                          >
                            <option value="" disabled>
                              Select key
                            </option>
                            {(this.state.list_of_keys[this.props.all_state?.data?.Entity_Details?.classify?.documentLevel] || []).map((key, i) => (
                              <option key={i} value={key}>
                                {key}
                              </option>
                            ))}
                          </Select>
                          <FormHelperText>Required</FormHelperText>
                        </FormControl>
                        <p
                          className="label-font"
                          style={{ fontWeight: "normal" }}
                        >
                          Label
                        </p>
                        <TextField
                          variant="standard"
                          style={{ width: "100%" }}
                          autoComplete="off"
                          name="label_type_new"
                          value={this.state.label_type_new}
                          onChange={event => this.handleChange("label_type_new", event.target.value)}
                        />
                        <p
                          className="label-font"
                          style={{ fontWeight: "normal" }}
                        >
                          Value
                        </p>
                        <TextField
                          variant="standard"
                          style={{ width: "100%" }}
                          autoComplete="off"
                          name="value_type_new"
                          value={this.state.value_type_new}
                          onChange={event => this.handleChange("value_type_new", event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  !this.props.all_state.isDuplicateView ? <div
                    className={
                      Object.entries(
                        extractionFinalObject
                      ).length < original_list_of_keys[this.props.all_state?.data?.Entity_Details?.classify?.documentLevel]?.length && this.props.all_state.card_edit !== true
                        ? "add-section"
                        : "add-section-disable"
                    }
                    onClick={(e) =>
                      Object.entries(
                        extractionFinalObject
                      ).length < original_list_of_keys[this.props.all_state?.data?.Entity_Details?.classify?.documentLevel]?.length && this.props.all_state.card_edit !== true
                        ? this.addData()
                        : e.preventDefault()
                    }
                  >
                    <div className="value-font" style={{ marginTop: "10px" }}>
                      <p style={{ margin: "0px" }}>Add new</p>
                      <p style={{ margin: "0px" }}> Key/Parameter</p>
                    </div>
                    <div>
                      <Add className="add-button-review" fontSize="large" />
                    </div>
                  </div> : null
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.err_duplicate === true && (
          <AlertBox key_name={this.state.normalised_new} />
        )}

      </>

    );
  }
}
export default EditManual;
