import React from "react";
import {
  Typography,
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

class TableHeader extends React.Component {
  render() {
    return (
      <TableHead className="thBorder">
        <TableRow>
          {this.props.tableHeadData.map((data, i) => (
            <TableCell key={i}
              className="tableHeadStyle"
              align="left"
              onClick={() => this.props.sort(data.name)}
            >
              <div className="sortSection">
                <Typography className="textTheme">{data.name}</Typography>

                <div
                  className={
                    data.name === this.props.audit_filter.sort_key ? "sort-icons" : "sort-icons-hide"
                  }
                >
                  {data.name === this.props.audit_filter.sort_key && this.props.audit_filter.sort_order === true ? (
                    <ArrowUpward
                      fontSize="small"
                      style={{ color: "grey" }}
                    />
                  ) : data.name === this.props.audit_filter.sort_key && this.props.audit_filter.sort_order === false ?
                    (<ArrowDownward
                      style={{ color: "grey" }}
                      fontSize="small"
                    />
                    ) : null
                  }
                </div>

              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default TableHeader;