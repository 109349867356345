import React from "react";
import {
  Typography,
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import {ArrowDownward, ArrowUpward, Help, SupervisorAccount, Visibility } from "@mui/icons-material";
import "./TableHeader.css";

class TableHeader extends React.Component {
  
  render() {
    return (
      <TableHead className="thBorder">
        <TableRow>
          {this.props.tableHeadData.map((data, i) => (
            <TableCell
              key={i}
              className="tableHeadStyle"
              align="left"
              onClick={() => this.props.sort(data.name)}
            >
              <div className="sortSection">
                {data.name === "Patient Name" && (
                  <div className="patient-icon-hide">
                    <Help
                      fontSize="small"
                      style={{
                        color: "var(--hti-brick)",
                        fontSize: "1em",
                        marginRight: "3px",
                      }}
                    />
                  </div>
                )}
                {data.name === "Document Name" && (
                  <div className="file-icon-hide" style = {{marginRight : "13px"}}>
                    <SupervisorAccount className = "blue-icon" />
                  </div>
                )}
                {data.name === "Status" &&
                 <Visibility
                 style={{ color: "#f58018", fontSize: "1em",visibility : "hidden" }}
               />}
                <Typography className="textTheme">{data.display_name}</Typography>

                <div
                  className={
                    data.icon === true ? "sort-icons" : "sort-icons-hide"
                  }
                >
                  {data.order === true ? (
                    <ArrowUpward
                      fontSize="small"
                      style={{ color: "grey" }}
                    />
                  ) : (
                    <ArrowDownward
                      style={{ color: "grey" }}
                      fontSize="small"
                    />
                  )}
                </div>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default TableHeader;
