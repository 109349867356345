import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getPmetrics } from "../../../redux/getdocsstatus/actions/actions";
import { Chip } from "@mui/material";
import * as c from "../../../utils/constants/constants";
import * as msg from "../../../utils/constants/messages";
import { GetDefaultDate, ConvertUtcToLocal, ConvertLocalToUtc } from '../../../commonFunctions/commonFunctions';
import { forceLogout } from "../../../commonFunctions/forceLogout";
import EChartsReact from "echarts-for-react";
import "./MetricsChart.css";

const chartOptions = {
    title: {
        show: false,
        textStyle: {
            fontSize: 12,
            fontWeight: "normal"
        },
        text: "No data to display in the selected interval.",
        left: 'center',
        top: 'center'
    },
    color: ["#2D9CDB", "#f2994a", "#27AE60"],
    grid: {
        left: 50,
        top: 20
    },
    tooltip: {
        trigger: 'axis',
        borderWidth: 1,
        borderColor: "#e3e3e3",
        borderRadius: 0,
        padding: 0,
        axisPointer: {
            type: 'line',
            label: {
                backgroundColor: '#6a7985'
            }
        },
        formatter: (params) => {
            return `<div class="tooltip-headers">
                        ${params[0].axisValue}
                    </div>
                    <div class="tooltip-body-container">
                        ${params[0] ? `<div class="tooltip-body">${params[0].marker} ${params[0].seriesName}: <span class="text-value">${params[0].value}<span/></div>` : ''}
                        ${params[1] ? `<div class="tooltip-body">${params[1].marker} ${params[1].seriesName}: <span class="text-value">${params[1].value}<span/></div>` : ''}
                        ${params[2] ? `<div class="tooltip-body">${params[2].marker} ${params[2].seriesName}: <span class="text-value">${params[2].value}<span/></div>` : ''}                                
                    <div/>`
    }
    },
    legend: {
        data: [],
        itemWidth: 10,
        textStyle: {
            padding: 2
        },
        icon: "circle",
        top: 1,
        right: 50
    },

    xAxis: [
        {
            type: 'category',
            boundaryGap: false,
            splitLine: {
                show: false
            },
            axisLabel: {
                fontSize: 10
            }
        }
    ],
    yAxis: [
        {
            type: 'value',
            splitLine: {
                show: false
            },
            axisLabel: {
                fontSize: 10
            }
        }
    ],

    series: []
}
const dur_data = [{ label: "Today", value: 0 }, { label: "Since Yesterday", value: 1 }, { label: "1 week", value: 7 }, { label: "2 weeks", value: 14 }, { label: "4 weeks", value: 28 }]

const MetricsChart = (props) => {

    let chartRef = useRef(null);
    const [dur, updateDuration] = useState(7);
    const [data, updateData] = useState([]);

    useEffect(() => {
        let defaultDate = GetDefaultDate({ duration: 7, type: "day" });
        let fromDate = ConvertLocalToUtc({ date: defaultDate.fromDate, format: "MM-DD-YYYY HH:mm:ss", startOf: "day" });
        let toDate = ConvertLocalToUtc({ date: defaultDate.toDate, format: "MM-DD-YYYY HH:mm:ss", endOf: "day" });
        props.getPmetrics(c.ROOT_URL + "/getPerformanceMetricsReport/date?from_date=" + fromDate + "&to_date=" + toDate);
    }, [])

    const changeDuration = ({ value, label }) => {
        updateDuration(value)
        let defaultDate = GetDefaultDate({ duration: value, type: "day" });
        let fromDate = ConvertLocalToUtc({ date: defaultDate.fromDate, format: "MM-DD-YYYY HH:mm:ss", startOf: "day" });
        let toDate = ConvertLocalToUtc({ date: defaultDate.toDate, format: "MM-DD-YYYY HH:mm:ss", endOf: "day" });
        if (label.indexOf('week') !== -1)
            props.getPmetrics(c.ROOT_URL + "/getPerformanceMetricsReport/date?from_date=" + fromDate + "&to_date=" + toDate);
        else
            props.getPmetrics(c.ROOT_URL + "/getPerformanceMetricsReport/hour?from_date=" + fromDate + "&to_date=" + toDate);
    };

    useEffect(() => {
        if (props.perform_metrics.perform_metrics) {
            let new_series = [], new_axis = [], no_data = false;
            let chart = chartRef.current?.getEchartsInstance()
            props.perform_metrics.perform_metrics && props.perform_metrics.perform_metrics.forEach((data) => {
                if (data.name != 'x_axis') {
                    if (data.data.length == 0) {
                        no_data = true;
                    } else new_series.push(data)

                } else {
                    (data.data.forEach((dt) => {
                        dt = dt.split(' ');
                        new_axis.push(ConvertUtcToLocal(dt[0] + ' ' + (dt[1] ? dt[1] + ':00 UTC' : '00:00:00'), dt[1] ? 'MM/DD, hh:mm A' : 'MM/DD'));
                    })
                    )
                }
            });

            let tempData = new_series.map((value) => {
                return ({
                    "name": value.name,
                    "type": 'line',
                    "smooth": true,
                    "symbolSize": 5,
                    "lineStyle": {
                        width: 2,
                    },
                    "showSymbol": true,
                    "animation": true,
                    "areaStyle": {
                        opacity: 0.2,
                    },
                    "data": value.data
                });
            });

            // updating chart options
            let myChart = chart.getOption();
            myChart.series = tempData;
            myChart.legend.data = tempData;
            myChart.color = ["#2D9CDB", "#f2994a", "#27AE60"];
            myChart.tooltip = chartOptions.tooltip;
            myChart.legend = chartOptions.legend;
            myChart.legend.data = tempData;
            if (!myChart.xAxis) {
                myChart.xAxis = chartOptions.xAxis;
                myChart.xAxis[0].data = new_axis;
            } else myChart.xAxis[0].data = new_axis;
            if (!myChart.yAxis) {
                myChart.yAxis = chartOptions.yAxis;
            }

            chart.clear(); // clear chart
            if (no_data) {
                myChart.title = chartOptions.title;
                myChart.title.show = true;
            } else {
                myChart.title = chartOptions.title;
                myChart.title.show = false;
            }

            chart.setOption(myChart); // set chart option

        }
    }, [props])

    return (
        <>
            <div className="flex-style">
                <div style={{ padding: "15px" }}>
                    <p className="perf-title title">Performance Metrics</p>
                </div>
                <div className="chip-container flex-style" style={{ marginLeft: "10px" }}>
                    {dur_data.map((data, i) => (
                        <Chip key={i}
                            label={
                                <p
                                    className={
                                        data.value === dur ? "active-chip" : "inactive-chip"
                                    }
                                >
                                    {data.label}
                                </p>
                            }
                            variant="outlined"
                            onClick={() => changeDuration(data)}
                            className={
                                data.value === dur ? "chip-active" : "chip-inactive"
                            }
                            size="small"
                        />
                    ))}
                </div>
            </div>

            <div className="chart-style">
                <EChartsReact
                    option={chartOptions}
                    ref={chartRef}
                    style={{ width: "750px", height: "300px" }}
                />
            </div>

        </>
    );

}

const mapStateToProps = (state) => {
    if (state.perform_metrics.hasError === 401) {
        forceLogout();
    } else if (state.perform_metrics.hasError)
        window.alert(msg.api_error);
    return {
        perform_metrics: state.perform_metrics
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPmetrics: (url) => dispatch(getPmetrics(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricsChart);